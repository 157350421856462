import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";
import BigNumber from "bignumber.js";
import RelativeTime from "dayjs/plugin/relativeTime";
import Countdown from "react-countdown";
import styled from "styled-components";
import { newClockIcon } from "../../../assets";
// import { config } from "../../../config";
// import { formatBigNumber, nFormatter } from "../../../utils";
import * as S from "./PoolProgress.styled";

dayjs.extend(Duration);
dayjs.extend(RelativeTime);

type PoolProgressProps = {
  // softCap: string;
  hardCap: string;
  balance: string;
  endTime: string;
  endOfDeposits: string;
  isWhitelistedAndUserNotInWhitelist: boolean;
};

const CountdownWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const DateWrapper = styled.div``;

const InfroWrapper = styled.div``;

export const PoolProgress = (props: PoolProgressProps) => {
  const {
    // softCap,
    hardCap,
    balance,
    endTime,
    endOfDeposits,
    isWhitelistedAndUserNotInWhitelist,
  } = props;

  // const startingWidth = (Number(softCap) * 100) / Number(hardCap);
  // const filledWidth = (Number(balance) * 100) / Number(hardCap);
  const isReachedHardCap = new BigNumber(balance).isGreaterThanOrEqualTo(
    new BigNumber(hardCap)
  );
  // const isPoolEnded = dayjs().isAfter(endTime);

  type CountdownProps = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  };

  const renderCountdown = (dates: CountdownProps) => {
    const { days, hours, minutes, seconds, completed } = dates;
    if (isWhitelistedAndUserNotInWhitelist) {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {/* <S.ClockIcon src={newClockIcon} alt="clock icon" /> */}
          <CountdownWrapper>
            <InfroWrapper>
              <span>Deposits are locked, because the pool is whitelisted.</span>
            </InfroWrapper>
          </CountdownWrapper>
        </div>
      );
    }
    if (completed) {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {/* <S.ClockIcon src={newClockIcon} alt="clock icon" /> */}
          <CountdownWrapper>
            <InfroWrapper>
              <span>
                Deposits are locked, because the time for deposits is over.
              </span>
            </InfroWrapper>
          </CountdownWrapper>
        </div>
      );
    }
    if (days) {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <S.ClockIcon src={newClockIcon} alt="clock icon" />
          <CountdownWrapper>
            <DateWrapper>
              {days}d {hours}h {minutes}m {seconds}s
            </DateWrapper>
            <InfroWrapper>
              to enter into the pool.
              {/* The minimum pool sum is{" "}
              {nFormatter(
                Number(formatBigNumber(softCap, config.USDC_TOKEN_DECIMALS)),
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              {config.STABLE_TOKEN} */}
            </InfroWrapper>
          </CountdownWrapper>
        </div>
      );
    }
    if (hours && !days) {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <S.ClockIcon src={newClockIcon} alt="clock icon" />
          <CountdownWrapper>
            <DateWrapper>
              {hours}h {minutes}m {seconds}s
            </DateWrapper>
            <InfroWrapper>
              to enter into the pool.
              {/* The minimum pool sum is{" "}
              {nFormatter(
                Number(formatBigNumber(softCap, config.USDC_TOKEN_DECIMALS)),
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              {config.STABLE_TOKEN} */}
            </InfroWrapper>
          </CountdownWrapper>
        </div>
      );
    }
    if (minutes && !hours && !days) {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <S.ClockIcon src={newClockIcon} alt="clock icon" />
          <CountdownWrapper>
            <DateWrapper>
              {minutes}m {seconds}s
            </DateWrapper>
            <InfroWrapper>
              to enter into the pool.
              {/* The minimum pool sum is{" "}
              {nFormatter(
                Number(formatBigNumber(softCap, config.USDC_TOKEN_DECIMALS)),
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              {config.STABLE_TOKEN} */}
            </InfroWrapper>
          </CountdownWrapper>
        </div>
      );
    }
    if (seconds && !minutes && !hours && !days) {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <S.ClockIcon src={newClockIcon} alt="clock icon" />
          <CountdownWrapper>
            <DateWrapper>{seconds}s</DateWrapper>
            <InfroWrapper>
              to enter into the pool.
              {/* The minimum pool sum is{" "}
              {nFormatter(
                Number(formatBigNumber(softCap, config.USDC_TOKEN_DECIMALS)),
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              {config.STABLE_TOKEN} */}
            </InfroWrapper>
          </CountdownWrapper>
        </div>
      );
    }
  };

  // const timeToEnd = dayjs.duration(
  //   Math.abs(dayjs().diff(endTime)),
  //   "milliseconds"
  // );

  return (
    <S.Wrapper>
      <S.BarWrapper>
        {/* <S.Bar startingWidth={startingWidth} filledWidth={filledWidth}>
          <S.FilledInfo>
            <S.InsideValueText>
              {nFormatter(
                Number(formatBigNumber(balance, config.USDC_TOKEN_DECIMALS)),
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              /{" "}
              {nFormatter(
                Number(formatBigNumber(hardCap, config.USDC_TOKEN_DECIMALS)),
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              {config.STABLE_TOKEN.toUpperCase()}
            </S.InsideValueText>
          </S.FilledInfo>
        </S.Bar> */}
      </S.BarWrapper>
      <S.TimeInfo>
        {/* {endOfDeposits ? null : (
          <S.ClockIcon src={newClockIcon} alt="clock icon" />
        )} */}
        <div>
          <S.ClockText>
            {isReachedHardCap ? (
              <span>
                Deposits are locked, because the hardcap has been reached
              </span>
            ) : endOfDeposits ? (
              <Countdown
                date={endOfDeposits}
                autoStart
                renderer={renderCountdown}
              />
            ) : (
              <Countdown date={endTime} autoStart renderer={renderCountdown} />
            )}
          </S.ClockText>
        </div>
      </S.TimeInfo>
    </S.Wrapper>
  );
};
