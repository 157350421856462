export const preparePortalElement = (id: string) => {
  let el = document.getElementById("dropdown");

  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", id);
    document.body.appendChild(el);
  }

  return el;
};
