import styled from "styled-components";
import { Typography } from "../Typography/Typography";

export const StatusBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
`;

export const Status = styled(Typography.Description)<{
  success: boolean;
  pending?: boolean;
}>`
  background-color: ${({ theme, success, pending }) =>
    success
      ? theme.colors.walletStatus.backgroundOn
      : pending
      ? theme.colors.walletStatus.backgroundPending
      : theme.colors.errorDark};
  color: ${({ theme, success }) =>
    success ? theme.colors.success : theme.colors.error};
  padding: 4px;
  border-radius: 4px;
`;
