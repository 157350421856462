import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import * as S from "./Layout.styled";
import { Header, Nav, TermsConditions } from "./components";
import { StyledToastContainer } from "../../toasts/ToastContainer.styled";
import "react-toastify/dist/ReactToastify.css";
// import MobileNav from "./components/MobileNav/MobileNav";
import { useScrollToTop } from "../../hooks";
import ConnectWalletAssets from "./components/ConnectWalletAssets/ConnectWalletAssets";
import { CriticalNotification, Disclaimer, Spinner } from "../../components";
import { useAppSelector } from "../../redux";

type LayoutProps = {
  children: JSX.Element;
};

export const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useLocation();
  const isPathForDisclaimer =
    pathname === "/dashboard" ||
    pathname === "/grow" ||
    pathname === "/earn" ||
    pathname === "/swap" ||
    pathname.includes("/project-details");

  const isCookiesPage = window.location.href.includes("/enable-cookies");
  useScrollToTop("contentToScroll");

  const { lockReason, isActive: isUserActive } = useAppSelector(
    (state) => state.user.userData
  );

  return isCookiesPage ? (
    <S.Wrapper>
      <S.RightSide id="contentToScroll">
        <Suspense fallback={<Spinner />}>
          <S.Content>{children}</S.Content>
        </Suspense>
      </S.RightSide>
    </S.Wrapper>
  ) : (
    <S.Wrapper>
      <S.NavContainer>
        <Nav />
      </S.NavContainer>
      <S.RightSide id="contentToScroll">
        <Header />
        {!isUserActive && (
          <CriticalNotification
            title="Account suspended"
            description={lockReason}
          />
        )}
        <Suspense fallback={<Spinner />}>
          <S.Content>{children}</S.Content>
        </Suspense>
      </S.RightSide>
      <StyledToastContainer
        className="ignoreOutsideClick"
        draggablePercent={60}
        newestOnTop
        closeButton={false}
        hideProgressBar
        icon
        autoClose={7000}
      />
      {/* {!isDesktopView && <MobileNav />} */}
      <ConnectWalletAssets />
      {isPathForDisclaimer ? <Disclaimer /> : null}
      <TermsConditions />
    </S.Wrapper>
  );
};
