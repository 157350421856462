import styled from "styled-components";
import { Typography } from "../index";
import { Theme } from "../../@types/theme";

const handleBorderType = (
  theme: Theme,
  error?: string | undefined,
  warning?: string | undefined,
  defaultColor?: string
) => {
  if (error) return theme.colors.error;
  if (warning) return theme.colors.warning;
  return defaultColor;
};

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div<{
  error?: string;
  title?: string;
  warning?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${({ title }) => (title ? "83px" : "60px")};

  background-color: ${({ theme }) => theme.colors.greyDark01};
  border: 1px solid
    ${({ theme, error, warning }) =>
      handleBorderType(theme, error, warning, theme.colors.greyDark)};
  border-radius: 2px;
  gap: 10px;

  padding: 15px 20px;
  transition: 0.3s;

  &:hover {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.white033)};
  }
  &:active {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
  }

  &:focus-within {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
    outline: 3px solid
      ${({ theme, error, warning }) =>
        error || warning
          ? "none"
          : handleBorderType(theme, error, warning, theme.colors.primary)};
  }
`;

export const Title = styled.p<{ isReversed?: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.description.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.description.letterSpacing};
  color: ${({ theme, isReversed }) =>
    isReversed ? theme.colors.white : theme.colors.grey};
  opacity: ${({ isReversed }) => (isReversed ? "0.6" : "1")};
  text-transform: uppercase;
`;

export const InputWrapper = styled.div<{ isReversed?: boolean }>`
  display: flex;
  flex-direction: ${({ isReversed }) => (isReversed ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14.5px;
`;

export const Logo = styled.img`
  width: 53px;
  height: 53px;
`;

export const Input = styled.input<{ isReversed?: boolean }>`
  display: none;
`;

export const inputButton = styled.button`
  color: rgba(255, 255, 255, 0.98);
  width: 89px;
  height: 15px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.bodySmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.bodySmall.letterSpacing};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
  gap: 4px;
  height: 16px;
`;

export const WarningMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const ErrorMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.error};
`;
