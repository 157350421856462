import { TableConfig, ColumnsConfig, TableDataItem } from "./Table.types";
import { TableElements } from "..";
import { QueryParams } from "../../constants";
import { SortDirection } from "../../constants/sort";
import { useSortableMechanism } from "./useSortableMechanism";

type TableProps<DataItem extends TableDataItem> = {
  items: DataItem[];
  config: TableConfig<DataItem>;
  sortQueryParamName?: QueryParams;
};

export const Table = <DataItem extends TableDataItem>(
  props: TableProps<DataItem>
) => {
  const { items, config, sortQueryParamName = QueryParams.SORT } = props;

  const columnsData = Object.entries(config.columns) as Entries<
    ColumnsConfig<DataItem>
  >;

  const { sortKey, sortDirection, handleSort } =
    useSortableMechanism(sortQueryParamName);

  return (
    <TableElements.Wrapper>
      <TableElements.Table>
        <TableElements.Head>
          <TableElements.HeadRow>
            {columnsData.map(([key, columnConfig]) => (
              <TableElements.HeadCell
                key={key}
                onClick={
                  columnConfig?.isSortable
                    ? () =>
                        handleSort(
                          columnConfig?.sortKey ? columnConfig.sortKey : key
                        )
                    : undefined
                }
                style={{
                  cursor: columnConfig?.isSortable ? "pointer" : undefined,
                }}
              >
                {columnConfig?.label}{" "}
                {sortKey === key && sortDirection
                  ? sortDirection === SortDirection.ASC
                    ? " ⇑"
                    : " ⇓"
                  : null}
              </TableElements.HeadCell>
            ))}
          </TableElements.HeadRow>
        </TableElements.Head>
        <TableElements.Body>
          {items.map((item) => (
            <TableElements.Row key={item.id}>
              {columnsData.map(([key, columnConfig]) => (
                <TableElements.Cell key={key}>
                  {columnConfig?.value ? columnConfig.value(item) : item[key]}
                </TableElements.Cell>
              ))}
            </TableElements.Row>
          ))}
        </TableElements.Body>
      </TableElements.Table>
    </TableElements.Wrapper>
  );
};
