import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../../style";
import { deviceMax } from "../../../style/media";

export const DropdownUl = styled(motion.ul)<{ isVisible: boolean }>`
  background: ${({ theme }) => theme.colors.dropdown.ulBackground};
  box-shadow: ${({ theme }) => theme.colors.dropdown.boxShadow};
  border-radius: 8px;
  padding: 8px;
  z-index: ${({ theme }) => theme.zIndex.dropdown.menu};

  transition: opacity 0.3s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};

  @media ${device.tablet} {
    width: 171px;
    margin: 6px 0 0 0;
  }

  @media ${deviceMax.tablet} {
    position: fixed !important;
    top: auto !important;
    bottom: 16px !important;
    left: 16px !important;
    transform: none !important;
    width: calc(100% - 32px);
  }
`;

export type DropdownLiProps = {
  disabled?: boolean;
};

export const DropdownLi = styled.li<DropdownLiProps>`
  background: ${({ theme }) => theme.colors.dropdown.liBackground};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  padding: 12px 0;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  p {
    color: ${({ theme, disabled }) =>
      disabled ? `${theme.colors.background}4D` : theme.colors.background};
  }

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.dropdown.liBackground : "rgba(32, 33, 36, 0.09)"};
  }

  @media ${device.tablet} {
    padding: 7px 0;
  }
`;
