import { SortDirection } from "../constants/sort";

export const parseSortParam = (sortParam: string) => {
  const [key, direction] = sortParam.split(":");

  if (direction !== SortDirection.ASC && direction !== SortDirection.DESC) {
    throw new Error("Invalid sort param");
  }

  return [key, direction] as const;
};
