import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import { Controller } from "react-hook-form";
import { parseUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import { inputSoilIcon } from "../../assets";
import { Button, CurrencyInput } from "../../components";
import { ConnectWalletButton } from "..";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { LockoutInfo } from "./LockoutInfo";
import { StakeDetails } from "./StakeDetails";
import { useStakeHandler } from "./useStakeHandler";
import * as S from "./StakeForm.styled";
import { config } from "../../config";
import { actions } from "../../redux";

type StakeFormProps = {
  onStakeSuccess?: () => void;
};

export const StakeForm = ({ onStakeSuccess }: StakeFormProps) => {
  const [isErrorInStakeInput, setIsErrorInStakeInput] = useState(false);
  const { handleSubmit, control, isStakeInProgress, inputValue } =
    useStakeHandler(onStakeSuccess);
  const { isConnected } = useAccount();
  const location = useLocation();
  const balances = useAppSelector((store) => store.wallet.balances);
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );

  const feeSOIL = useAppSelector((store) => store.staking.feeSOIL);

  let stakedAmount = parseUnits(
    inputValue || "0",
    config.SOIL_TOKEN_DECIMALS
  ).sub(BigNumber.from(feeSOIL));
  stakedAmount = stakedAmount.isNegative() ? BigNumber.from(0) : stakedAmount;

  const isButtonDisabled =
    isStakeInProgress || isErrorInStakeInput || stakedAmount.isZero();

  useEffect(() => {
    dispatch(actions.wallet.getBalances(walletAddress));
  }, [walletAddress]);

  return (
    <S.StakingForm onSubmit={handleSubmit}>
      <Controller
        name="amountToStake"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CurrencyInput
            {...field}
            label="$SOIL"
            name="SOIL"
            decimals={config.SOIL_TOKEN_DECIMALS}
            logo={inputSoilIcon}
            available={balances.soil}
            onErrorChange={setIsErrorInStakeInput}
          />
        )}
      />
      <StakeDetails amountToStake={stakedAmount} />
      {isConnected ? (
        <Button.Primary disabled={isButtonDisabled} type="submit" bigSize>
          {location.state?.amount ? "Grow your unlocked founds" : "Grow"}
        </Button.Primary>
      ) : (
        <ConnectWalletButton />
      )}
      <LockoutInfo />
    </S.StakingForm>
  );
};
