import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { api } from "../../../../api";
import { config } from "../../../../config";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";
import { handleBlockchainPromiseInToast } from "../../../../toasts/handleBlockchainPromiseInToast";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import {
  openKycPreventModal,
  openMissingKycModal,
  openTfaModal,
} from "../../../../redux/features/uiSlice";
import { useEthersSigner } from "../../../../utils";
import {
  resetTfaAuthorizationForAction,
  setCurrentTfaAction,
} from "../../../../redux/features/userSlice";

type DepositFormType = {
  amount: string;
  acceptTerms: boolean;
};

export const useDepositToPoolHandler = (
  poolLevel: PoolLevel,
  onSuccess: () => void,
  closeModal: () => void
) => {
  const [myAmount, setMyAmount] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { control, handleSubmit, watch, formState } =
    useForm<DepositFormType>();
  const signer = useEthersSigner();
  const dispatch = useAppDispatch();
  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isPoolsDepositAuthenticated } = tfaAuthenticatedForAction;

  const [isPending, setIsPending] = useState(false);

  const handleDeposit = async ({ amount }: DepositFormType) => {
    try {
      if (!signer) {
        throw new Error("Wallet is not connected!");
      }
      setIsPending(true);

      const parsedAmount = ethers.utils.parseUnits(
        amount,
        config.USDC_TOKEN_DECIMALS
      );

      const {
        data: { signature, deadline },
      } = await api.pools.getDepositSignature(
        poolLevel.pool.poolId,
        poolLevel.level,
        parsedAmount.toString()
      );

      const depositTx = poolsContractService.deposit(
        poolLevel.pool.poolId,
        poolLevel.level,
        parsedAmount,
        deadline,
        signature,
        signer
      );

      const title = `Depositing ${amount} ${config.STABLE_TOKEN} (including fees) to ${poolLevel.name}`;

      await handleBlockchainPromiseInToast(depositTx, {
        pending: {
          title,
          message: "Your request is in progress...",
        },
        error: {
          title,
          message:
            "Your request has been finished unsuccessful. Try again later",
        },
        success: {
          title,
          message:
            "The transaction has been finished successful . You can check details on the block explorer by click the button below.",
        },
      });

      onSuccess();
    } catch (e: unknown) {
      if (
        e instanceof AxiosError &&
        e.response?.data?.message === "KYC user data missing"
      ) {
        closeModal();
        return dispatch(openMissingKycModal());
      }

      if (
        e instanceof AxiosError &&
        (e.response?.data?.message === "KYC not passed." ||
          e.response?.data?.message === "KYC not found.")
      ) {
        closeModal();
        dispatch(openKycPreventModal());
      } else {
        toast.errorHandler(e, "Deposit funds failed!");
      }
      setIsPending(false);
      dispatch(resetTfaAuthorizationForAction());
    }
  };

  const handleDepositSubmit = (amount: string, acceptTerms: boolean) => {
    if (tfaEnabled && acceptTerms) {
      setMyAmount(amount);
      setTermsAccepted(true);
      dispatch(setCurrentTfaAction("deposit"));
      dispatch(openTfaModal());
    } else if (acceptTerms) {
      handleDeposit({ amount, acceptTerms });
    }
  };

  const submitDeposit = async ({ amount, acceptTerms }: DepositFormType) => {
    handleDepositSubmit(amount, acceptTerms);
  };

  useEffect(() => {
    if (isPoolsDepositAuthenticated) {
      handleDeposit({ amount: myAmount, acceptTerms: termsAccepted });
    }
  }, [isPoolsDepositAuthenticated]);

  return {
    control,
    handleSubmit: handleSubmit(submitDeposit),
    isPending,
    inputAmount: watch("amount"),
    formState,
  };
};
