import React from "react";
import { Typography } from "..";
import * as S from "./ConfirmationDialog.styled";

export type DialogProps = {
  text: string;
  handleConfirmClick: () => void;
  handleCancelClick: () => void;
};

export const ConfirmationDialog = ({
  text,
  handleConfirmClick,
  handleCancelClick,
}: DialogProps) => (
  <S.Wrapper>
    <Typography.HeaderMedium>{text}</Typography.HeaderMedium>
    <S.ButtonsWrapper>
      <S.CancelButton onClick={handleCancelClick}>Cancel</S.CancelButton>
      <S.ConfirmButton onClick={handleConfirmClick}>Confirm</S.ConfirmButton>
    </S.ButtonsWrapper>
  </S.Wrapper>
);
