import { apiCall, apiCallWithAuthToken } from "./config";

export const initLogin = (walletAddress: string) =>
  apiCall.get<InitLoginResponse>(`auth/login/${walletAddress}`);

export const loginWithSignature = (walletAddress: string, signature: string) =>
  apiCall.post<LoginResponse>("auth/login", { walletAddress, signature });

export const refreshAccessToken = (refreshToken: string) =>
  apiCall.post<JWTTokens>("auth/refresh", undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

export const getUser = () => apiCallWithAuthToken.get<UserResponse>("user/me");

export const logout = () => apiCallWithAuthToken.delete("auth/logout");
