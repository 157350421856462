import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { userDarkIcon, userLightIcon } from "../../../../assets";
import * as S from "../ToolBar/ToolBar.styled";

export type UserIconProps = {
  click?: () => void;
  isToolbar?: boolean;
  isOpen?: boolean;
};

const UserIcon = ({ click, isOpen, isToolbar }: UserIconProps) => (
  <S.UserIconBtn
    isToolbar={isToolbar}
    className="ignoreOutsideClick"
    type="button"
    onClick={click}
    style={{ position: "relative" }}
  >
    <S.IconImg src={isToolbar ? userLightIcon : userDarkIcon} alt="user icon" />
    <AnimatePresence>
      {isOpen && isToolbar && (
        <S.IconBgUser
          key="icon"
          as={motion.div}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.2 }}
        />
      )}
    </AnimatePresence>
  </S.UserIconBtn>
);

export default UserIcon;
