import styled from "styled-components";

export const WalletToolWrapper = styled.div`
  padding: 15px;
  min-width: 445px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const WalletStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const WalletBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const WalletBalanceValues = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
