import styled from "styled-components";
import { motion } from "framer-motion";
import { Typography } from "../Typography/Typography";
import { ReactComponent as CloseButton } from "../../assets/closeButton.svg";
import { device } from "../../style";

export const Background = styled(motion.div)<{ withBackdropBlur?: boolean }>`
  backdrop-filter: ${({ withBackdropBlur }) =>
    withBackdropBlur ? "blur(10px)" : "none"};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: -400px;
  left: 0;
  right: 0;
  bottom: -400px;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

export const Modal = styled.div<{ message?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  overflow-y: auto;
  top: 416px;
  left: 50%;
  padding: 16px;
  transform: translate(-50%, 0);
  width: ${(props) =>
    props.message ? "clamp(260px, 757px, 90%)" : "clamp(260px, 537px, 90%)"};
  max-height: calc(100vh - 50px);
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.modal.background};

  ::-webkit-scrollbar {
    height: 6px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.notification.text.primary};
    border-radius: 10px;

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.notification.text.secondary};
    }
  }

  @media ${device.mobileL} {
    transform: translate(-50%, -50%);
    padding: 30px 30px 20px 30px;
    top: 50%;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 32px 8px;

  @media ${device.mobileL} {
    padding: 0px 0px 38px 0px;
  }
`;

export const Title = styled(Typography.ModalTitle)`
  display: flex;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.colors.grey};
  font-weight: 700;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const CloseBtn = styled(CloseButton)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    opacity: 0.7;
  }
`;
