import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { routes } from "../../utils/routes";
import { PrivateRoute, AdminRoute, CookiesRoute } from "../index";

const Dashboard = React.lazy(
  () => import("../../pages/userPanel/Dashboard/Dashboard")
);
const AdminDashboard = React.lazy(
  () => import("../../pages/adminPanel/Dashboard/Dashboard")
);
const Earn = React.lazy(() => import("../../pages/userPanel/Earn/Earn"));
const Stake = React.lazy(() => import("../../pages/userPanel/Stake/Stake"));
const Swap = React.lazy(() => import("../../pages/userPanel/Swap/Swap"));
const Vesting = React.lazy(
  () => import("../../pages/userPanel/Vesting/Vesting")
);
const Notifications = React.lazy(
  () => import("../../pages/userPanel/Notifications/Notifications")
);
const KYCauth = React.lazy(() => import("../../pages/userPanel/KYC/KYCauth"));
const Wallet = React.lazy(() => import("../../pages/userPanel/Wallet/Wallet"));
const MyProfile = React.lazy(
  () => import("../../pages/userPanel/MyProfile/MyProfile")
);
const Settings = React.lazy(
  () => import("../../pages/userPanel/Settings/Settings")
);
const KYC = React.lazy(() => import("../../pages/userPanel/KYC/KYC"));
const ProjectDetails = React.lazy(
  () => import("../../pages/userPanel/ProjectDetails/ProjectDetails")
);
const Admins = React.lazy(() => import("../../pages/adminPanel/Admins/Admins"));
const Messages = React.lazy(
  () => import("../../pages/adminPanel/Messages/Messages")
);
const Pools = React.lazy(() => import("../../pages/adminPanel/Pools/Pools"));
const NewPool = React.lazy(
  () => import("../../pages/adminPanel/NewPool/NewPool")
);
const Reports = React.lazy(
  () => import("../../pages/adminPanel/Reports/Reports")
);
const AdminVesting = React.lazy(
  () => import("../../pages/adminPanel/Vesting/Vesting")
);

const AdminStaking = React.lazy(
  () => import("../../pages/adminPanel/Staking/Staking")
);

const Users = React.lazy(() => import("../../pages/adminPanel/Users/Users"));

const UserDetails = React.lazy(
  () => import("../../pages/adminPanel/UserDetails/UserDetails")
);
const EditPool = React.lazy(
  () => import("../../pages/adminPanel/EditPool/EditPool")
);
const FeesWithdrawal = React.lazy(
  () => import("../../pages/adminPanel/FeesWithdrawal/FeesWithdrawal")
);
const FundsWithdrawal = React.lazy(
  () => import("../../pages/adminPanel/FundsWithdrawal/FundsWithdrawal")
);
const Cookies = React.lazy(
  () => import("../../pages/EnableCookies/EnableCookies")
);

const LoanAgreements = React.lazy(
  () => import("../../pages/adminPanel/LoanAgreements/LoanAgreements")
);

const WhiteList = React.lazy(
  () => import("../../pages/adminPanel/WhiteList/WhiteList")
);

const LoanAgreement = React.lazy(
  () => import("../../pages/userPanel/LoanAgreement/LoanAgreement")
);

export function AnimatedRoutes() {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path={routes.cookiesPage} element={<Cookies />} />
      <Route element={<CookiesRoute />}>
        <Route
          path="/"
          element={<Navigate to={routes.userPanel.dashboard} />}
        />
        <Route path={routes.userPanel.dashboard} element={<Dashboard />} />
        <Route
          path={routes.userPanel.loanAgreement}
          element={<LoanAgreement />}
        />
        <Route path={routes.userPanel.vesting} element={<Vesting />} />
        <Route path={routes.userPanel.kycAuth} element={<KYCauth />} />
        <Route path={routes.userPanel.swap} element={<Swap />} />
        <Route
          path={routes.userPanel.notifications}
          element={<Notifications />}
        />
        <Route path={routes.userPanel.wallet} element={<Wallet />} />
        <Route path={routes.userPanel.earn} element={<Earn />} />
        <Route
          path={routes.userPanel.projectDetails}
          element={<ProjectDetails />}
        />

        <Route element={<PrivateRoute />}>
          <Route path={routes.userPanel.grow} element={<Stake />} />
          <Route path={routes.userPanel.myProfile} element={<MyProfile />} />
          <Route path={routes.userPanel.settings} element={<Settings />} />
          <Route path={routes.userPanel.kyc} element={<KYC />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route
            path={routes.adminPanel.dashboard}
            element={<AdminDashboard />}
          />
          <Route path={routes.adminPanel.admins} element={<Admins />} />
          <Route path={routes.adminPanel.messages} element={<Messages />} />
          <Route path={routes.adminPanel.staking} element={<AdminStaking />} />
          <Route path={routes.adminPanel.pools} element={<Pools />} />
          <Route path={routes.adminPanel.reports} element={<Reports />} />
          <Route
            path={routes.adminPanel.loanAgreements}
            element={<LoanAgreements />}
          />
          <Route path={routes.adminPanel.vesting} element={<AdminVesting />} />
          <Route path={routes.adminPanel.users} element={<Users />} />
          <Route path={routes.adminPanel.newPool} element={<NewPool />} />
          <Route path={routes.adminPanel.editPool} element={<EditPool />} />
          <Route
            path={routes.adminPanel.userDetails}
            element={<UserDetails />}
          />
          <Route
            path={routes.adminPanel.feesWithdrawal}
            element={<FeesWithdrawal />}
          />
          <Route
            path={routes.adminPanel.fundsWithdrawal}
            element={<FundsWithdrawal />}
          />
          <Route path={routes.adminPanel.whiteList} element={<WhiteList />} />
        </Route>
      </Route>
    </Routes>
  );
}
