import styled from "styled-components";

export const Alert = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.warning};
  max-width: 1200px;
  justify-self: flex-start;
  padding: 6px 12px;
  border: 1px solid ${({ theme }) => theme.colors.warning};
  margin-right: auto;
  border-radius: 8px;

  div {
    flex-shrink: 0;
  }

  button {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`;
