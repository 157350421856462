import React from "react";
import * as S from "./KycStatus.styled";
import { greenMark } from "../../assets";
import { useAppSelector } from "../../redux/hooks";

export const KycStatus = () => {
  const kyc = useAppSelector((state) => state.user.userData.kyc);

  const getKycStatus = () => {
    if (kyc?.status === "approved") {
      return "approved";
    }
    if (kyc?.status === "started") {
      return "started";
    }
    if (kyc?.status === "pending") {
      return "pending";
    }
    if (kyc?.status === "rejected") {
      return "rejected";
    }
    return "not verified";
  };
  return (
    <S.StatusParagraph status={getKycStatus()}>
      {getKycStatus() === "approved" && (
        <img src={greenMark} alt="green mark icon" />
      )}
      {getKycStatus()}
    </S.StatusParagraph>
  );
};
