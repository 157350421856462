import * as S from "./StatusLabel.styled";

type StatusLabelProps = {
  success: boolean;
  pending?: boolean;
  successLabel: string;
  errorLabel: string;
  pendingLabel?: string;
  started?: boolean;
  startedLabel?: string;
};

export const StatusLabel = (props: StatusLabelProps) => {
  const {
    success,
    pending,
    pendingLabel,
    successLabel,
    errorLabel,
    started,
    startedLabel,
  } = props;

  return (
    <S.StatusBox>
      <S.Status success={success} pending={pending}>
        {success
          ? successLabel
          : pending
          ? pendingLabel
          : started
          ? startedLabel
          : errorLabel}
      </S.Status>
    </S.StatusBox>
  );
};
