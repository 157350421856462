import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import Duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAppSelector } from "../../../../redux";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";

dayjs.extend(Duration);
dayjs.extend(relativeTime);

export const useEarlyWithdrawalCheck = (poolLevel: PoolLevel) => {
  const userWalletAddress = useAppSelector(
    (store) => store.user.userData.walletAddress
  );
  const [lastDepositTime, setLastDepositTime] = useState<Dayjs>();

  const fetchLastDepositTime = async () => {
    try {
      const userInfo = await poolsContractService.getUserInfo(
        userWalletAddress,
        poolLevel.pool.poolId,
        poolLevel.level
      );
      setLastDepositTime(dayjs.unix(userInfo.lastDepositTime.toNumber()));
    } catch (e: unknown) {
      toast.errorHandler(
        e,
        "Failed to fetch information about user from the smart contract"
      );
    }
  };

  useEffect(() => {
    fetchLastDepositTime();
  }, [userWalletAddress]);

  const [areFundsLocked, timeToUnlock] = useMemo(() => {
    if (lastDepositTime) {
      let unlockDate = lastDepositTime.add(
        Number(poolLevel.cooldown),
        "seconds"
      );

      if (unlockDate.isAfter(poolLevel.pool.end)) {
        unlockDate = dayjs(poolLevel.pool.end);
      }

      const areLocked = unlockDate.isAfter(dayjs());
      const toUnlock = dayjs.duration(unlockDate.diff(dayjs())).humanize();

      // const toUnlock = dayjs
      //   .duration(unlockDate.diff(dayjs()))
      //   .format(
      //     `${
      //       unlockDate.diff(dayjs(), "month") > 0
      //         ? `M[${
      //             unlockDate.diff(dayjs(), "months") > 1 ? " months" : " month"
      //           }]`
      //         : ""
      //     } D[d] H[h] m[m]`
      //   );

      return [areLocked, toUnlock];
    }

    return [null, null];
  }, [lastDepositTime, poolLevel.cooldown]);

  return { areFundsLocked, timeToUnlock };
};
