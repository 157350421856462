import styled from "styled-components";
import { device } from "../../style";

export const SoilBarWrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ bordered }) => (bordered ? "12px 12px 0 12px" : "12px")};
  background: #08a98a4d;
  width: 100%;
  position: relative;
  padding: 7.78px 15px 5.78px 32px;

  @media ${device.tablet} {
    border-radius: ${({ bordered }) => (bordered ? "12px 0 0 12px" : "12px")};
    min-width: 165px;
  }
`;

export const UsdcBarWrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ bordered }) => (bordered ? "12px 12px 0 12px" : "12px")};
  background: #2775ca4d;
  width: 100%;
  position: relative;
  padding: 7.78px 15px 5.78px 32px;

  @media ${device.tablet} {
    border-radius: ${({ bordered }) => (bordered ? "12px 0 0 12px" : "12px")};
    min-width: 165px;
  }
`;

export const CoinSoilIconWrapper = styled.div`
  position: absolute;
  left: -10px;
  top: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #26a677;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CoinUsdcIconWrapper = styled.div`
  position: absolute;
  left: -10px;
  top: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #2775ca;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UsdcCoinLogo = styled.img`
  width: 21px;
  height: 20px;
`;

export const SoilCoinLogo = styled.img`
  width: 21px;
  height: 20px;
`;
