import { AnimatePresence } from "framer-motion";
import { useAppSelector } from "../../../../redux/hooks";
import { AdminLinks, UserLinks } from "./components";
import { Logo, AdminLogo } from "../Logo/Logo";
import * as S from "./Nav.styled";
import { isAdminRole } from "../../../../utils";
import { useMediaQuery } from "../../../../hooks";
import { device } from "../../../../style";
import { TermsOfService } from "../../../../components";

export type NavProps = {
  toggleMobileNav?: () => void;
};

export const Nav = ({ toggleMobileNav }: NavProps) => {
  const roles = useAppSelector((state) => state?.user?.userData?.roles);
  const isAdmin = isAdminRole(roles);
  const isDesktopView = useMediaQuery(device.laptop);

  return (
    <AnimatePresence>
      <S.Wrapper>
        {isAdmin ? <AdminLogo /> : isDesktopView ? <Logo /> : null}
        <S.NavWrapper>
          {isAdmin ? (
            <AdminLinks toggleMobileNav={toggleMobileNav} />
          ) : (
            <UserLinks toggleMobileNav={toggleMobileNav} />
          )}
        </S.NavWrapper>
        <TermsOfService />
      </S.Wrapper>
    </AnimatePresence>
  );
};
