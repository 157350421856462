import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useFormContext } from "react-hook-form";
import * as S from "./CustomFilter.styled";
import { CustomInput } from "../CustomInput";
import { Typography } from "../../../components";
import { FormValues } from "../NewMessage/NewMessageBody";

export const CustomFilter = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [typesArray, setTypesArray] = useState<string[]>([]);

  useEffect(() => {
    setValue("types", typesArray);
  }, [typesArray]);

  const onHandleAddTypes = () => {
    const value = getValues("inputType");
    if (value.length > 0) {
      setValue("inputType", "");
      setTypesArray((prevState) => {
        const newArray = [...prevState];
        newArray.push(value);
        return [...newArray];
      });
    }
  };

  const onHandleDelete = (value: string) => {
    setTypesArray((prev) => {
      const newArray = [...prev];
      newArray.splice(newArray.indexOf(value), 1);
      return [...newArray];
    });
  };

  const animations = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
  };

  return (
    <S.Wrapper>
      <CustomInput
        {...register("inputType")}
        placeholder="Types..."
        clicked={onHandleAddTypes}
        error={errors.types?.message}
      />
      <AnimatePresence>
        <S.List>
          {typesArray.map((el) => (
            <S.ListItem key={el} {...animations} layout>
              <Typography.BodySmall>{el}</Typography.BodySmall>
              <S.DeleteButton
                type="button"
                onClick={() => onHandleDelete(el)}
              />
            </S.ListItem>
          ))}
        </S.List>
      </AnimatePresence>
    </S.Wrapper>
  );
};
