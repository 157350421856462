import styled from "styled-components";
import { device } from "../../../style";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const BarWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
`;

export const InsideValueText = styled.p`
  color: rgba(239, 239, 239, 0.65);
  text-align: right;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 93.5%; /* 11.22px */

  @media ${device.tablet} {
    font-size: 12.305px;
  }
`;

export const ClockIcon = styled.img`
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const Bar = styled.div<{ startingWidth: number; filledWidth: number }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10.29px;
  flex-grow: 1;
  height: 30px;
  border-radius: 12px;
  opacity: 0.9;
  background: #1f474a;
  position: relative;

  ::before,
  ::after {
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* ::before {
    background-color: ${({ theme }) => theme.colors.farm.bar.start};
    width: ${({ startingWidth }) => `${startingWidth}%`};
    border-right: 1px solid ${({ theme }) => theme.colors.background};
    border-radius: 8px;
  } */

  ::after {
    background-color: #08d8aa;
    height: 30px;
    opacity: 0.2;
    border-radius: 12px;
    width: ${({ filledWidth }) =>
      filledWidth ? (filledWidth < 2 ? "2%" : `${filledWidth}%`) : "0"};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* animation: fillBar 1.5s ease; */
  }
`;

export const FilledInfo = styled.div`
  display: flex;
  font-size: 11px;
  line-height: 13.92px;
  letter-spacing: 0.05;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};

  span {
    font-size: 7px;
    line-height: 8.86px;
  }
`;

export const TimeInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  color: #fff;
  text-align: center;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  div {
    width: calc(100% - 60px);

    @media ${device.tablet} {
      width: unset;
    }
  }

  span {
    color: #fff;
    text-align: center;
    font-family: Ingra;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
`;

export const ClockWrapper = styled.div`
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;

  img {
    width: 100%;
  }
`;

export const ClockText = styled.p`
  color: #fff;
  text-align: center;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  @media ${device.tablet} {
    font-size: 12.305px;
  }

  span {
    color: #fff;
    text-align: center;
    font-family: Ingra;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    @media ${device.tablet} {
      font-size: 12.305px;
    }
  }
`;
