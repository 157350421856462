import { bpsToPercent, humanizePoolDuration } from "../../../../../utils";
import * as S from "./LevelSelect.styled";

type LevelSelectProps = {
  levels: PoolLevel[];
  selectedLevelId?: PoolLevel["id"];
  onLevelSelect: (selectedLevel: PoolLevel) => void;
};

export const LevelSelect = (props: LevelSelectProps) => {
  const { levels, selectedLevelId, onLevelSelect } = props;

  return (
    <S.LevelsList>
      {levels.map((level) => (
        <S.Level
          isSelected={level.id === selectedLevelId}
          key={level.id}
          onClick={() => onLevelSelect(level)}
        >
          <S.LevelName fontWeight={700}>{level.name}</S.LevelName>
          <S.LevelDetails>
            <li>
              Fixed Apr in USDC: <b>{bpsToPercent(level.usdcApr)}%</b>
            </li>
            <li>
              Duration (lockup period):{" "}
              <b>{humanizePoolDuration(level.cooldown)}</b>
            </li>
          </S.LevelDetails>
        </S.Level>
      ))}
    </S.LevelsList>
  );
};
