import styled from "styled-components";
import { Typography } from "../../../../../../components";

export const TwoFaContainer = styled.div`
  padding: 16px 16px 38px 16px;
  div {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    input {
      width: 45px;
      height: 45px;
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      background-clip: padding-box;
      border-radius: 4px;
      color: #494949;

      :focus-visible {
        outline: 3px solid ${({ theme }) => theme.colors.chart.primary};
      }
    }
  }
`;

export const BackToCodeBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.chart.primary};
`;
export const DontRememberText = styled(Typography.BodySmall)`
  text-align: left;
  width: 100%;
`;
