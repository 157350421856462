import { NavLink } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { routes } from "../../../../../utils/routes";
import * as S from "./NavLinks.styled";
import { useAppSelector } from "../../../../../redux";

export type NavProps = {
  toggleMobileNav?: () => void;
};

export const UserLinks = ({ toggleMobileNav }: NavProps) => {
  const isLogged = useAppSelector((state) => state.user.isLogged);

  return (
    <>
      <S.NavItem as="li">
        {/* <motion.div
          key="dashboardLink"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        > */}
        <NavLink onClick={toggleMobileNav} to={routes.userPanel.dashboard}>
          Dashboard
        </NavLink>
        {/* </motion.div> */}
      </S.NavItem>
      <S.NavItem as="li">
        {/* <motion.div
          key="earnLink"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        > */}
        <NavLink onClick={toggleMobileNav} to={routes.userPanel.earn}>
          Earn
        </NavLink>
        {/* </motion.div> */}
      </S.NavItem>
      <AnimatePresence>
        <S.NavItem as="li" disabled={!isLogged}>
          {/* <motion.div
            key="stakingLink"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          > */}
          <NavLink onClick={toggleMobileNav} to={routes.userPanel.grow}>
            Grow
          </NavLink>
          {/* </motion.div> */}
        </S.NavItem>
      </AnimatePresence>
      <S.NavItem as="li">
        <NavLink onClick={toggleMobileNav} to={routes.userPanel.swap}>
          Swap
        </NavLink>
        {/* <CustomTooltip message="Coming soon.." className="fullWidth">
          <p
            style={{
              width: "100%",
              textAlign: isDesktopView ? "left" : "right",
            }}
          >
            Swap
          </p>
        </CustomTooltip> */}
      </S.NavItem>
      <AnimatePresence>
        <S.NavItem as="li">
          {/* <motion.div
            key="vestingLink"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          > */}
          <NavLink onClick={toggleMobileNav} to={routes.userPanel.vesting}>
            Vesting
          </NavLink>
          {/* </motion.div> */}
        </S.NavItem>
      </AnimatePresence>
    </>
  );
};
