export const triggerInputChange = (input: HTMLInputElement, value = "") => {
  const inputPrototype = Object.getPrototypeOf(input);

  const setValue = Object.getOwnPropertyDescriptor(
    inputPrototype,
    "value"
  )?.set;

  if (setValue) {
    const event = new Event("input", { bubbles: true });
    setValue.call(input, value);
    input.dispatchEvent(event);
  }
};
