import React from "react";
import { NavLink } from "react-router-dom";
import * as S from "./MyProfileNav.styled";
import { KycStatus, Typography, Button } from "../../../../components";
import { routes } from "../../../../utils/routes";
import { useAuth } from "../../../../hooks";
import { useAppSelector } from "../../../../redux/hooks";

const MyProfileNav = () => {
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const { logout } = useAuth();
  return (
    <>
      <NavLink to={routes.userPanel.kyc}>
        <S.UserToolElement>
          <Typography.Body fontWeight={400} opacity={0.92}>
            KYC
          </Typography.Body>
          <KycStatus />
        </S.UserToolElement>
      </NavLink>
      <NavLink to={routes.userPanel.settings}>
        <S.UserToolElement>
          <Typography.Body fontWeight={400} opacity={0.92}>
            Settings
          </Typography.Body>
        </S.UserToolElement>
      </NavLink>
      {isLogged && (
        <Button.Secondary type="button" onClick={() => logout()}>
          Logout
        </Button.Secondary>
      )}
    </>
  );
};

export default MyProfileNav;
