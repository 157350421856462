import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Typography, Button } from "../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux";
import { closeKycPreventModal } from "../../../../../../redux/features/uiSlice";
import * as S from "./KycPrevent.styled";
import { routes } from "../../../../../../utils/routes";

const KycPrevent = () => {
  const isOpenKycPreventModal = useAppSelector(
    (state) => state.ui.isKycPreventModalOpen
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRedirectToKycPage = () => {
    navigate(routes.userPanel.kyc);
    dispatch(closeKycPreventModal());
  };
  return (
    <Modal
      title="KYC verification required"
      isOpen={isOpenKycPreventModal}
      onClose={() => dispatch(closeKycPreventModal())}
      withOutsideClick
    >
      <S.Container>
        <Typography.Body>
          To interact with the SOIL, you have to pass the Know-Your-Customer
          (KYC) verification. If you are not verified positively, you will not
          be able to use any function of the SOIL that requires connecting your
          wallet.
        </Typography.Body>
        <Typography.Body>
          We use Fractal as a third-party KYC services provider. Your personal
          data will be processed by Fractal. We will only have access to the
          results of your KYC verification. To learn more about processing of
          your personal data please consult our Privacy Policy, as well as the
          Fractal’s Privacy Policy.
        </Typography.Body>
        <Button.Primary
          bigSize
          style={{ marginTop: "10px" }}
          onClick={() => handleRedirectToKycPage()}
        >
          Go to KYC verification
        </Button.Primary>
      </S.Container>
    </Modal>
  );
};

export default KycPrevent;
