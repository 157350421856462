import styled from "styled-components";
import { selectArrow } from "../../assets";

export const SelectWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const Select = styled.select`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 28px 8px 80px;
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.text.primary};
  appearance: none;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.5em;
  cursor: pointer;
  option {
    color: black;
    background-color: transparent;
    display: flex;
    min-height: 20px;
    padding: 0px 2px 1px;
    text-align: center;
    margin-right: 10px;
  }
`;

export const SelectTitle = styled.span`
  position: absolute;
  top: 9px;
  left: 12px;
  height: 15px;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.bodySmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.bodySmall.letterSpacing};
  color: ${({ theme }) => theme.colors.text.grey};
`;
