import styled from "styled-components";

export const MobileNavBack = styled.div`
  position: fixed;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  width: 100%;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const MobileNavWrapper = styled.div<{ isConnected?: boolean }>`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  z-index: 999;
  flex-basis: ${({ isConnected }) => (isConnected ? "unset" : "100%")};
`;
