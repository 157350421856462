import { ComponentPropsWithoutRef, forwardRef } from "react";
import { searchIcon } from "../../assets";
import * as S from "./SearchInput.styled";

type SearchInputProps = ComponentPropsWithoutRef<"input">;

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => (
    <S.InputWrapper>
      <S.SearchInput
        autoComplete="off"
        placeholder="Search..."
        ref={ref}
        {...props}
      />
      <S.SearchIcon src={searchIcon} />
    </S.InputWrapper>
  )
);
