import { useState } from "react";
import { eyeCloseRedesign, eyeOpenRedesign } from "../../assets";
import * as S from "./WalletAddressRedesign.styled";

export type WalletAddressProps = {
  address: string;
};

export const WalletAddressRedesign = ({ address }: WalletAddressProps) => {
  const [isAddressVisible, setIsAddressVisible] = useState(false);

  const toggleAddressVisible = () =>
    setIsAddressVisible((prevState) => !prevState);

  const displayedAddress = isAddressVisible
    ? address
    : `${address.substring(0, 8)}*****`;
  return (
    <div className="flex items-center gap-2">
      <S.Button
        type="button"
        onClick={toggleAddressVisible}
        aria-label={isAddressVisible ? "Hide address" : "Show address"}
      >
        {isAddressVisible ? (
          <img
            src={eyeCloseRedesign}
            alt="eye icon close"
            aria-label="Close eye"
          />
        ) : (
          <img
            src={eyeOpenRedesign}
            alt="eye icon open"
            aria-label="Open eye"
          />
        )}
      </S.Button>
      <S.Text className="break-all">{displayedAddress}</S.Text>
    </div>
  );
};
