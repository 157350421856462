import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { boostIcon } from "../../../assets";
import { Dropdown, Modal } from "../../../components";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { BoostBody } from "./BoostBody";
import { ClaimBody } from "./ClaimBody";
import { UpgradeBody } from "./UpgradeBody";
import { WithdrawBody } from "./WithdrawBody";

enum PoolActionModal {
  WITHDRAW = "WITHDRAW",
  CLAIM = "CLAIM",
  BOOST = "BOOST",
  UPGRADE = "UPGRADE",
}

type PoolActionsProps = {
  poolLevel: PoolLevel;
  onActionSuccess?: () => void;
  allLevels: PoolLevel[];
};

export const PoolActions = ({
  poolLevel,
  onActionSuccess,
  allLevels,
}: PoolActionsProps) => {
  const [openedModal, setOpenedModal] = useState<PoolActionModal>();

  const handleModalOpen = (modalType: PoolActionModal) => () =>
    setOpenedModal(modalType);

  const handleCloseModal = () => {
    setOpenedModal(undefined);
    if (onActionSuccess) {
      onActionSuccess();
    }
  };

  const dispatch = useAppDispatch();

  const isPoolEnded = dayjs().isAfter(poolLevel.pool.end);

  const hasNoDeposits = new BigNumber(poolLevel.deposit || "0").isZero();
  const hasNoRewards =
    new BigNumber(poolLevel.soilReward || "0").isZero() &&
    new BigNumber(poolLevel.usdcReward || "0").isZero();

  const higherLevelsOnPool = allLevels
    .filter((level) => level.pool.poolId === poolLevel.pool.poolId)
    .filter((levelOnThisPool) => levelOnThisPool.level > poolLevel.level);

  const rewardIsLocked = dayjs(poolLevel.unlockRewardsAfter).isAfter(dayjs());

  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );
  const menuItems = [
    {
      label: "Withdraw",
      onClick: handleModalOpen(PoolActionModal.WITHDRAW),
      isDisabled: hasNoDeposits,
    },
    {
      label: "Claim rewards",
      onClick: handleModalOpen(PoolActionModal.CLAIM),
      isDisabled: hasNoRewards || rewardIsLocked,
      tooltip: dayjs(poolLevel.unlockRewardsAfter).isAfter(dayjs())
        ? `Rewards are locked until ${dayjs(
            poolLevel.unlockRewardsAfter
          ).format("DD.MM.YYYY HH:mm")}`
        : undefined,
    },
    {
      label: "Upgrade",
      onClick: handleModalOpen(PoolActionModal.UPGRADE),
      isDisabled:
        hasNoDeposits ||
        isPoolEnded ||
        rewardIsLocked ||
        higherLevelsOnPool.length === 0,
      tooltip: dayjs(poolLevel.unlockRewardsAfter).isAfter(dayjs())
        ? `Upgrade is locked until ${dayjs(poolLevel.unlockRewardsAfter).format(
            "DD.MM.YYYY HH:mm"
          )}`
        : undefined,
    },
  ];

  if (poolLevel.boostPercentage && Number(poolLevel.boostPercentage) > 0) {
    menuItems.push({
      label: "Boost yield",
      onClick: handleModalOpen(PoolActionModal.BOOST),
      isDisabled: hasNoDeposits,
    });
  }

  useEffect(() => {
    if (openedModal === PoolActionModal.BOOST) {
      dispatch(actions.wallet.getBalances(walletAddress));
    }
  }, [openedModal === PoolActionModal.BOOST, walletAddress]);

  return (
    <>
      <Dropdown elements={menuItems} />
      <Modal
        title="Boost your yield"
        icon={boostIcon}
        isOpen={openedModal === PoolActionModal.BOOST}
        onClose={handleCloseModal}
      >
        <BoostBody poolLevel={poolLevel} closeModal={handleCloseModal} />
      </Modal>
      <Modal
        title="Withdraw"
        isOpen={openedModal === PoolActionModal.WITHDRAW}
        onClose={handleCloseModal}
      >
        <WithdrawBody poolLevel={poolLevel} closeModal={handleCloseModal} />
      </Modal>
      <Modal
        title="Claim rewards"
        isOpen={openedModal === PoolActionModal.CLAIM}
        onClose={handleCloseModal}
      >
        <ClaimBody poolLevel={poolLevel} closeModal={handleCloseModal} />
      </Modal>
      <Modal
        title="Upgrade to pool with higher APR"
        isOpen={openedModal === PoolActionModal.UPGRADE}
        onClose={handleCloseModal}
      >
        <UpgradeBody poolLevel={poolLevel} onSuccess={handleCloseModal} />
      </Modal>
    </>
  );
};
