import { usdcSymbol } from "../../assets";
import { config } from "../../config";
import { formatNumberSuffix } from "../../utils";
import { Typography } from "../Typography/Typography";
import * as S from "./ValueBars.styled";

type UsdcBarProps = {
  value: string | number;
  bordered?: boolean;
};

export const UsdcBar = ({ value, bordered }: UsdcBarProps) => (
  <S.UsdcBarWrapper bordered={bordered}>
    <S.CoinUsdcIconWrapper>
      <S.UsdcCoinLogo src={usdcSymbol} alt="stable coin logo" />
    </S.CoinUsdcIconWrapper>
    <Typography.BarUSDCLabel fontWeight={700}>
      {config.STABLE_TOKEN}
    </Typography.BarUSDCLabel>
    <Typography.BarValue fontWeight={700}>
      {value && Number(value) > 0 ? formatNumberSuffix(Number(value)) : "-"}
    </Typography.BarValue>
  </S.UsdcBarWrapper>
);
