import { QueryParams } from "../constants";
import { useGetSearchParam } from "./useGetSearchParam";
import { useSetSearchParam } from "./useSetSearchParam";

export const useSearchParamState = (
  param: QueryParams,
  defaultValue?: string
) => {
  const get = useGetSearchParam(param) || defaultValue;
  const set = useSetSearchParam(param);

  return [get, set] as const;
};
