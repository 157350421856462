import { useEffect } from "react";
import { useAccount } from "wagmi";
import { getNetwork } from "@wagmi/core";
import { AnimatePresence, motion } from "framer-motion";
import { Modal, Typography } from "../../../../../../components";
import { useModal } from "../../../../../../hooks";
import * as S from "../SwitchNetwork/SwitchNetwork.styled";
import {
  cursor,
  metamaskIcon,
  walletconnectIcon,
} from "../../../../../../assets";
import { useAppSelector } from "../../../../../../redux/hooks";
import { config } from "../../../../../../config";

const SwitchAccountNoAuth = () => {
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const { isOpenSignMessageModal } = useAppSelector((state) => state.ui);
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const { address, connector } = useAccount();
  const { chain } = getNetwork();

  useEffect(() => {
    if (
      chain?.id === Number(config.NETWORK_ID) &&
      !isLogged &&
      isOpenSignMessageModal
    ) {
      handleModalOpen();
    }
    if (!isOpenSignMessageModal) {
      handleModalClose();
    }
  }, [address, isOpenSignMessageModal, chain?.id]);

  return (
    <Modal
      title="Sign message"
      onClose={handleModalClose}
      isOpen={isModalOpen}
      withoutCloseIcon
    >
      <Typography.Body style={{ paddingBottom: "30px" }}>
        &quot;Sign signature&quot; request has been sent to your wallet app. You
        must approve signing for log-in or reject to stay logged out.
      </Typography.Body>
      <AnimatePresence>
        <Typography.Body style={{ paddingBottom: "20px" }} key="noAuthTypo">
          {connector?.name === "MetaMask"
            ? "Check the metamask extension in Your browser..."
            : "Check your wallet app, that You use with the walletConnect..."}
        </Typography.Body>
        <S.ClickAnimationWrapper key="noAuthAnimation">
          <motion.img
            width={70}
            height={70}
            src={
              connector?.name === "MetaMask" ? metamaskIcon : walletconnectIcon
            }
            alt="metamask icon"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            transition={{ repeat: Infinity, duration: 0.2, repeatDelay: 3 }}
          />
          <motion.img
            src={cursor}
            alt="cursor"
            width={20}
            style={{
              filter:
                "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7500%) hue-rotate(229deg) brightness(103%) contrast(105%)",
            }}
            height={20}
            initial={{ x: "-80px" }}
            animate={{ x: 0 }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 1.6,
            }}
          />
        </S.ClickAnimationWrapper>
      </AnimatePresence>
    </Modal>
  );
};

export default SwitchAccountNoAuth;
