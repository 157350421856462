import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";

dayjs.extend(Duration);

export const humanizePoolDuration = (
  timestamp: string | number,
  unit: Duration.DurationUnitType = "seconds"
) => {
  const duration = dayjs.duration(Math.abs(Number(timestamp)), unit);

  const months = Math.floor(duration.asMonths());
  const days = Math.floor(duration.days());

  let output = "";

  if (months > 0) {
    if (months === 1) {
      output += `${months} month `;
    } else {
      output += `${months} months `;
    }
  }

  if (days > 0 && months < 1) {
    if (days === 1) {
      output += `${days} day`;
    } else {
      output += `${days} days`;
    }
  }

  return output;
};
