import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSOILBalance, getUSDCBalance } from "../../services/wallet.service";

export type WalletDataType = {
  usdc: string;
  soil: string;
};

interface walletState {
  balances: WalletDataType;
  loading: boolean;
}

const initialState: walletState = {
  balances: {
    usdc: "0",
    soil: "0",
  },
  loading: false,
};

export const getBalances = createAsyncThunk(
  "wallet/getBalances",
  async (address: string) => {
    const usdc = await getUSDCBalance(address);
    const soil = await getSOILBalance(address);

    const balances = {
      usdc: usdc.toString(),
      soil: soil.toString(),
    };
    return balances;
  }
);

const resetWalletBalance = createAction("wallet/resetWalletData");

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBalances.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBalances.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getBalances.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.balances = { ...payload };
    });
    builder.addCase(resetWalletBalance, (state) => {
      state.balances = {
        usdc: "0",
        soil: "0",
      };
    });
  },
});

export const wallet = { getBalances, resetWalletBalance };

export default walletSlice.reducer;
