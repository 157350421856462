import { Signer } from "ethers";
import { config } from "../config";
import { ContractUserRole } from "../constants";
import { ProtocolSettings } from "./contracts";

const checkRole = async (user: string) => {
  const DEFAULT_ADMIN_ROLE = await ProtocolSettings.DEFAULT_ADMIN_ROLE();

  const [isSuperAdmin, isAdmin] = await Promise.all([
    ProtocolSettings.hasRole(DEFAULT_ADMIN_ROLE, user),
    ProtocolSettings.isAdmin(user),
  ]);

  if (isSuperAdmin) {
    return ContractUserRole.SUPER_ADMIN;
  }

  if (isAdmin) {
    return ContractUserRole.ADMIN;
  }

  return null;
};

const changeAdminRole = async (
  user: string,
  isAdmin: boolean,
  signer: Signer
) => {
  const ADMIN_ROLE = await ProtocolSettings.ADMIN_ROLE();

  let changeRolePromise;

  if (isAdmin) {
    changeRolePromise = ProtocolSettings.connect(signer).grantRole(
      ADMIN_ROLE,
      user
    );
  } else {
    changeRolePromise = ProtocolSettings.connect(signer).revokeRole(
      ADMIN_ROLE,
      user
    );
  }

  const { wait } = await changeRolePromise;

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

export const protocolSettingsService = { checkRole, changeAdminRole };
