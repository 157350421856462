import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";
import RelativeTime from "dayjs/plugin/relativeTime";
import { LoadingSkeleton, Typography } from "../../../components";
import { useAppSelector } from "../../../redux";

dayjs.extend(Duration);
dayjs.extend(RelativeTime);

export const LockoutInfo = () => {
  const lockoutTime = useAppSelector((store) => store.staking.lockoutTime);

  return (
    <Typography.Body fontWeight={300} opacity={0.9}>
      *Unlocking will lock up your unlocked $SOIL tokens for{" "}
      {lockoutTime ? (
        dayjs.duration(lockoutTime, "seconds").humanize()
      ) : (
        <LoadingSkeleton inline width="35px" />
      )}
    </Typography.Body>
  );
};
