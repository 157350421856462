// import dayjs from "dayjs";
import { PoolIcon } from "../PoolIcon";
import * as S from "./PoolTitle.styled";

type PoolTitleProps = {
  poolLevel: PoolLevel;
};

export const PoolTitle = (props: PoolTitleProps) => {
  const {
    poolLevel: {
      // createdAt,
      pool,
    },
  } = props;

  // const isRecentlyAdded = dayjs(createdAt).isAfter(dayjs().subtract(7, "days"));

  return (
    <S.TitleWrapper role="link" aria-label="Link to project details">
      <S.IconWrapper>
        <PoolIcon poolIcon={pool.icon} />
      </S.IconWrapper>
    </S.TitleWrapper>
  );
};
