import styled from "styled-components";

export const Wrapper = styled.button<{ isBig?: boolean }>`
  min-width: ${({ isBig }) => (isBig ? "20px" : "13.33px")};
  min-height: ${({ isBig }) => (isBig ? "20px" : "13.33px")};
  max-width: ${({ isBig }) => (isBig ? "20px" : "13.33px")};
  max-height: ${({ isBig }) => (isBig ? "20px" : "13.33px")};
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: transform 0.3s;

  img {
    width: 100%;
  }

  &:hover {
    transform: scale(1.15);
  }
`;
