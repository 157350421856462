import React from "react";
import NoExtension from "./components/NoExtension/NoExtension";
import SwitchNetwork from "./components/SwitchNetwork/SwitchNetwork";
import SwitchAccountNoAuth from "./components/SwitchAccountNoAuth/SwitchAccountNoAuth";
import SwitchAccountAuth from "./components/SwitchAccountAuth/SwitchAccountAuth";
import LoginWithTfa from "./components/LoginWithTfa/LoginWithTfa";
import KycPrevent from "./components/KycPrevent/KycPrevent";
import TfaForActionModal from "./components/TfaForActionModal/TfaForActionModal";
import { MissingKyc } from "./components/MissingKyc/MissingKyc";

const ConnectWalletAssets = () => (
  <>
    <NoExtension />
    <SwitchNetwork />
    <SwitchAccountNoAuth />
    <SwitchAccountAuth />
    <LoginWithTfa />
    <KycPrevent />
    <TfaForActionModal />
    <MissingKyc />
  </>
);

export default ConnectWalletAssets;
