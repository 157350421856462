import styled, { keyframes } from "styled-components";

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  background: transparent;
  backdrop-filter: blur(3px);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 300px;
`;

export const SmallLogo = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 4.5vmin;
  width: 4.5vmin;
  opacity: 0.8;
`;

export const Spinner = styled.div`
  animation: ${spin} 1s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: ${({ theme }) => theme.colors.greyDark};
  border-top-color: ${({ theme }) => theme.colors.greyDark};
  box-sizing: border-box;
  height: 20vmin;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20vmin;
  z-index: ${({ theme }) => theme.zIndex.spinner};

  &:before {
    animation: ${spin} 2s infinite linear;
    border: solid 2vmin transparent;
    border-radius: 50%;
    border-right-color: ${({ theme }) => theme.colors.greyMid};
    border-top-color: ${({ theme }) => theme.colors.greyMid};
    box-sizing: border-box;
    content: "";
    height: 16vmin;
    left: 0;
    position: absolute;
    top: 0;
    width: 16vmin;
  }

  &:after {
    content: "";
    animation: ${spin} 3s infinite linear;
    border: solid 2vmin transparent;
    border-radius: 50%;
    border-right-color: ${({ theme }) => theme.colors.grey};
    border-top-color: ${({ theme }) => theme.colors.grey};
    box-sizing: border-box;
    height: 12vmin;
    position: absolute;
    left: 2vmin;
    top: 2vmin;
    width: 12vmin;
  }
`;
