import styled from "styled-components";
import { device } from "../../../../style";

export const Wrapper = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-right: 16px;
  position: static;
  background: rgba(0, 0, 0, 0.4);

  @media ${device.tablet} {
    padding-left: 31px;
    padding-right: 30px;
  }

  @media ${device.laptop} {
    padding-left: 31px;
    padding-right: 26px;
    justify-content: flex-end;
    position: static;
    background: transparent;
  }
`;

export const DesktopWrapper = styled.div<{
  isProjectDetailPathname?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ isProjectDetailPathname }) =>
    isProjectDetailPathname ? "space-between" : "flex-end"};
  margin-right: 3px;
  margin-left: 40px;

  @media ${device.laptop} {
    margin-left: 0;
  }
`;

export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 20px;
  cursor: pointer;
  align-self: flex-start;
  z-index: 1;

  img {
    width: 30px;
    height: 30px;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 18px;
  height: 26px;
  min-width: 18px;
  min-height: 26px;
`;
