import { toast as reactToastifyToast } from "react-toastify";
import { getErrorMessage } from "../utils";
import ToastBody from "./ToastBody";

const success = (title: string, message: string) =>
  reactToastifyToast.success(<ToastBody title={title} message={message} />);

const loading = (title: string, message: string) =>
  reactToastifyToast.loading(<ToastBody title={title} message={message} />);

const error = (title: string, message: string) =>
  reactToastifyToast.error(<ToastBody title={title} message={message} />);

const errorHandler = (e: unknown, title: string, defaultError?: string) => {
  const errorMessage = getErrorMessage(e, defaultError);

  return reactToastifyToast.error(
    <ToastBody title={title} message={errorMessage} />
  );
};

export const toast = { success, error, errorHandler, loading };
