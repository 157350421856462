import styled from "styled-components";
import { Typography } from "../Typography/Typography";
import { device } from "../../style";

type WrappersProps = {
  awarded?: boolean;
  single?: boolean;
  center?: boolean;
};

const Wrapper = styled.div<WrappersProps>`
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: ${({ awarded }) => (awarded ? "1px solid" : "")}
    ${({ theme, awarded }) => (awarded ? theme.colors.primary : null)};

  @media ${device.laptop} {
    width: ${(props) => (props.single ? "32%" : "100%")};
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 12px;
`;

export const WrapperPrimary = styled(Wrapper)<WrappersProps>`
  background-color: ${({ theme }) => theme.colors.boxBackground};
  align-items: ${(props) => (props.center ? "center" : "")};
  gap: 30px;
  border-radius: 12px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);
`;

export const WrapperSecondary = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.boxBackground};
  gap: 15px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`;

export const WrapperAdmin = styled(Wrapper)`
  background-color: ${({ theme, awarded }) =>
    awarded ? theme.colors.primary : theme.colors.boxBackground};
  gap: 12px;
  width: 100%;
`;

export const Title = styled(Typography.HeaderSmall)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;
  font-size: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 700;
`;

export const ContentPrimary = styled(Content)<{ center?: boolean }>`
  gap: 30px;
  align-items: ${(props) => (props.center ? "center" : "")};
`;

export const ContentSecondary = styled(Content)`
  gap: 15px;
`;

export const AdminTitle = styled(Typography.HeaderSmall)`
  color: ${({ theme }) => theme.colors.text.grey};
`;

export const Image = styled.img`
  width: 90px;
  height: 26px;
`;
