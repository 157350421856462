import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { QueryParams } from "../constants";

const DEFAULT_ITEMS_PER_PAGE = 10;

export const usePaginationQueryParams = (
  defaultItemsPerPage = DEFAULT_ITEMS_PER_PAGE
) => {
  const [params, setParams] = useSearchParams();

  const page = params.get(QueryParams.PAGE);
  const itemsPerPage = params.get(QueryParams.ITEMS_PER_PAGE);

  const setPage = useCallback(
    (newPage: number) => {
      setParams((param) => {
        param.set(QueryParams.PAGE, String(newPage));
        return param;
      });
    },
    [setParams, params]
  );

  const changeItemsPerPage = useCallback(
    (newItemsPerPage: number) => {
      setParams((param) => {
        param.set(QueryParams.ITEMS_PER_PAGE, String(newItemsPerPage));
        param.set(QueryParams.PAGE, String(1));

        return param;
      });
    },
    [setParams, params]
  );

  return {
    page: page ? Number(page) : 1,
    itemsPerPage: itemsPerPage ? Number(itemsPerPage) : defaultItemsPerPage,
    setPage,
    changeItemsPerPage,
  };
};
