import styled from "styled-components";
import { selectArrow } from "../../assets";

export const PaginationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.box.background.primary};
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;

export const DisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 19px;
  gap: 14px;
`;

export const Display = styled.span`
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #ffffff;
  opacity: 0.9;
`;

export const Select = styled.select`
  width: 40px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grey};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontStyles.body.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.body.lineHeight};
  border: none;
  margin-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 6px;

  option {
    color: black;
    background: transparent;
    display: flex;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;

  .pagination {
    display: flex;
    color: ${({ theme }) => theme.colors.grey};

    li {
      margin: 0px 5px;
    }

    .activePage {
      color: ${({ theme }) => theme.colors.white};
      font-weight: 700;
    }
  }
`;
