import dayjs from "dayjs";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { inputSoilIcon, inputStableIcon } from "../../../../assets";
import { Button, FormInput, MBC, Typography } from "../../../../components";
import { config } from "../../../../config";
import { ConnectWalletButton } from "../../../ConnectWalletButton";
import { PoolIcon } from "../../PoolIcon";
import { usePoolClaimHandler } from "./usePoolClaimHandler";

type ClaimBodyProps = {
  poolLevel: PoolLevel;
  closeModal: () => void;
};

export const ClaimBody = ({ poolLevel, closeModal }: ClaimBodyProps) => {
  const { isConnected } = useAccount();
  const { unlockRewardsAfter, wasSoftCapExceeded } = poolLevel;

  const canClaim =
    unlockRewardsAfter &&
    dayjs().isAfter(dayjs(unlockRewardsAfter)) &&
    wasSoftCapExceeded;

  const { isClaimInProgress, submitClaimRewards } = usePoolClaimHandler(
    poolLevel,
    closeModal
  );

  return (
    <MBC.Form onSubmit={submitClaimRewards}>
      <MBC.LogoWrapper>
        <PoolIcon poolIcon={poolLevel.pool.icon} />
      </MBC.LogoWrapper>
      <MBC.HeaderWithLogoWrapper smallGap center style={{ gap: "12px" }}>
        <MBC.InfoBox>
          <MBC.InfoRow as="li">
            Pool
            <span>{poolLevel.name}</span>
          </MBC.InfoRow>
        </MBC.InfoBox>
      </MBC.HeaderWithLogoWrapper>
      <MBC.InfoBox>
        <FormInput
          name="claimUSDC"
          label="USDC"
          logo={inputStableIcon}
          disabled
          value={ethers.utils.formatUnits(
            poolLevel.usdcReward || "0",
            config.USDC_TOKEN_DECIMALS
          )}
        />
        <FormInput
          name="claimSOIL"
          label="SOIL"
          logo={inputSoilIcon}
          disabled
          style={{ marginBottom: "8px" }}
          value={ethers.utils.formatUnits(
            poolLevel.soilReward || "0",
            config.SOIL_TOKEN_DECIMALS
          )}
        />
      </MBC.InfoBox>
      {!canClaim ? (
        <Typography.Body>
          Rewards will be claimable after end of pool
        </Typography.Body>
      ) : null}
      {isConnected ? (
        <Button.Primary
          type="submit"
          bigSize
          disabled={isClaimInProgress || !canClaim}
        >
          Claim
        </Button.Primary>
      ) : (
        <ConnectWalletButton />
      )}
    </MBC.Form>
  );
};
