import styled from "styled-components";
import { plusIcon } from "../../../assets";
import { Typography } from "../../../components";

export const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 52px;
`;

export const Select = styled.input<{ error?: string }>`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 28px 8px 6px;
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.text.primary};
  appearance: none;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.error : theme.colors.greyDark};

  cursor: pointer;
`;

export const AddButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 32px;
  text-decoration: none;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: transparent;
  background-image: url(${plusIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  transition: all 0.2s;
  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const ErrorMessage = styled(Typography.BodySmall)`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.error};
  text-transform: uppercase;
`;
