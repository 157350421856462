import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../style";

export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LogoWrapper = styled(motion.div)`
  width: 37px;
  height: 56px;
  align-self: center;
  margin-bottom: 75px;
  margin-top: 40px;

  @media ${device.mobileL} {
    width: 50px;
    height: 75px;
  }

  @media ${device.tablet} {
    width: 61px;
    height: 92px;
    margin-bottom: 90px;
    margin-top: 87px;
  }
`;
