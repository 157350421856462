import React from "react";
import styled from "styled-components";

type PDFViewerType = {
  urlToPDF: string;
};

const Container = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  iframe {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    body,
    html {
      height: 100%;
      overflow: hidden;
      background: transparent;
    }
  }
`;

export const PDFViewer = ({ urlToPDF }: PDFViewerType) => (
  <Container>
    <iframe title="Loan agreement" src={urlToPDF} width="100%" height="800" />
  </Container>
);
