import styled from "styled-components";

export const UserToolWrapper = styled.ul`
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 18.5px;
`;

export const UserToolElement = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 10px;

  &:hover {
    opacity: 0.8;
  }
`;
