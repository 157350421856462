export const translateMillisecondsToText = (time: number) => {
  const seconds = time / 1000;
  const minutes = time / (1000 * 60);
  const hours = time / (1000 * 60 * 60);
  const days = time / (1000 * 60 * 60 * 24);

  if (Number(days.toFixed()) === 1) return `${days.toFixed()} day ago`;
  if (Number(days.toFixed()) > 1) return `${days.toFixed()} days ago`;

  if (Number(hours.toFixed()) === 1) return `${hours.toFixed()} hour ago`;
  if (Number(hours.toFixed()) > 1) return `${hours.toFixed()} hours ago`;

  if (Number(minutes.toFixed()) === 1) return `${minutes.toFixed()} minute ago`;
  if (Number(minutes.toFixed()) > 1) return `${minutes.toFixed()} minutes ago`;

  if (Number(seconds.toFixed()) === 1) return `${seconds.toFixed()} second ago`;
  if (Number(seconds.toFixed()) > 1) return `${seconds.toFixed()} seconds ago`;
};
