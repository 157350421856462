import * as S from "./Logo.styled";
import { logo, adminLogo } from "../../../../assets";

export const Logo = () => (
  <S.Wrapper>
    <img src={logo} alt="logo" />
  </S.Wrapper>
);

export const AdminLogo = () => (
  <S.Wrapper>
    <img src={adminLogo} alt="logo" />
  </S.Wrapper>
);
