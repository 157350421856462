import styled, { keyframes } from "styled-components";

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${spin} 1s infinite linear;
  border: solid 3px transparent;
  border-radius: 50%;
  border-right-color: ${({ theme }) => theme.colors.greyDark};
  border-top-color: ${({ theme }) => theme.colors.greyDark};
  box-sizing: border-box;
  height: 25px;
  margin: auto;
  width: 25px;
  z-index: 1;

  &:before {
    animation: ${spin} 2s infinite linear;
    border: solid 2px transparent;
    border-radius: 50%;
    border-right-color: ${({ theme }) => theme.colors.greyMid};
    border-top-color: ${({ theme }) => theme.colors.greyMid};
    box-sizing: border-box;
    content: "";
    height: 17px;
    left: 0;
    position: absolute;
    top: 0;
    width: 17px;
  }
`;
