import styled from "styled-components";

export type DropdownProps = {
  targetIcon: string;
};

export const Dropdown = styled.div.withConfig({
  shouldForwardProp: (prop) => !["targetIcon"].includes(prop),
})<DropdownProps>`
  position: absolute;
  border-radius: 8px;
  height: auto;
  top: 43px;
  right: -14px;
  /* border: 1px solid ${({ theme }) => theme.colors.primary}; */
  background: ${({ theme }) => theme.colors.toolbar.background};
  box-shadow: ${({ theme }) => theme.colors.toolbar.boxShadowDark};
  z-index: ${({ theme }) => theme.zIndex.dropdown.menu};
`;

export const DropdownMark = styled.img`
  position: absolute;
  top: -7px;
  z-index: ${({ theme }) => theme.zIndex.toolbar.dropdown};
  filter: invert(30%) sepia(64%) saturate(432%) hue-rotate(107deg)
    brightness(98%) contrast(102%);
`;
