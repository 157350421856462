import { ActionCreator, AnyAction, AsyncThunkAction } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useAppDispatch } from "../redux";

export const useReduxActionInterval = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ActionCreator<AnyAction | AsyncThunkAction<any, any, any>>,
  ms: number
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(action());
    }, ms);

    dispatch(action());

    return () => {
      clearInterval(intervalId);
    };
  }, []);
};
