import * as staking from "./staking";
import * as user from "./user";
import * as pools from "./pools";
import * as rate from "./rate";
import * as notification from "./notification";

export const api = {
  staking,
  user,
  pools,
  rate,
  notification,
};
