import styled from "styled-components";
import { device } from "../../../../style";

export const Wrapper = styled.nav`
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.nav};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  top: 0;
  bottom: 64px;
  padding-top: 30px;
  padding-left: 0;
  min-width: 100%;
  right: 0;
  height: 100%;

  @media ${device.laptop} {
    border-right: 1px solid rgba(38, 166, 119, 0.44);
    background: rgba(0, 0, 0, 0.6);
    min-width: 120px;
    position: static;
    z-index: initial;
    padding-top: 30px;
    padding-left: 17px;
    min-height: 100%;
    height: 100%;
    display: block;
    gap: 29px;
    position: relative;
  }

  @media ${device.laptopM} {
    border-right: 1px solid rgba(38, 166, 119, 0.44);
    background: rgba(0, 0, 0, 0.6);
    min-width: 160px;
    position: static;
    z-index: initial;
    padding-top: 30px;
    padding-left: 28px;
    min-height: 100%;
    height: 100%;
    display: block;
    gap: 29px;
  }
`;

export const NavWrapper = styled.ul`
  margin-top: 144px;
  align-items: flex-end;
  text-align: right;
  gap: 34px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: auto;
  left: auto;

  span {
    text-align: right;

    @media ${device.laptop} {
      text-align: left;
    }
  }

  @media ${device.laptop} {
    padding-left: 0;
    width: 90px;
    height: 26px;
    margin-top: 48px;
    gap: 27px;
    align-items: flex-start;
    text-align: initial;
  }
`;

export const HamburgerBtn = styled.div`
  width: 19px;
  height: 19px;
  left: 30px;
  top: 25px;
  z-index: ${({ theme }) => theme.zIndex.hamburger};
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;

  @media ${device.mobileL} {
    right: 31px;
    top: 25px;
  }

  div {
    background: white;
    border-radius: 30px;
    width: 30px;
    height: 3px;
  }
`;
