import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../../style";

export type TypographyProps = {
  uppercase?: boolean;
  fontWeight?: number;
  opacity?: number;
  isWhite?: boolean;
  noLineHeight?: boolean;
};

export const textPrototype = styled(motion.p).withConfig({
  shouldForwardProp: (prop) =>
    !["isWhite"].includes(prop) && !["uppercase"].includes(prop),
})<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  text-transform: ${({ uppercase }) => uppercase && "uppercase"};
  opacity: ${({ opacity }) => opacity && opacity};
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.white : theme.colors.grey};
`;

export const valuePrototype = styled.span<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  text-transform: ${({ uppercase }) => uppercase && "uppercase"};
  opacity: ${({ opacity }) => opacity && opacity};
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.white : theme.colors.grey};
`;

export const titlePrototype = styled.h1<TypographyProps>`
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  text-transform: ${({ uppercase }) => uppercase && "uppercase"};
  opacity: ${({ opacity }) => opacity && opacity};
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.white : theme.colors.grey};
`;

const Big = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.big.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.big.fontSize
      : theme.fontStyles.big.lineHeight};
`;

const HeaderBig = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.headerBig.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.headerBig.fontSize
      : theme.fontStyles.headerBig.lineHeight};
`;

const Header = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.header.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.header.fontSize
      : theme.fontStyles.header.lineHeight};
`;

const HeaderMedium = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.headerMedium.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.headerMedium.fontSize
      : theme.fontStyles.headerMedium.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.headerMedium.letterSpacing};
`;

const HeaderSmall = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.headerSmall.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.headerSmall.fontSize
      : theme.fontStyles.headerSmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.headerSmall.letterSpacing};
`;

const Body = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.body.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.body.fontSize
      : theme.fontStyles.body.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.body.letterSpacing};
`;

const BodySmall = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.bodySmall.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.bodySmall.fontSize
      : theme.fontStyles.bodySmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.bodySmall.letterSpacing};
`;

const Medium = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.medium.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.medium.fontSize
      : theme.fontStyles.medium.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.medium.letterSpacing};
`;

const Description = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.description.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.description.fontSize
      : theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.description.letterSpacing};
`;

const TimeStamp = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.messageTime.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.messageTime.fontSize
      : theme.fontStyles.messageTime.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.messageTime.letterSpacing};
`;

const ToastTitle = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.toast.title.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.toast.title.fontSize
      : theme.fontStyles.toast.title.lineHeight};
  color: ${({ theme }) => theme.colors.toast.title};
`;

const ToastMessage = styled(textPrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.toast.message.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.toast.message.fontSize
      : theme.fontStyles.toast.message.lineHeight};
  color: ${({ theme }) => theme.colors.toast.message};
`;

const CurrencyValueText = styled(valuePrototype)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.headerSmall.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    noLineHeight
      ? theme.fontStyles.headerSmall.fontSize
      : theme.fontStyles.headerSmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.headerSmall.letterSpacing};
`;

const CurrencyValueTableText = styled(valuePrototype)<{ isLevel?: boolean }>`
  font-size: ${({ theme, isLevel }) =>
    isLevel
      ? theme.fontStyles.bodySmall.fontSize
      : theme.fontStyles.body.fontSize};
  line-height: ${({ theme, isLevel }) =>
    isLevel
      ? theme.fontStyles.bodySmall.lineHeight
      : theme.fontStyles.body.lineHeight};
  letter-spacing: ${({ theme, isLevel }) =>
    isLevel
      ? theme.fontStyles.bodySmall.letterSpacing
      : theme.fontStyles.body.letterSpacing};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const CurrencyValueBiggerText = styled(CurrencyValueText)<TypographyProps>`
  font-size: ${({ theme }) => theme.fontStyles.header.fontSize};
  line-height: ${({ theme, noLineHeight }) =>
    !noLineHeight && theme.fontStyles.header.lineHeight};
`;

const CurrencyValueCurrency = styled(valuePrototype)<{ isLevel?: boolean }>`
  font-size: ${({ theme, isLevel }) =>
    isLevel
      ? theme.fontStyles.bodySmall.fontSize
      : theme.fontStyles.description.fontSize};
  line-height: ${({ theme, isLevel, noLineHeight }) =>
    isLevel
      ? noLineHeight
        ? theme.fontStyles.bodySmall.fontSize
        : theme.fontStyles.bodySmall.lineHeight
      : noLineHeight
      ? theme.fontStyles.description.fontSize
      : theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme, isLevel }) =>
    isLevel
      ? theme.fontStyles.bodySmall.letterSpacing
      : theme.fontStyles.description.letterSpacing};
`;

const ModalTitle = styled(titlePrototype)`
  font-size: 20px;
  line-height: ${({ theme }) => theme.fontStyles.header.lineHeight};
  font-weight: 700;

  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontStyles.header.fontSize};
  }
`;

const GreenValue = styled(textPrototype)`
  color: #26a677;
  font-family: Ingra;
  font-size: 13.308px;
  font-style: normal;
  line-height: 93.5%; /* 12.443px */

  @media ${device.tablet} {
    font-size: 25px;
  }
`;

const IngraLabel = styled(textPrototype)`
  color: #fff;
  font-family: Ingra;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 93.5%; /* 10.285px */

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const IngraTitle = styled(textPrototype)`
  color: #afbdd7;
  font-family: Ingra;
  font-size: 18px;
  font-style: normal;
  line-height: 93.5%; /* 16.83px */
`;

const Balance = styled(textPrototype)`
  color: #fff;
  font-family: Ingra;
  font-size: 14px;
  font-style: normal;
  line-height: 93.5%;

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

const BarValue = styled(textPrototype)`
  color: #fff;
  font-family: Ingra;
  font-size: 18px;
  font-style: normal;
  line-height: 93.5%; /* 16.83px */
`;

const BarUSDCLabel = styled(textPrototype)`
  color: #2775ca;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  line-height: 93.5%; /* 11.22px */
`;

const BarSoilLabel = styled(BarUSDCLabel)`
  color: #08a98a;
`;

const IngraSubtitle = styled(textPrototype)`
  color: #afbdd7;
  font-family: Ingra;
  font-size: 14px;
  font-style: normal;
  line-height: 93.5%; /* 13.09px */
`;

const IngraSmallLabel = styled(textPrototype)`
  color: #fff;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  line-height: 93.5%;
  text-transform: lowercase;
`;

const ErrorText = styled(ToastMessage)`
  color: ${({ theme }) => theme.colors.error};
`;

export const Typography = {
  Big,
  HeaderBig,
  Header,
  HeaderMedium,
  HeaderSmall,
  Body,
  BodySmall,
  Medium,
  Description,
  TimeStamp,
  ToastTitle,
  ToastMessage,
  CurrencyValueText,
  CurrencyValueBiggerText,
  CurrencyValueTableText,
  CurrencyValueCurrency,
  ModalTitle,
  IngraLabel,
  GreenValue,
  IngraTitle,
  Balance,
  BarValue,
  BarUSDCLabel,
  BarSoilLabel,
  IngraSubtitle,
  IngraSmallLabel,
  ErrorText,
};
