import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { config } from "../../config";
import { poolsContractService } from "../../services";

interface IPoolState {
  poolsContractFee: string | null;
  poolsContractFeeFormatted: string | null;
  isError: boolean;
}

const initialPoolState: IPoolState = {
  poolsContractFee: null,
  poolsContractFeeFormatted: null,
  isError: false,
};

const getPoolsContractFee = createAsyncThunk("pool/getFee", async () => {
  const fee = await poolsContractService.getPoolContractFees();

  return {
    fee: fee.toString(),
    formatted: ethers.utils.formatUnits(fee, config.USDC_TOKEN_DECIMALS),
  };
});

export const poolSlice = createSlice({
  name: "pool",
  initialState: initialPoolState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPoolsContractFee.pending, (state) => {
      state.isError = false;
    });
    builder.addCase(getPoolsContractFee.fulfilled, (state, { payload }) => {
      state.poolsContractFee = payload.fee;
      state.poolsContractFeeFormatted = payload.formatted;
    });
    builder.addCase(getPoolsContractFee.rejected, (state) => {
      state.poolsContractFee = null;
      state.poolsContractFeeFormatted = null;
      state.isError = true;
    });
  },
});

export const pool = {
  getPoolsContractFee,
};

export default poolSlice.reducer;
