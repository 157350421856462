import { useNavigate } from "react-router-dom";
import { useDisconnect, useSignMessage } from "wagmi";
import { getNetwork } from "@wagmi/core";
import { toast } from "../toasts";
import { routes } from "../utils/routes";
import { initLogin } from "../api/auth";
import {
  getUserData,
  loginUser,
  loginUserTfa,
  logoutUser,
} from "../redux/features/userSlice";
import { useAppDispatch } from "../redux/hooks";
import { config } from "../config";
import { isAdminRole } from "../utils";
import {
  closeSignMessageModal,
  openSignMessageModal,
} from "../redux/features/uiSlice";
import { staking } from "../redux/features/stakingSlice";
import { wallet } from "../redux/features/walletSlice";

export const useAuth = () => {
  const { signMessageAsync } = useSignMessage();
  const { chain } = getNetwork();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { disconnectAsync } = useDisconnect();

  const tfaLogin = async (code: string) => {
    try {
      await dispatch(loginUserTfa(code)).unwrap();
      await dispatch(getUserData()).unwrap();
      toast.success("Login successful", "You have been logged in successfully");
    } catch {
      toast.error("Failed to login", "Login has failed, try again later");
    }
  };

  const logout = async () => {
    try {
      await disconnectAsync();
      await dispatch(logoutUser()).unwrap();
      dispatch(staking.resetStakingData());
      dispatch(wallet.resetWalletBalance());
      toast.success(
        "Logout successful",
        "You have been successfully logged out"
      );
    } catch {
      toast.success(
        "Logout successful",
        "You have been successfully logged out"
      );
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  };

  const rejectLogin = async () => {
    try {
      await disconnectAsync();
      await dispatch(logoutUser()).unwrap();
      dispatch(staking.resetStakingData());
      dispatch(wallet.resetWalletBalance());
    } catch (error) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  };
  const login = async (address: string) => {
    if (chain?.id === Number(config.NETWORK_ID)) {
      try {
        const response = await initLogin(address);
        const { message } = response.data;
        dispatch(openSignMessageModal());
        const signature = await signMessageAsync({ message });
        dispatch(closeSignMessageModal());
        const data = { address, signedMessage: signature };
        const payload = await dispatch(loginUser(data)).unwrap();
        const { tfaEnabled } = payload;
        if (!tfaEnabled) {
          const userData = await dispatch(getUserData()).unwrap();
          const isAdmin = isAdminRole(userData.roles);
          toast.success(
            "Login successful",
            "You have been logged in successfully"
          );
          if (isAdmin) {
            navigate(routes.adminPanel.dashboard);
          }
          localStorage.removeItem("closedByUser");
        } else {
          /* eslint-disable */
          return;
          /* eslint-enable */
        }
      } catch (error) {
        rejectLogin();
        toast.error("Failed to login", "Login has failed, try again later");
        dispatch(closeSignMessageModal());
      }
    }
  };

  return {
    login,
    tfaLogin,
    logout,
  };
};
