import styled from "styled-components";

export const CurrencyValueWrapper = styled.p`
  display: flex;
  align-items: center;
`;

export const CurrencyValueInlineWrapper = styled.span`
  display: flex;
  align-items: flex-end;
  vertical-align: bottom;
`;
