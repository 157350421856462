import { useCallback } from "react";
import { QueryParams } from "../../constants";
import { SortDirection } from "../../constants/sort";
import { useSearchParamState } from "../../hooks";
import { parseSortParam } from "../../utils";

export const useSortableMechanism = (sortQueryParamName: QueryParams) => {
  const [sortParam, setSortParam] = useSearchParamState(sortQueryParamName);

  const [sortKey, sortDirection] = sortParam
    ? parseSortParam(sortParam)
    : [undefined, undefined];

  const handleSort = useCallback(
    (key: string) => {
      let direction: SortDirection | undefined = SortDirection.ASC;

      if (key === sortKey) {
        if (sortDirection === SortDirection.ASC) {
          direction = SortDirection.DESC;
        } else if (sortDirection === SortDirection.DESC) {
          setSortParam(undefined);
          return;
        }
      }

      setSortParam(`${key}:${direction}`);
    },
    [sortKey, sortDirection]
  );

  return { sortKey, sortDirection, handleSort };
};
