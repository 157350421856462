/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, BigNumberish, ethers } from "ethers";
import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  afterDecimals,
  formatBigNumber,
  triggerInputChange,
} from "../../utils";
import * as S from "./CurrencyInput.styled";

export type CurrencyInputProps = {
  name: string;
  label: string;
  logo: string;
  title?: string;
  available?: BigNumberish;
  isReversed?: boolean;
  decimals: number;
  loading?: boolean;
  onErrorChange?: (isError: boolean) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const ErrorMessage = {
  error: "Please provide an amount",
  warning: "You do not have enough money",
};

export const CurrencyInput = forwardRef<
  HTMLInputElement | null,
  CurrencyInputProps
>(
  (
    {
      logo,
      label,
      name,
      title,
      isReversed,
      value,
      available,
      onChange,
      loading,
      onErrorChange,
      decimals,
      ...rest
    },
    ref
  ) => {
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");

    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (afterDecimals(e.target.value) > decimals) {
        e.preventDefault();
        return;
      }

      if (onChange) {
        onChange(e);
      }
      setIsTouched(true);
    };

    const handleMax = (e: React.MouseEvent) => {
      e.preventDefault();
      if (available) {
        const newValue = ethers.utils.formatUnits(available, decimals);
        if (innerRef.current) triggerInputChange(innerRef.current, newValue);
      }
    };

    const handleHalf = (e: React.MouseEvent) => {
      e.preventDefault();
      if (available) {
        const newValue = ethers.BigNumber.from(available).div(2);
        const formatValue = ethers.utils.formatUnits(newValue, decimals);
        if (innerRef.current) triggerInputChange(innerRef.current, formatValue);
      }
    };

    useEffect(() => {
      const isError = isTouched && Number(value || 0) === 0;

      if (available) {
        const isWarning =
          value &&
          ethers.utils.parseUnits(String(value), decimals).gt(available);
        setWarning(isWarning ? ErrorMessage.warning : "");
      }

      setError(isError ? ErrorMessage.error : "");
    }, [value, available]);

    useEffect(() => {
      if (onErrorChange) {
        onErrorChange(Boolean(error || warning));
      }
    }, [error, warning]);

    return (
      <S.Container>
        <S.Wrapper
          title={title}
          warning={warning}
          $loading={loading}
          data-testid="wrapper"
        >
          {title && (
            <S.Title isReversed={isReversed} data-testid="title">
              {title}
            </S.Title>
          )}
          <S.InputWrapper isReversed={isReversed}>
            <S.LabelWrapper as="label" htmlFor={name}>
              <S.Logo src={logo} alt="logo" />
              <S.Label>{label}</S.Label>
            </S.LabelWrapper>
            <S.Input
              type="number"
              step=".01"
              id={name}
              name={name}
              ref={innerRef}
              placeholder="0"
              data-testid="input"
              isReversed={isReversed}
              value={value}
              onChange={handleChange}
              {...rest}
            />
          </S.InputWrapper>
          {loading && <S.InputSpinner />}
        </S.Wrapper>
        <S.InfoWrapper data-testid="infoWrapper">
          {warning ? (
            <S.WarningMessage uppercase>{warning}</S.WarningMessage>
          ) : null}
          {available !== undefined && (
            <>
              <S.AvailableTitle uppercase>
                Available{" "}
                <span>
                  {formatBigNumber(String(available), decimals)} {label}
                </span>
              </S.AvailableTitle>
              <S.ButtonsWrapper>
                <S.SmallButton
                  disabled={rest.disabled || BigNumber.from(available).isZero()}
                  uppercase
                  as="button"
                  onClick={handleMax}
                >
                  Max
                </S.SmallButton>
                <S.SmallButton
                  disabled={rest.disabled || BigNumber.from(available).isZero()}
                  uppercase
                  as="button"
                  onClick={handleHalf}
                >
                  Half
                </S.SmallButton>
              </S.ButtonsWrapper>
            </>
          )}
        </S.InfoWrapper>
      </S.Container>
    );
  }
);
