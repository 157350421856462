import { toast } from "../toasts";
import { useAppDispatch, useAppSelector } from "../redux";
import {
  authenticateTfaStake,
  authenticateTfaUnStake,
  authenticateTfaCompound,
  authenticateTfaHarvest,
  authenticateTfaDeposit,
  authenticateTfaClaim,
} from "../redux/features/userSlice";
import { authTfa } from "../api/tfa";

export const useTfa = () => {
  const dispatch = useAppDispatch();
  const { currentAction } = useAppSelector((state) => state.user);

  const authenticateWithTfa = async (code: string) => {
    try {
      const { data } = await authTfa(code);
      const { refreshToken, accessToken } = data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      switch (currentAction) {
        case "stake": {
          return dispatch(authenticateTfaStake());
        }
        case "unstake": {
          return dispatch(authenticateTfaUnStake());
        }
        case "compound": {
          return dispatch(authenticateTfaCompound());
        }
        case "harvest": {
          return dispatch(authenticateTfaHarvest());
        }
        case "deposit": {
          return dispatch(authenticateTfaDeposit());
        }
        case "claim": {
          return dispatch(authenticateTfaClaim());
        }
        default:
      }
    } catch {
      toast.error("Tfa authentication failed", "Try again with correct code.");
    }
  };

  return {
    authenticateWithTfa,
  };
};
