import React, { useState } from "react";
import { MBC, SmallSpinner, Typography } from "../../../components";
import { BackendUserRole } from "../../../constants";
import { protocolSettingsService } from "../../../services/protocolSettings.service";
import { toast } from "../../../toasts";
import { useEthersSigner } from "../../../utils";
import * as S from "./ChangeUserRoleForm.styled";

type ChangeUserRoleFormProps = {
  closeModal: () => void;
  userWalletAddress: string;
  onSuccess?: () => void;
};

export const ChangeUserRoleForm = ({
  closeModal,
  userWalletAddress,
  onSuccess,
}: ChangeUserRoleFormProps) => {
  const [newRole, setNewRole] = useState("user");
  const [isLoading, setIsLoading] = useState(false);

  const signer = useEthersSigner();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!signer) {
        throw new Error("Wallet is not connected.");
      }
      await protocolSettingsService.changeAdminRole(
        userWalletAddress,
        newRole === BackendUserRole.ADMIN,
        signer
      );

      toast.success(
        "Role Successfully Changed",
        `User Role has been successfully Changed`
      );

      if (onSuccess) onSuccess();
    } catch (error: unknown) {
      toast.errorHandler(error, `Unable to change user role`);
    }
    setIsLoading(false);
    closeModal();
  };

  const handleRoleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNewRole(event.target.value);
  };

  return isLoading ? (
    <SmallSpinner />
  ) : (
    <MBC.Form onSubmit={handleSubmit}>
      <S.DescriptionWrapper>
        <S.SelectWrapper>
          <S.FilterSelect value={newRole} onChange={handleRoleChange}>
            <option key="User" value="user">
              User
            </option>
            <option key="Admin" value="admin">
              Admin
            </option>
          </S.FilterSelect>
        </S.SelectWrapper>
        <Typography.BodySmall>
          Please remember that an administrator is able to perform
          administrative actions on the contracts like withdrawing charged fees
          and modifying the state of contracts
        </Typography.BodySmall>
      </S.DescriptionWrapper>
      <S.SubmitButton type="submit">Submit</S.SubmitButton>
    </MBC.Form>
  );
};
