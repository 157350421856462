/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as S from "./DurationPicker.styled";

type DurationPickerProps = {
  value: Date[];
  error?: string;
  title?: string;
  onChange: any;
};

export const DurationPicker = ({
  error,
  title,
  value,
  onChange,
}: DurationPickerProps) => (
  <S.Container>
    {title && <S.Title>{title}</S.Title>}
    <S.DatePickerWrapper
      onChange={onChange}
      error={error}
      selectsRange
      startDate={value ? value[0] : null}
      endDate={value ? value[1] : null}
      dateFormat="dd/MM/yyyy"
      placeholderText="Click to select a duration"
      minDate={new Date()}
      withPortal
    />
    <S.InfoWrapper>
      {error ? <S.ErrorMessage uppercase>{error}</S.ErrorMessage> : null}
    </S.InfoWrapper>
  </S.Container>
);
