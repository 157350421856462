import styled from "styled-components";
import { Typography } from "../../../components";
import { device } from "../../../style";

export const InfoWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 13px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media ${device.laptopL} {
    gap: 30px;
  }
`;

export const InfoBox = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const BoostRow = styled(Typography.Description)`
  position: absolute;
  top: -6px;
  right: -9px;
  transform: translateX(100%);

  display: flex;
  gap: 4px;
`;

export const Boost = styled(Typography.Description)`
  display: flex;
  width: 70px;
  gap: 4px;
  color: ${({ theme }) => theme.colors.farm.boostText};
  background-color: ${({ theme }) => theme.colors.farm.boostBgc};
  padding: 4.5px 4px;

  img {
    width: 12px;
    height: 12px;
  }
`;
