import styled from "styled-components";
import { smallLogo } from "../../../assets";
import { config } from "../../../config";
import { device } from "../../../style";

type PoolIconProps = {
  poolIcon: Pool["icon"];
  isSmall?: boolean;
};

const PoolIconImg = styled.img<{ isSmall?: boolean }>`
  max-width: ${({ isSmall }) => (isSmall ? "171px" : "208px")};
  max-height: ${({ isSmall }) => (isSmall ? "23px" : "40px")};

  @media ${device.tablet} {
    max-width: ${({ isSmall }) => (isSmall ? "199px" : "208px")};
    max-height: ${({ isSmall }) => (isSmall ? "27px" : "40px")};
  }
`;

export const PoolIcon = ({ poolIcon, isSmall }: PoolIconProps) => (
  <PoolIconImg
    src={poolIcon ? `${config.API_URL}files/icons/${poolIcon}` : smallLogo}
    alt="farm icon"
  />
);
