import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Typography } from "../index";
import { Theme } from "../../@types/theme";

const handleBorderType = (
  theme: Theme,
  error: string | undefined,
  warning: string | undefined,
  defaultColor: string
) => {
  if (error) return theme.colors.error;
  if (warning) return theme.colors.warning;
  return defaultColor;
};

export const Input = styled.input<{ isReversed?: boolean }>``;

export const DatePickerWrapper = styled(DatePicker)<{
  error?: string;
  title?: string;
  warning?: string;
}>`
  height: 83px;
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white05};
  text-align: center;
  border: 1px solid
    ${({ theme, error, warning }) =>
      handleBorderType(theme, error, warning, theme.colors.greyDark)};
  outline: 0;
  background: transparent;
  &:hover {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.white033)};
  }
  &:active {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
  }

  &:focus-within {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
    outline: 3px solid
      ${({ theme, error, warning }) =>
        error || warning
          ? "none"
          : handleBorderType(theme, error, warning, theme.colors.primary)};
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Wrapper = styled.div<{
  error?: string;
  title?: string;
  warning?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${({ title }) => (title ? "83px" : "60px")};

  background-color: ${({ theme }) => theme.colors.greyDark01};
  border: 1px solid
    ${({ theme, error, warning }) =>
      handleBorderType(theme, error, warning, theme.colors.greyDark)};
  border-radius: 2px;
  gap: 10px;

  padding: 15px 20px;
  transition: 0.3s;

  &:hover {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.white033)};
  }
  &:active {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
  }

  &:focus-within {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
    outline: 3px solid
      ${({ theme, error, warning }) =>
        error || warning
          ? "none"
          : handleBorderType(theme, error, warning, theme.colors.primary)};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
  gap: 4px;
  height: 16px;
`;

export const ErrorMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.error};
`;

export const Title = styled.p`
  position: absolute;
  top: 15px;
  left: 20px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.description.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.description.letterSpacing};
  color: ${({ theme }) => theme.colors.grey};
  opacity: 0.6;
  text-transform: uppercase;
`;

export const SubTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.description.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.description.letterSpacing};
  color: ${({ theme }) => theme.colors.grey};
  opacity: 0.6;
  text-transform: uppercase;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 10;
`;
