import { closeButton } from "../../../../../assets";
import { CustomTooltip } from "../../../../../components";
import { useBoolean } from "../../../../../hooks";
import { useAppSelector } from "../../../../../redux";
import { isAdminRole } from "../../../../../utils";
import * as S from "./AdminRoleAlert.styled";

export const AdminRoleAlert = () => {
  const [isAlertHidden, hideAlert] = useBoolean(false);
  const { roles, contractRole } = useAppSelector(
    (store) => store.user.userData
  );

  if (!isAdminRole(roles)) {
    return null;
  }

  if (contractRole !== null || isAlertHidden) {
    return null;
  }

  return (
    <S.Alert>
      <span>Missing role on the contracts</span>
      <CustomTooltip
        message="Your wallet address doesn't have an admin role on the blockchain. You are unable to perform any administrative action in the contracts of the protocol."
        big
        placement="bottom"
      />
      <button onClick={hideAlert} type="button">
        <img src={closeButton} alt="close button" />
      </button>
    </S.Alert>
  );
};
