import { useAppSelector } from "../../redux";
import { Typography } from "../Typography/Typography";
import * as S from "./KycInstructions.styled";
import { tickIcon, pendingIcon } from "../../assets";

export const KycInstructions = () => {
  const kycStatus = useAppSelector((state) => state.user.userData.kyc?.status);

  const stepOneSuccess =
    kycStatus === "started" ||
    kycStatus === "approved" ||
    kycStatus === "pending" ||
    kycStatus === "rejected";
  const stepTwoSuccess =
    kycStatus === "pending" ||
    kycStatus === "approved" ||
    kycStatus === "rejected";
  const stepThreeSuccess = kycStatus === "approved" || kycStatus === "rejected";
  return (
    <S.Container>
      <S.InstructionBox success={stepOneSuccess}>
        <S.SuccessMark success={stepOneSuccess}>
          <img src={stepOneSuccess ? tickIcon : pendingIcon} alt="tick icon" />
        </S.SuccessMark>
        <Typography.Body style={{ textAlign: "center" }} fontWeight={700}>
          Init KYC in our app
        </Typography.Body>
        <Typography.BodySmall style={{ textAlign: "center" }} fontWeight={300}>
          Start the KYC verification process by clicking the &quot;Go to KYC
          verification&quot; button in Our application. You will be redirected
          to fractal.id
        </Typography.BodySmall>
      </S.InstructionBox>
      <S.InstructionBox success={stepTwoSuccess}>
        <S.SuccessMark success={stepTwoSuccess}>
          <img src={stepTwoSuccess ? tickIcon : pendingIcon} alt="tick icon" />
        </S.SuccessMark>
        <Typography.Body style={{ textAlign: "center" }} fontWeight={700}>
          Verify and back
        </Typography.Body>
        <Typography.BodySmall style={{ textAlign: "center" }} fontWeight={300}>
          Follow the instructions on the fractal.id website. After successfully
          completing the KYC process,{" "}
          <Typography.BodySmall as="span" fontWeight={700}>
            return to Our website by clicking the button on the fractal page or
            wait to be redirected automatically.
          </Typography.BodySmall>{" "}
          If you closed the fractal page while waiting for your data to be
          verified, click the &quot;Continue KYC verification&quot; button. If
          your document verification is already completed, and you have been
          verified by fractal, return to Us by clicking the button on the
          fractal page, or wait for automatic redirection to Our application.
        </Typography.BodySmall>
      </S.InstructionBox>
      <S.InstructionBox success={stepThreeSuccess}>
        <S.SuccessMark success={stepThreeSuccess}>
          <img
            src={stepThreeSuccess ? tickIcon : pendingIcon}
            alt="tick icon"
          />
        </S.SuccessMark>
        <Typography.Body style={{ textAlign: "center" }} fontWeight={700}>
          Information from KYC provider
        </Typography.Body>
        <Typography.BodySmall style={{ textAlign: "center" }} fontWeight={300}>
          We have received information from the KYC provider. Your current
          status is displayed in the main section.
        </Typography.BodySmall>
      </S.InstructionBox>
    </S.Container>
  );
};
