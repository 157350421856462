import styled from "styled-components";
import { Typography } from "../../../components";
import { device } from "../../../style";

export const TitleWrapper = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;

  @media ${device.tablet} {
    margin-bottom: 30.76px;
  }
`;

export const IconWrapper = styled.div`
  /* max-width: 100%; */
  max-height: 100px;
  display: flex;

  img {
    /* max-width: 100%; */
    /* max-height: 100%; */
    /* margin: auto; */
    max-height: 40px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  margin-left: 15px;
  max-width: 140px;

  @media ${device.mobileM} {
    max-width: 100%;
  }

  @media ${device.tablet} {
    margin-top: 4.5px;
    margin-bottom: 4.5px;
  }
`;

export const InfoRecently = styled(Typography.Description)`
  color: ${({ theme }) => theme.colors.farm.recentlyText};
  background-color: ${({ theme }) => theme.colors.farm.recentlyBgc};
  padding: 7px 24px 7px 8px;
  align-self: flex-start;
  border-bottom-right-radius: 30px;
  position: absolute;
  top: -35px;
  left: -30px;
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  gap: 11.33px;
`;
