import styled from "styled-components";
import { device } from "../../style";

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: static;

  @media ${device.tablet} {
    position: relative;
  }
`;

export type DotsBtnProps = {
  isDropdownOpen: boolean;
};

export const DotsBtn = styled.button<DotsBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 8px 14px 8px 14px;
  background: ${({ theme, isDropdownOpen }) =>
    isDropdownOpen
      ? theme.colors.button.secondary.clicked
      : theme.colors.dropdown.btnBackground};
  border: 1px solid ${({ theme }) => theme.colors.button.secondary.border};
  z-index: ${({ theme }) => theme.zIndex.dropdown.dots};

  :hover {
    background: ${({ theme }) =>
      `${theme.colors.button.secondary.hover}, ${theme.colors.button.secondary.border}`};
  }

  :active {
    background: ${({ theme }) =>
      `${theme.colors.button.secondary.clicked}, ${theme.colors.button.secondary.border}`};
  }

  :focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.button.secondary.border};
  }
`;
