import { Connector, useConnect, useSignMessage } from "wagmi";
import { useEffect } from "react";
import { Button } from "../Button/Button.styled";
import {
  metamaskIcon,
  walletconnectIcon,
  rabbyWalletIcon,
  walletDisconnectedLightIcon,
  safeIcon,
} from "../../assets";
import * as S from "./ConnectWallet.styled";

export type ConnectWalletProps = {
  handleModalClose?: () => void;
  callback?: () => void;
};

export const ConnectWallet = ({
  handleModalClose,
  callback,
}: ConnectWalletProps) => {
  const { connectAsync, connectors, pendingConnector } = useConnect();
  const { isSuccess, isLoading } = useSignMessage();

  const handleConnect = async (connector: Connector) => {
    await connectAsync({ connector });
  };

  useEffect(() => {
    if (isSuccess && !isLoading && handleModalClose) {
      handleModalClose();
    }
    if (isSuccess && callback) {
      callback();
    }
    if (pendingConnector?.name === "WalletConnect" && handleModalClose) {
      handleModalClose();
    }
  }, [isSuccess, pendingConnector]);

  const switchImage = (connector: string) => {
    switch (connector) {
      case "MetaMask":
        return metamaskIcon;
      case "WalletConnect":
        return walletconnectIcon;
      case "Rabby Wallet":
        return rabbyWalletIcon;
      case "Safe":
        return safeIcon;
      default:
        return walletDisconnectedLightIcon;
    }
  };

  const switchAlt = (connector: string) => {
    switch (connector) {
      case "MetaMask":
        return "metamask icon";
      case "WalletConnect":
        return "walletConnect icon";
      case "Rabby Wallet":
        return "rabby wallet icon";
      default:
        return "icon";
    }
  };

  return (
    <S.ConnectWalletWrapper>
      {connectors.map((connector) =>
        connector.id === "injected" && connector.name === "MetaMask" ? null : (
          <Button.Secondary
            bigSize
            disabled={!connector.ready}
            key={connector.id}
            onClick={() => handleConnect(connector)}
          >
            <S.ButtonContent>
              <img
                width={30}
                height={30}
                src={switchImage(connector.name)}
                alt={switchAlt(connector.name)}
              />
              <span>{connector.name}</span>
            </S.ButtonContent>
          </Button.Secondary>
        )
      )}
    </S.ConnectWalletWrapper>
  );
};
