import React from "react";
import { motion } from "framer-motion";
import * as S from "./Dropdown.styled";

export interface DropdownProps {
  children: React.ReactNode;
  innerRef: React.Ref<HTMLDivElement>;
  targetIcon: string;
}

const Dropdown = ({ children, innerRef, targetIcon }: DropdownProps) => (
  <S.Dropdown
    id="dropdown"
    key="dropdown"
    as={motion.div}
    initial={{ opacity: 0, x: "100%" }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: "100%" }}
    transition={{ duration: 0.2 }}
    targetIcon={targetIcon}
    ref={innerRef}
  >
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      {/* <S.DropdownMark
          style={{ ...generateMarkPosition() }}
          src={triangleIcon}
          alt="triangle mark icon"
        /> */}
      {children}
    </div>
  </S.Dropdown>
);

export default Dropdown;
