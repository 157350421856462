import { CustomTooltip, LoadingSkeleton, MBC } from "../../../../../components";
import { config } from "../../../../../config";
import { useAppSelector } from "../../../../../redux";
import { bpsToPercent, formatBigNumber } from "../../../../../utils";
import { useBoostInfo } from "../../../useBoostInfo";
import * as S from "./BoostInfo.styled";

type BoostInfoProps = {
  poolLevel: PoolLevel;
};

export const BoostInfo = ({ poolLevel }: BoostInfoProps) => {
  const myStaked = useAppSelector((store) => store.staking.myStaked);
  const { boostLimit, poolDepositInSoil } = useBoostInfo(poolLevel);

  const depositAmount = poolLevel.deposit || "0";

  return (
    <S.Wrapper>
      <MBC.Text>
        To receive an additional boost to the prizes in the SOIL token, you must
        stake a SOIL token on the staking contract worth{" "}
        {bpsToPercent(poolLevel.boostPercentage || 0)}% of your deposit in this
        pool.
      </MBC.Text>
      <MBC.InfoRow>
        Deposit value
        <span>
          {poolDepositInSoil ? (
            `${formatBigNumber(
              depositAmount,
              config.USDC_TOKEN_DECIMALS,
              6
            )} USDC (${formatBigNumber(
              poolDepositInSoil.toString(),
              config.SOIL_TOKEN_DECIMALS,
              6
            )} SOIL)`
          ) : (
            <LoadingSkeleton width="80px" />
          )}
          <CustomTooltip message="Your current balance of USDC tokens in this pool and an amount in the SOIL token." />
        </span>
      </MBC.InfoRow>
      <MBC.InfoRow>
        Required to boost
        <span>
          {boostLimit ? (
            `${formatBigNumber(
              boostLimit.toString(),
              config.SOIL_TOKEN_DECIMALS,
              6
            )} SOIL`
          ) : (
            <LoadingSkeleton width="80px" />
          )}
          <CustomTooltip message="The required balance of staked SOIL tokens on the staking contract to receive a booster." />
        </span>
      </MBC.InfoRow>
      <MBC.InfoRow>
        Currently staked
        <span>
          {myStaked ? (
            `${formatBigNumber(myStaked, config.SOIL_TOKEN_DECIMALS, 6)} SOIL`
          ) : (
            <LoadingSkeleton width="80px" />
          )}
          <CustomTooltip message="Your current balance of the SOIL tokens staked on the staking contract." />
        </span>
      </MBC.InfoRow>
    </S.Wrapper>
  );
};
