import styled from "styled-components";
import { useMediaQuery } from "../../../hooks";
import { device } from "../../../style";

type PoolDetailsProps = {
  poolLevel: PoolLevel;
  isDetailsView?: boolean;
};

const PoolDetailsContainer = styled.div<{ isDetailsView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 7px;

  @media ${device.laptop} {
    max-width: ${({ isDetailsView }) =>
      isDetailsView ? "unset" : "calc(100% - 250px)"};
  }

  @media ${device.laptopM} {
    max-width: unset;
  }
`;

const BoldTitleText = styled.p<{ isDetailsView?: boolean }>`
  color: #fff;

  font-family: Ingra;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.5%; /* 16.83px */
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 18.458px;
  }

  @media ${device.laptop} {
    font-size: ${({ isDetailsView }) => (isDetailsView ? "18px" : "18.458px")};
  }
`;

const ThinTitleText = styled.p<{ isDetailsView?: boolean }>`
  color: #fff;
  font-family: Ingra;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 93.5%;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 18.458px;
  }

  @media ${device.laptop} {
    font-size: ${({ isDetailsView }) => (isDetailsView ? "18px" : "18.458px")};
  }
`;

const GraySeparator = styled.p<{ isDetailsView?: boolean }>`
  color: rgba(255, 255, 255, 0.6);
  font-family: Ingra;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 93.5%;
  text-transform: uppercase;
  margin-left: 2px;
  margin-right: 2px;
`;

const SubtitleThin = styled.p<{ isDetailsView?: boolean }>`
  color: #afbdd7;

  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 14.356px;
  }

  @media ${device.laptop} {
    font-size: ${({ isDetailsView }) => (isDetailsView ? "12px" : "14.356px")};
  }
`;

const SubtitleBold = styled.p<{ isDetailsView?: boolean }>`
  color: #fff;
  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 14.356px;
  }

  @media ${device.laptop} {
    white-space: nowrap;
    font-size: ${({ isDetailsView }) => (isDetailsView ? "12px" : "14.356px")};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  z-index: 2;

  @media ${device.laptop} {
    flex-wrap: wrap;
  }
`;

const SubtitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
`;

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;

  @media ${device.laptop} {
    flex-wrap: wrap;
  }
`;

const Separator = styled.div`
  color: #fff;
  font-family: Ingra;
  font-size: 14.356px;
  font-style: normal;
  font-weight: 400;
  line-height: 93.5%;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 14.356px;
  }
`;

const HorizontalLine = styled.div<{ isDelailsView?: boolean }>`
  display: none;

  @media ${device.tablet} {
    display: block;
    height: 1.025px;
    width: 100%;
    opacity: 0.2;
    background: #afbdd7;
    margin-top: 8.84px;
    margin-bottom: 23.5px;
  }

  @media ${device.laptop} {
    margin-bottom: 5.5px;
  }

  @media ${device.laptopM} {
    margin-bottom: 33px;
    margin-top: 24px;
  }
`;

export const PoolDetails = ({ poolLevel, isDetailsView }: PoolDetailsProps) => {
  const { name, pool, subtitle, description } = poolLevel;
  const { name: poolName } = pool;
  const isTabletView = useMediaQuery(device.tablet);

  return (
    <PoolDetailsContainer isDetailsView={isDetailsView}>
      <TitleWrapper>
        <BoldTitleText isDetailsView={isDetailsView}>SOIL</BoldTitleText>
        <ThinTitleText isDetailsView={isDetailsView}>{name}</ThinTitleText>
        <GraySeparator>|</GraySeparator>
        <BoldTitleText isDetailsView={isDetailsView}>{poolName}</BoldTitleText>
      </TitleWrapper>
      <SubtitlesWrapper>
        <SubtitleWrapper>
          <SubtitleThin isDetailsView={isDetailsView}>POOL:</SubtitleThin>
          <SubtitleBold isDetailsView={isDetailsView}>
            {description}
          </SubtitleBold>
        </SubtitleWrapper>
        {isTabletView ? <Separator>|</Separator> : null}
        <SubtitleWrapper>
          <SubtitleThin isDetailsView={isDetailsView}>PARTNER: </SubtitleThin>
          <SubtitleBold isDetailsView={isDetailsView}>{subtitle}</SubtitleBold>
        </SubtitleWrapper>
      </SubtitlesWrapper>
      <HorizontalLine />
    </PoolDetailsContainer>
  );
};
