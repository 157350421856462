import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Typography } from "../../../components";
import { selectArrow } from "../../../assets";
import { device } from "../../../style";

export const SelectRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
  @media ${device.tablet} {
    gap: 12px;
    flex-direction: row;
    //justify-content: space-between;
    align-items: flex-start;
  }
`;

export const SendButton = styled(Button.Secondary)`
  width: 100px;
  height: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FilterSelect = styled.select<{ paddingLeft: string }>`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px 8px;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "0px")};
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.bodySmall.lineHeight};
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.text.primary};
  appearance: none;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.5em;
  cursor: pointer;
  option {
    color: black;
    background: transparent;
    display: flex;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const selectTitle = styled(Typography.BodySmall)`
  position: absolute;
  top: 9px;
  left: 12px;
  height: 15px;
  color: ${({ theme }) => theme.colors.text.grey};
`;

export const selectWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  @media ${device.tablet} {
    width: 33%;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

export const ListItem = styled.li`
  background-color: rgba(255, 255, 255, 0.02);
  padding: 8px 28px 8px 6px;
  border-radius: 8px;
  p {
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  height: 52px;
  min-width: 216px;
`;

export const DatePickerButton = styled(DatePicker)<{ error?: string }>`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 28px 8px 6px;
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.text.primary};
  appearance: none;
  text-decoration: none;
  border: none;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.error : theme.colors.greyDark};
`;

export const ErrorMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.error};
  text-transform: uppercase;
`;
