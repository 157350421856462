import { useEffect } from "react";
import { useAccount, useSwitchNetwork } from "wagmi";
import { getNetwork } from "@wagmi/core";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery, useModal } from "../../../../../../hooks";
import { Modal, Button, Typography } from "../../../../../../components";
import { config } from "../../../../../../config";
import {
  metamaskIcon,
  warningIcon,
  cursor,
  walletconnectIcon,
} from "../../../../../../assets";
import { toast } from "../../../../../../toasts";
import * as S from "./SwitchNetwork.styled";

const SwitchNetwork = () => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const { switchNetwork, isLoading, isSuccess, isError } = useSwitchNetwork();
  const { chain } = getNetwork();
  const { isConnected, address, connector } = useAccount();

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        "Successful switch",
        "The network has been successfully switched"
      );
    }
    if (isError) {
      toast.error(
        "Switch network unsuccessful",
        "Network switch to Polygon Mainnet failed"
      );
    }
  }, [isError, isSuccess]);

  const isMobileView = useMediaQuery("(max-width: 424px)");

  useEffect(() => {
    if (
      isConnected &&
      Number(config.NETWORK_ID) !== chain?.id &&
      !isMobileView
    ) {
      handleModalOpen();
    } else if (isConnected && Number(config.NETWORK_ID) === chain?.id) {
      handleModalClose();
    }
  }, [address, chain?.id]);

  return (
    <Modal
      title="Invalid network detected"
      onClose={handleModalClose}
      isOpen={isModalOpen}
      withoutCloseIcon
    >
      <S.WarningWrapper>
        <img width={40} src={warningIcon} alt="warning icon" />
        <Typography.Body>
          For better interaction with our app, we require the wallet to be
          connected to the Polygon network.
        </Typography.Body>
      </S.WarningWrapper>
      <AnimatePresence>
        {isLoading && (
          <>
            <Typography.Body style={{ paddingBottom: "30px" }}>
              {connector?.name === "MetaMask"
                ? "Check the metamask extension in Your browser..."
                : "Check your wallet app, that You use with the walletConnect..."}
            </Typography.Body>
            <S.ClickAnimationWrapper>
              <motion.img
                width={70}
                height={70}
                src={
                  connector?.name === "MetaMask"
                    ? metamaskIcon
                    : walletconnectIcon
                }
                alt="metamask icon"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                transition={{ repeat: Infinity, duration: 0.2, repeatDelay: 3 }}
              />
              <motion.img
                src={cursor}
                alt="cursor"
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7500%) hue-rotate(229deg) brightness(103%) contrast(105%)",
                }}
                width={20}
                height={20}
                initial={{ x: "-80px" }}
                animate={{ x: 0 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "mirror",
                  duration: 1.6,
                }}
              />
            </S.ClickAnimationWrapper>
          </>
        )}
      </AnimatePresence>
      <Button.Secondary
        disabled={isLoading}
        bigSize
        onClick={() => switchNetwork?.(config.NETWORK_ID)}
      >
        Switch to Polygon network
      </Button.Secondary>
    </Modal>
  );
};

export default SwitchNetwork;
