import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { fetchVestingInfo } from "../../services/vesting.service";

interface vestingState {
  isLoading: boolean;
  isRefreshPending: boolean;
  vestingInfo?: {
    lockedTokens: string;
    totalTokens: string;
    unlockedTokens: string;
    nextRelease: number;
    nextReleaseAmount: string;
    endDate: Date;
  };
}

const initialVesting: vestingState = {
  isLoading: false,
  isRefreshPending: false,
};

export const getVestingInfo = createAsyncThunk(
  "vesting/getVestingInfo",
  async (walletAddress: string) => {
    const {
      lockedTokens,
      nextRelease,
      totalTokens,
      unlockedTokens,
      nextReleaseAmount,
      endDate,
    } = await fetchVestingInfo(walletAddress);
    return {
      lockedTokens: ethers.utils.formatEther(lockedTokens),
      totalTokens: ethers.utils.formatEther(totalTokens),
      unlockedTokens: ethers.utils.formatEther(unlockedTokens),
      nextRelease: nextRelease * 1000,
      nextReleaseAmount: ethers.utils.formatEther(nextReleaseAmount),
      endDate: new Date(endDate.toNumber() * 1000),
    };
  }
);

export const refreshVestingInfo = createAsyncThunk(
  "vesting/refreshVestingInfo",
  async (walletAddress: string) => {
    const {
      lockedTokens,
      nextRelease,
      totalTokens,
      unlockedTokens,
      nextReleaseAmount,
      endDate,
    } = await fetchVestingInfo(walletAddress);
    return {
      lockedTokens: ethers.utils.formatEther(lockedTokens),
      totalTokens: ethers.utils.formatEther(totalTokens),
      unlockedTokens: ethers.utils.formatEther(unlockedTokens),
      nextRelease: nextRelease * 1000,
      nextReleaseAmount: ethers.utils.formatEther(nextReleaseAmount),
      endDate: new Date(endDate.toNumber() * 1000),
    };
  }
);

export const vestingSlice = createSlice({
  name: "vesting",
  initialState: initialVesting,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVestingInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVestingInfo.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getVestingInfo.fulfilled, (state, { payload }) => {
      state.vestingInfo = payload;
      state.isLoading = false;
    });
    builder.addCase(refreshVestingInfo.pending, (state) => {
      state.isRefreshPending = true;
    });
    builder.addCase(refreshVestingInfo.fulfilled, (state, { payload }) => {
      state.vestingInfo = payload;
      state.isRefreshPending = false;
    });
    builder.addCase(refreshVestingInfo.rejected, (state) => {
      state.isRefreshPending = false;
    });
  },
});

export default vestingSlice.reducer;
