import { useEffect } from "react";
import { useAccount, useSwitchNetwork } from "wagmi";
import { getNetwork } from "@wagmi/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, useMediaQuery } from "../../../../hooks";
import * as S from "./Header.styled";
import ToolBar from "../ToolBar/ToolBar";
import { device } from "../../../../style";
import { backButton } from "../../../../assets";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { AdminRoleAlert } from "./AdminRoleAlert";
import { getBalances } from "../../../../redux/features/walletSlice";
import { config } from "../../../../config";
import { Logo } from "../Logo/Logo";

export const Header = () => {
  const { isConnected, address, isReconnecting, connector } = useAccount();
  const { login } = useAuth();
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );
  const { isLogged, tfaAuthenticated, tfaEnabled } = useAppSelector(
    (state) => state.user
  );
  const isDesktopView = useMediaQuery(device.laptop);
  const { pathname } = useLocation();
  const { chain } = getNetwork();
  const { isSuccess } = useSwitchNetwork();
  const isProjectDetailPathname = pathname.includes("/project-details");
  // const pathToDisplay = isProjectDetailPathname
  //   ? "earn"
  //   : pathname.slice(1, pathname.length).replace("-", " ");

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const { loading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (
      (isConnected &&
        address &&
        address?.toLowerCase() !== walletAddress.toLowerCase() &&
        ((!tfaEnabled && !tfaAuthenticated) ||
          (tfaEnabled && tfaAuthenticated && !loading)) &&
        walletAddress &&
        isLogged &&
        !loading) ||
      (isConnected &&
        address &&
        !isLogged &&
        !isReconnecting &&
        chain?.id === Number(config.NETWORK_ID) &&
        !loading)
    ) {
      login(address);
    }
  }, [
    isConnected,
    address,
    walletAddress,
    connector,
    isLogged,
    chain?.id,
    isSuccess,
    loading,
  ]);

  useEffect(() => {
    if (address) {
      dispatch(getBalances(address));
    }
  }, [isConnected, address]);

  return (
    <S.Wrapper>
      {/* {!isDesktopView ? (
        <S.MobileWrapper>
          <S.LogoWrapper>
            <img src={smallLogo} alt="logo soil" />
          </S.LogoWrapper>
          <Typography.HeaderSmall>
            {capitalizeFirstLetter(pathToDisplay)}
          </Typography.HeaderSmall>
          <AdminRoleAlert />
          {pathname === routes.userPanel.wallet && isConnected ? (
            <WalletStatus isConnected={isConnected} />
          ) : null}
        </S.MobileWrapper>
      ) 
      :
       ( */}
      {!isDesktopView ? <Logo /> : null}
      <S.DesktopWrapper isProjectDetailPathname={isProjectDetailPathname}>
        {isProjectDetailPathname ? (
          <S.BackWrapper onClick={handleBack}>
            <img src={backButton} alt="back" />
          </S.BackWrapper>
        ) : null}
        <AdminRoleAlert />
        <ToolBar />
      </S.DesktopWrapper>
      {/* )} */}
    </S.Wrapper>
  );
};
