/* eslint-disable */
import { ReactDatePickerProps } from "react-datepicker";
import * as S from "./DurationPicker.styled";
import { CustomTooltip } from "../Tooltip/CustomTooltip";

type DurationPickerProps = {
  error?: string;
  title?: string;
  tooltipMessage?: string;
  onChange: any;
} & Omit<ReactDatePickerProps, "onChange">;

export const CustomDatePicker = ({
  title,
  error,
  onChange,
  tooltipMessage,
  ...rest
}: DurationPickerProps) => (
  <S.Container>
    <S.Box>
      {title && <S.SubTitle>{title}</S.SubTitle>}
      {tooltipMessage && <CustomTooltip message={tooltipMessage} />}
    </S.Box>
    <S.DatePickerWrapper error={error} onChange={onChange} {...rest} />
    <S.InfoWrapper>
      {error ? <S.ErrorMessage uppercase>{error}</S.ErrorMessage> : null}
    </S.InfoWrapper>
  </S.Container>
);
