import { useEffect } from "react";

type useDetectIframeClickHookType = (
  action: () => void,
  condition?: boolean
) => void;

export const useDetectIframeClick: useDetectIframeClickHookType = (
  action,
  condition = true
) => {
  useEffect(() => {
    window.focus();

    const listener = () => {
      setTimeout(() => {
        if (
          document.activeElement &&
          document.activeElement.tagName === "IFRAME" &&
          condition
        ) {
          action();
        }
      });
    };

    window.addEventListener("blur", listener);

    return () => window.removeEventListener("blur", listener);
  }, [condition]);
};
