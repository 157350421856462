import { BigNumber } from "ethers";
import Skeleton from "react-loading-skeleton";
import { CustomTooltip, MBC } from "../../../components";
import { config } from "../../../config";
import { useAppSelector } from "../../../redux";
import { bpsToPercent, formatBigNumber } from "../../../utils";

type StakeDetailsProps = {
  amountToStake: BigNumber;
};

export const StakeDetails = ({ amountToStake }: StakeDetailsProps) => {
  const { feeUSDC, feeSOIL, apr, isLoading } = useAppSelector(
    (store) => store.staking
  );

  return (
    <MBC.InfoBox>
      <MBC.InfoRow as="li">
        APR
        <span>
          {isLoading ? (
            <Skeleton
              baseColor="#FFFFFF1F"
              highlightColor="#f5f5f5"
              width="60px"
              style={{ zIndex: "0" }}
            />
          ) : (
            <span>{bpsToPercent(apr)}%</span>
          )}
          <CustomTooltip message="APR, or annual percentage rate, whether in traditional banking or the crypto world, refers to the ordinary interest applied to the principal amount of an investment" />
        </span>
      </MBC.InfoRow>
      <MBC.InfoRow as="li">
        Transaction fee
        <span>
          {formatBigNumber(feeSOIL, config.SOIL_TOKEN_DECIMALS, 2)} $SOIL (
          {formatBigNumber(feeUSDC, config.USDC_TOKEN_DECIMALS, 2)} USDC)
          <CustomTooltip message="An amount of $SOIL cryptocurrency charged from the transaction as a protocol fee" />
        </span>
      </MBC.InfoRow>
      <MBC.InfoRow as="li">
        Grew amount
        <span>
          {Number(amountToStake) > 0
            ? formatBigNumber(amountToStake, config.SOIL_TOKEN_DECIMALS, 6)
            : "0.00"}
          <CustomTooltip message="The declared amount of $SOIL cryptocurrency minus the fee. This amount will be credited to your staking balance after the transaction." />
        </span>
      </MBC.InfoRow>
    </MBC.InfoBox>
  );
};
