import { boostIcon } from "../../../../assets";
import { CustomTooltip } from "../../../../components";
import { useBoostInfo } from "../../useBoostInfo";
import * as S from "./BoostInfo.styled";

type BoostInfoProps = {
  poolLevel: PoolLevel;
};

export const BoostInfo = ({ poolLevel }: BoostInfoProps) => {
  const { areRewardsBoosted } = useBoostInfo(poolLevel);

  if (!areRewardsBoosted) {
    return null;
  }

  return (
    <CustomTooltip message="Your staking amount qualified you for an additional SOIL token reward in this pool">
      <S.Boost>
        Boosted <img src={boostIcon} alt="boost" />
      </S.Boost>
    </CustomTooltip>
  );
};
