import React from "react";
import * as S from "./Spinner.styled";
import { smallLogo } from "../../assets";

export const Spinner = () => (
  <S.SpinnerContainer>
    <S.Spinner />
    <S.SmallLogo src={smallLogo} alt="small soil logo" />
  </S.SpinnerContainer>
);
