import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { AnimatePresence, motion } from "framer-motion";
import * as S from "../../ToolBar.styled";
import NotificationsIcon from "../../../Icons/NotificationsIcon";
import WalletTool from "../WalletTool/WalletTool";
import UserTool from "../UserTool/UserTool";
import { SmallSpinner } from "../../../../../../components";
import { useAppSelector } from "../../../../../../redux";
import { ConnectWalletButton } from "../../../../../../features";
import HamburgerIcon from "../../../Icons/HamburgerIcon";
import {
  MobileNavBack,
  MobileNavWrapper,
} from "../../../MobileNav/MobileNav.styled";
import { Nav } from "../../../Nav/Nav";
import { useMediaQuery } from "../../../../../../hooks";
import { device } from "../../../../../../style";

const UserToolBar = ({
  isNewNotification,
  handleNotificationClick,
}: S.ToolBarProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const isDesktopView = useMediaQuery(device.laptop);

  const { userData } = useAppSelector((state) => state.user);
  const authLoading = useAppSelector((state) => state.user.loading);
  const { isConnected } = useAccount();

  const [isWalletDropdownVisible, setIsWalletDropdownVisible] = useState(false);
  const [isUserDropdownVisible, setIsUserDropdownVisible] = useState(false);

  const toggleWalletDropdown = () => {
    setIsUserDropdownVisible(false);
    setIsWalletDropdownVisible(!isWalletDropdownVisible);
  };

  const toggleUserDropdown = () => {
    setIsWalletDropdownVisible(false);
    setIsUserDropdownVisible(!isUserDropdownVisible);
  };

  const handleClickHamburger = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleClickMenuElement = () => {
    setIsMenuVisible(false);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? "hidden" : "auto";
  }, [isMenuVisible]);

  return (
    <>
      {isConnected ? (
        <WalletTool
          toggleVisible={toggleWalletDropdown}
          isOpen={isWalletDropdownVisible}
          isLogged={isLogged}
        />
      ) : (
        <ConnectWalletButton small />
      )}
      {authLoading && !userData ? (
        <SmallSpinner />
      ) : isLogged ? (
        <>
          <NotificationsIcon
            isNewNotification={isNewNotification}
            isToolbar
            click={handleNotificationClick}
          />
          <UserTool
            toggleVisible={toggleUserDropdown}
            isOpen={isUserDropdownVisible}
          />
          {!isDesktopView ? (
            <MobileNavWrapper isConnected={isConnected}>
              <HamburgerIcon
                click={handleClickHamburger}
                isOpen={isMenuVisible}
              />
              <AnimatePresence>
                {isMenuVisible && (
                  <MobileNavBack
                    onClick={() => setIsMenuVisible(false)}
                    as={motion.div}
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <motion.div
                      key="nav"
                      style={{ height: "100%" }}
                      animate={{ x: 0 }}
                      initial={{ x: "100%" }}
                      exit={{ x: "100%" }}
                      transition={{ duration: 0.2 }}
                    >
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div onClick={handleMenuClick}>
                        <Nav toggleMobileNav={handleClickMenuElement} />
                      </div>
                    </motion.div>
                  </MobileNavBack>
                )}
              </AnimatePresence>
            </MobileNavWrapper>
          ) : null}
        </>
      ) : !isDesktopView ? (
        <MobileNavWrapper>
          <HamburgerIcon click={handleClickHamburger} isOpen={isMenuVisible} />
          <AnimatePresence>
            {isMenuVisible && (
              <MobileNavBack
                onClick={() => setIsMenuVisible(false)}
                as={motion.div}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <motion.div
                  key="nav"
                  style={{ height: "100%" }}
                  animate={{ x: 0 }}
                  initial={{ x: "100%" }}
                  exit={{ x: "100%" }}
                  transition={{ duration: 0.2 }}
                >
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div onClick={handleMenuClick}>
                    <Nav toggleMobileNav={handleClickMenuElement} />
                  </div>
                </motion.div>
              </MobileNavBack>
            )}
          </AnimatePresence>
        </MobileNavWrapper>
      ) : null}
    </>
  );
};

export default UserToolBar;
