import { CustomTooltip, MBC } from "../../../../../components";
import { bpsToPercent, humanizePoolDuration } from "../../../../../utils";
import { PoolIcon } from "../../../PoolIcon";

type DepositPoolInfoProps = {
  poolLevel: PoolLevel;
};

export const DepositPoolInfo = ({ poolLevel }: DepositPoolInfoProps) => (
  <>
    <MBC.LogoWrapper>
      <PoolIcon poolIcon={poolLevel.pool.icon} />
    </MBC.LogoWrapper>
    <MBC.HeaderWithLogoWrapper>
      <MBC.InfoBox>
        <MBC.InfoRow as="li">
          Loan category
          <span>{poolLevel.name}</span>
        </MBC.InfoRow>
        <MBC.InfoRow as="li">
          Fixed APR in USDC
          <span>
            {bpsToPercent(poolLevel.usdcApr)}%
            <CustomTooltip message="Refers to the ordinary interest applied to the principal amount of an investment" />
          </span>
        </MBC.InfoRow>
        <MBC.InfoRow as="li">
          Variable APR in $SOIL
          <span>
            {bpsToPercent(poolLevel.apr)}%
            <CustomTooltip message="Refers to the interest applied to the principal amount of investment. It can be changed according to the number of investors or the total amount of investments" />
          </span>
        </MBC.InfoRow>
        <MBC.InfoRow as="li">
          Loan duration (lockup period)
          <span>
            {humanizePoolDuration(poolLevel.cooldown)}
            <CustomTooltip message="The period on which invested funds will be locked on the contract" />
          </span>
        </MBC.InfoRow>
      </MBC.InfoBox>
    </MBC.HeaderWithLogoWrapper>
  </>
);
