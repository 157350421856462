import { useEffect, useState } from "react";
import { CurrencyValue, InfoBox, Modal, PenIcon } from "../../components";
import * as S from "./PoolFees.styled";
import { toast } from "../../toasts";
import { poolsContractService } from "../../services";
import { config } from "../../config";
import { getErrorMessage, formatBigNumber } from "../../utils";
import { useModal } from "../../hooks";
import { EditPoolFees } from "./EditPoolFees";

export const PoolFees = () => {
  const [poolFees, setPoolsFees] = useState<string>();
  const { isModalOpen, handleModalClose, handleModalOpen } = useModal();

  const getPoolFees = async () => {
    try {
      const poolContractFees = await poolsContractService.getPoolContractFees();
      setPoolsFees(
        formatBigNumber(poolContractFees, config.USDC_TOKEN_DECIMALS)
      );
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
    }
  };

  useEffect(() => {
    getPoolFees();
  }, []);

  return (
    <>
      <Modal
        title="Fee on the pools contract"
        isOpen={isModalOpen}
        onClose={handleModalClose}
      >
        <EditPoolFees
          closeModal={handleModalClose}
          value={poolFees || "0"}
          update={getPoolFees}
        />
      </Modal>
      <InfoBox.Admin title="Fee on the pools contract">
        <S.Row>
          <CurrencyValue
            value={poolFees || null}
            currency="USDC"
            decimals={2}
            isBig
          />
          <PenIcon noWrap click={handleModalOpen} />
        </S.Row>
      </InfoBox.Admin>
    </>
  );
};
