import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";

interface ITokenState {
  isError: boolean;
  price: string | null;
}

const initialState: ITokenState = {
  isError: false,
  price: null,
};

const getTokenPrice = createAsyncThunk("token/getPrice", async () => {
  const {
    data: { rate },
  } = await api.rate.getSoilToUsdcRate();

  return rate;
});

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getTokenPrice.pending, (state) => {
      state.isError = false;
    });
    builder.addCase(getTokenPrice.fulfilled, (state, { payload }) => {
      state.price = payload;
    });
    builder.addCase(getTokenPrice.rejected, (state) => {
      state.price = null;
      state.isError = true;
    });
  },
});

export const token = {
  getTokenPrice,
};
