/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";

export const getErrorMessage = (
  error: any,
  defaultError = "Something went wrong."
): string => {
  // IF ERROR FROM API
  if (
    error instanceof AxiosError &&
    typeof error?.response?.data?.message === "string"
  ) {
    return error.response.data.message;
  }

  if (
    error instanceof AxiosError &&
    error?.response?.data?.message &&
    Array.isArray(error?.response?.data?.message) &&
    error?.response?.data?.message.length > 0
  ) {
    return error?.response.data.message[0];
  }

  if (error?.data?.message) {
    return error.data.message;
  }

  if (error?.reason) {
    return error.reason;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "string") {
    return error;
  }

  return defaultError;
};
