const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};

export const config = {
  NODE_ENV: envVariableValidator(process.env.REACT_APP_NODE_ENV, "NODE_ENV"),
  API_URL: envVariableValidator(process.env.REACT_APP_API_URL, "API_URL"),

  JSON_RPC_URL: envVariableValidator(
    process.env.REACT_APP_JSON_RPC_URL,
    "JSON_RPC_URL"
  ),
  NETWORK_ID: Number(
    envVariableValidator(process.env.REACT_APP_NETWORK_ID, "NETWORK_ID")
  ),
  PROJECT_TOKEN: "$SOIL",
  STABLE_TOKEN: "USDC",
  ALCHEMY_ID: envVariableValidator(
    process.env.REACT_APP_ALCHEMY_ID,
    "ALCHEMY_ID"
  ),
  USDC_TOKEN_ADDRESS: envVariableValidator(
    process.env.REACT_APP_USDC_TOKEN_ADDRESS,
    "USDC_TOKEN_ADDRESS"
  ),
  SOIL_TOKEN_ADDRESS: envVariableValidator(
    process.env.REACT_APP_SOIL_TOKEN_ADDRESS,
    "SOIL_TOKEN_ADDRESS"
  ),
  VESTING_ADDRESS: envVariableValidator(
    process.env.REACT_APP_VESTING_ADDRESS,
    "VESTING_ADDRESS"
  ),
  POOLS_CONTRACT_ADDRESS: envVariableValidator(
    process.env.REACT_APP_POOLS_CONTRACT_ADDRESS,
    "POOLS_CONTRACT_ADDRESS"
  ),
  PROTOCOL_SETTINGS_CONTRACT: envVariableValidator(
    process.env.REACT_APP_PROTOCOL_SETTINGS_CONTRACT,
    "PROTOCOL_SETTINGS_CONTRACT"
  ),
  USDC_SOIL_POOL_ADDRESS: envVariableValidator(
    process.env.REACT_APP_USDC_SOIL_POOL_ADDRESS,
    "USDC_SOIL_POOL_ADDRESS"
  ),
  USDC_TOKEN_DECIMALS: 6,
  SOIL_TOKEN_DECIMALS: 18,
  STAKING_ADDRESS: envVariableValidator(
    process.env.REACT_APP_STAKING_ADDRESS,
    "STAKING_ADDRESS"
  ),
  UNISWAP_SWAP_ROUTER_ADDRESS: "0xE592427A0AEce92De3Edee1F18E0157C05861564", // The address is the same for mainnet and testnet, so there is no needed to pass via the env
  UNISWAP_QUOTER_ADDRESS: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6", // The address is the same for mainnet and testnet, so there is no needed to pass via the env
  SWAP_CONFIG: {
    POOL_REFRESH_INTERVAL: 30000, // The time in milliseconds describing how often data on the SOIL-USDC pool is refreshed on the swap page
    SWAP_DEADLINE: 6000, // 10 minutes
  },
  POOLS_CONFIG: {
    REFRESH_INTERVAL: 10000, // The time in milliseconds how often data on the earn page will be updated
  },
  CONFIRMATION_BLOCKS: 4, // The required confirmation blocks after blockchain transactions

  TERMS_SERVICE: envVariableValidator(
    process.env.REACT_APP_TERMS_SERVICE,
    "TERMS_SERVICE"
  ),
  WALLET_CONNECT_PROJECT_ID: envVariableValidator(
    process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    "WALLET_CONNECT_PROJECT_ID"
  ),
  PRIVACY_POLICY: envVariableValidator(
    process.env.REACT_APP_PRIVACY_POLICY,
    "PRIVACY_POLICY"
  ),
  GENERAL_TERMS: envVariableValidator(
    process.env.REACT_APP_GENERAL_TERMS,
    "GENERAL_TERMS"
  ),
  DASHBOARD: envVariableValidator(process.env.REACT_APP_DASHBOARD, "DASHBOARD"),
  COOKIE3_CLIENT_ID: envVariableValidator(
    process.env.REACT_APP_COOKIES3_CLIENT_ID,
    "COOKIES3_CLIENT_ID"
  ),
};
