import { Navigate, Outlet } from "react-router-dom";
import { routes } from "../../utils/routes";

export const CookiesRoute = () => {
  const isCookiesEnabled = navigator.cookieEnabled;
  return isCookiesEnabled ? (
    <Outlet />
  ) : (
    <Navigate
      to={routes.cookiesPage}
      state={{ error: "You must enable cookies to proceed" }}
    />
  );
};
