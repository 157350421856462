import React from "react";
import { pen } from "../../assets";
import * as S from "./PenIcon.styled";

export type IconProps = {
  click?: () => void;
  noWrap?: boolean;
};

export const PenIcon = ({ click, noWrap }: IconProps) =>
  noWrap ? (
    <button type="button" onClick={click}>
      <img src={pen} alt="pen icon" />
    </button>
  ) : (
    <S.Wrapper type="button" onClick={click}>
      <img src={pen} alt="pen icon" />
    </S.Wrapper>
  );
