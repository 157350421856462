import BigNumber from "bignumber.js";
import { config } from "../../config";
import { useAppSelector } from "../../redux";

export const useBoostInfo = (poolLevel: PoolLevel) => {
  const { myStaked } = useAppSelector((store) => store.staking);
  const soilPrice = useAppSelector((store) => store.token.price);

  const poolDepositInSoil =
    soilPrice && poolLevel.deposit && poolLevel.deposit !== "0"
      ? new BigNumber(poolLevel.deposit)
          .multipliedBy(
            10 ** (config.SOIL_TOKEN_DECIMALS - config.USDC_TOKEN_DECIMALS)
          )
          .dividedBy(soilPrice)
          .integerValue()
      : undefined;

  const boostLimit = poolLevel.boostPercentage
    ? poolDepositInSoil
        ?.multipliedBy(poolLevel.boostPercentage)
        .dividedBy(10000)
        .integerValue()
    : undefined;

  const areRewardsBoosted = boostLimit && boostLimit.isLessThan(myStaked);

  return { poolDepositInSoil, boostLimit, areRewardsBoosted };
};
