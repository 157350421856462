/* eslint-disable */
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Filler,
  ScriptableContext,
} from "chart.js";
import "chartjs-adapter-dayjs-3/dist/chartjs-adapter-dayjs-3.esm";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import { theme } from "../../style";
import { SmallSpinner } from "../../components";
import * as S from "./TokenPriceChart.styled";
import { useTokenPriceChartData } from "./useTokenPriceChartData";
import "chartjs-plugin-style";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

ChartJS.defaults.hover.intersect = false;

const pluginCanvasBg = {
  id: "customCanvasBackgroundColor",
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = options.color || "rgba(0, 0, 0, 0)";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const corsairPlugin = {
  id: "corsair",
  afterInit: (chart: any) => {
    chart.corsair = {
      x: 0,
      y: 0,
    };
  },
  afterEvent: (chart: any, evt: any) => {
    const {
      chartArea: { top, bottom, left, right },
    } = chart;
    const {
      event: { x, y },
    } = evt;
    if (x < left || x > right || y < top || y > bottom) {
      chart.corsair = {
        x,
        y,
        draw: false,
      };
      chart.draw();
      return;
    }

    chart.corsair = {
      x,
      y,
      draw: true,
    };

    chart.draw();
  },
  afterDatasetsDraw: (chart: any, _: any, opts: any) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
    } = chart;
    const { x, y, draw } = chart.corsair;

    if (!draw) {
      return;
    }

    ctx.lineWidth = opts.width || 0;
    ctx.setLineDash(opts.dash || []);
    ctx.strokeStyle = opts.color || "black";

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    ctx.moveTo(left, y);
    ctx.lineTo(right, y);
    ctx.stroke();
    ctx.restore();
  },
};

// Tooltip.positioners.custom = (elements: any, eventPosition: any) => {
//   // eslint-disable-next-line
//   const { element } = elements?.[0];
//   const { x, y } = element;
//
//   return {
//     x: x - 30,
//     y: y - 60,
//   };
// };

export const TokenPriceChart = () => {
  const options = {
    layout: { padding: 4, borderRadius: 2 },
    tension: 0.4,
    devicePixelRatio: 2,
    maintainAspectRatio: false,
    tooltips: {
      intersect: true,
    },
    elements: {
      point: {
        backgroundColor: theme.colors.priceChart.transparent,
        borderColor: theme.colors.priceChart.transparent,
        pointBackgroundColor: theme.colors.priceChart.transparent,
        pointBorderColor: theme.colors.priceChart.transparent,
        fill: false,
        hoverBackgroundColor: theme.colors.priceChart.primary,
        borderWidth: 0,
        hoverBorderWidth: 5,
      },
      line: {
        borderColor: theme.colors.priceChart.primary,
        backgroundColor: theme.colors.priceChart.primary,
        borderWidth: 2,
      },
    },
    interaction: {
      mode: "nearest" as const,
      intersect: false,
      axis: "x" as const,
    },
    responsive: true,
    scales: {
      y: {
        position: "right" as const,
        ticks: {
          color: theme.colors.greyMid,
          major: {
            enabled: true,
          },
        },
        grid: {
          color: theme.colors.white033,
          border: {
            color: "yellow",
          },
          drawOnChartArea: true,
        },
      },
      x: {
        type: "time" as const,
        ticks: {
          color: (context: any) =>
            context.tick && context.tick.major
              ? theme.colors.white
              : theme.colors.greyMid,
          font: {
            weight: (context: any) =>
              context.tick && context.tick.major && "bold",
          },
          major: {
            enabled: true,
          },
          callback: (value: any, index: any, ticks: any) => {
            ticks.forEach((tick: any) => {
              if (tick.major && tick.label) {
                tick.label = dayjs(tick.value).format("MMMM");
              }
              if (!tick.major && tick.label) {
                tick.label = dayjs(tick.value).format("DD");
              }
            });
            return value;
          },
          maxRotation: 0,
        },
        grid: {
          display: false,
          border: {
            color: "yellow",
          },
        },
      },
    },
    plugins: {
      corsair: {
        dash: [2, 2],
        color: theme.colors.greyMid,
        width: 1,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
        color: theme.colors.greyDark,
      },
      tooltip: {
        caretSize: 0,
        // position: "custom" as const,
        borderColor: theme.colors.greyMid,
        borderStyle: "solid",
        borderWidth: 1,
        titleColor: theme.colors.greyDark,
        bodyColor: theme.colors.greyDark,
        displayColors: true,
        padding: 8,
        cornerRadius: 3,
        fill: true,
        multiKeyBackground: theme.colors.priceChart.primary,
        backgroundColor: theme.colors.white,
        callbacks: {
          // eslint-disable-next-line
          label: (labelData: any) => `  Price: $${labelData.raw.toFixed(3)}`,
          title: (titleData: any) =>
            `${dayjs(titleData[0]?.label).format("DD/MM/YYYY")}`,
        },
      },
    },
  };

  const { labels, data: priceData } = useTokenPriceChartData();

  const data = {
    labels,
    datasets: [
      {
        data: priceData,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const { chart } = context;
          const { ctx } = chart;
          const gradientFill = ctx.createLinearGradient(0, 70, 0, 190);
          gradientFill?.addColorStop(0, theme.colors.priceChart.primary);
          gradientFill?.addColorStop(1, "rgba(0, 0, 0, 0)");
          return gradientFill;
        },
        borderColor: theme.colors.priceChart.primary,
        fill: true,
      },
    ],
  };

  return (
    <S.Wrapper>
      <S.ChartWrapper>
        {priceData ? (
          <Line
            data={data}
            options={options}
            plugins={[pluginCanvasBg, corsairPlugin]}
          />
        ) : (
          <SmallSpinner />
        )}
      </S.ChartWrapper>
    </S.Wrapper>
  );
};
