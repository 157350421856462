import styled from "styled-components";
import { device } from "../../../../style";

export const Wrapper = styled.div`
  width: 109px;
  height: 32px;
  display: flex;

  @media ${device.laptop} {
    width: 80px;
    height: 31px;
  }

  img {
    width: 109px;
    height: 32px;

    @media ${device.laptop} {
      width: 80px;
      height: 31px;
    }
  }
`;
