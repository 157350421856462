import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";
import { getUnlockedTokens, UnlockedPositionItem } from "../../api/staking";
import { stakingService } from "../../services";

interface stakingState {
  isLoading: boolean;
  isError: boolean;
  myStaked: string;
  rewardsEarned: string;
  lastRewards: string;
  apr: string;
  totalTokens: string;
  feeSOIL: string;
  feeUSDC: string;
  lockoutTime: number | null;
  unlockedPositions: UnlockedPositionItem[];
  isLoadingUnlocked: boolean;
}

const initialStaking: stakingState = {
  isLoading: true,
  isError: false,
  myStaked: "0",
  rewardsEarned: "0",
  lastRewards: "0",
  apr: "0",
  totalTokens: "0",
  feeSOIL: "0",
  feeUSDC: "0",
  lockoutTime: null,
  unlockedPositions: [],
  isLoadingUnlocked: false,
};

const fetchStakingData = async () => {
  const [
    rewardsResponse,
    stakedAmountResponse,
    aprResponse,
    stakingFeesResponse,
    unlockedTokensResponse,
  ] = await Promise.all([
    api.staking.getStakingRewards(),
    api.staking.getStakedAmount(),
    api.staking.getStakingAPR(),
    api.staking.getStakingFees(),
    api.staking.getUnlockedTokens(),
  ]);

  return {
    rewardsEarned: rewardsResponse.data.rewardsAmount,
    lastRewards: rewardsResponse.data.lastRewardAmount,
    myStaked: stakedAmountResponse.data.stakedTokens,
    apr: aprResponse.data.apr,
    totalTokens: aprResponse.data.totalTokens,
    feeUSDC: stakingFeesResponse.data.feeInUsdc,
    feeSOIL: stakingFeesResponse.data.fee,
    unlockedTokens: unlockedTokensResponse.data.unlockedTokens,
  };
};

const getStakingData = createAsyncThunk("staking/getData", fetchStakingData);

const refreshStakingData = createAsyncThunk(
  "staking/refreshData",
  fetchStakingData
);

const getLockoutTime = createAsyncThunk("staking/getLockoutTime", async () => {
  const lockoutTime = await stakingService.getLockoutTime();

  return lockoutTime.toNumber();
});

const getUnlockedPositions = createAsyncThunk(
  "staking/getUnlockedPositions",
  async () => {
    const res = await getUnlockedTokens();
    return res.data.unlockedTokens;
  }
);

const resetStakingData = createAction("staking/resetData");

export const stakingSlice = createSlice({
  name: "staking",
  initialState: initialStaking,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStakingData.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isLoadingUnlocked = true;
    });
    builder.addCase(getStakingData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.rewardsEarned = payload.rewardsEarned;
      state.lastRewards = payload.lastRewards;
      state.apr = payload.apr;
      state.myStaked = payload.myStaked;
      state.totalTokens = payload.totalTokens;
      state.feeSOIL = payload.feeSOIL;
      state.feeUSDC = payload.feeUSDC;
      state.unlockedPositions = payload.unlockedTokens;
      state.isLoadingUnlocked = false;
    });
    builder.addCase(getStakingData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isLoadingUnlocked = false;
    });
    builder.addCase(refreshStakingData.pending, (state) => {
      state.isError = false;
    });
    builder.addCase(refreshStakingData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.rewardsEarned = payload.rewardsEarned;
      state.lastRewards = payload.lastRewards;
      state.apr = payload.apr;
      state.myStaked = payload.myStaked;
      state.totalTokens = payload.totalTokens;
      state.feeSOIL = payload.feeSOIL;
      state.feeUSDC = payload.feeUSDC;
      state.unlockedPositions = payload.unlockedTokens;
      state.isLoadingUnlocked = false;
    });
    builder.addCase(refreshStakingData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isLoadingUnlocked = false;
    });
    builder.addCase(getLockoutTime.fulfilled, (state, { payload }) => {
      state.lockoutTime = payload;
    });
    builder.addCase(getLockoutTime.rejected, (state) => {
      state.lockoutTime = null;
    });
    builder.addCase(resetStakingData, (state) => {
      state.isError = false;
      state.myStaked = "0";
      state.rewardsEarned = "0";
      state.lastRewards = "0";
      state.apr = "0";
      state.totalTokens = "0";
      state.feeSOIL = "0";
      state.feeUSDC = "0";
      state.lockoutTime = null;
    });
    builder.addCase(getUnlockedPositions.pending, (state) => {
      state.isLoadingUnlocked = true;
    });
    builder.addCase(getUnlockedPositions.rejected, (state) => {
      state.isLoadingUnlocked = false;
      state.unlockedPositions = initialStaking.unlockedPositions;
    });
    builder.addCase(getUnlockedPositions.fulfilled, (state, { payload }) => {
      state.isLoadingUnlocked = false;
      state.unlockedPositions = payload;
    });
  },
});

export const staking = {
  getStakingData,
  refreshStakingData,
  getLockoutTime,
  resetStakingData,
  getUnlockedPositions,
};

export default stakingSlice.reducer;
