import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "../../style";

export const DisclaimerWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 2px;
  width: 100%;
  z-index: 999;

  @media ${device.tablet} {
    bottom: 0;
  }
`;

export const DisclaimerContainer = styled(motion.div)`
  position: relative;
  padding: 20px;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background: rgba(0, 0, 0, 1);
  border: 1px solid rgba(21, 106, 75, 0.65);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 4px;

  @media ${device.tablet} {
    width: 80%;
  }
`;

export const ToggleExpandButton = styled.button<{ isExpanded: boolean }>`
  width: 168px;
  border-top-right-radius: 4px;
  border-top-left-radius: 30px;
  padding: 8px 8px 8px 32px;
  color: white;
  position: absolute;
  top: -32px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(0, 0, 0, 1);

  svg {
    transform: ${({ isExpanded }) => (isExpanded ? "none" : "rotate(180deg)")};
    path {
      fill: rgba(255, 255, 255, 0.6);
    }
  }
`;

export const WarningIcon = styled.img`
  display: none;

  @media ${device.tablet} {
    width: 60px;
    height: 60px;
    display: initial;
  }
`;
