/* eslint-disable */

export function formatNumberSuffix(value: number): string {
  const suffixes = ["", "k", "m", "b", "t"];

  if (value < 1000 && value > 100) return value.toFixed(0);
  if (value <= 100) return value.toFixed(2);

  const formatValue = (val: number, precision: number = 2): string => {
    const absValue = Math.abs(val);

    if (absValue >= 1000) {
      const scaledValue = absValue / 1000;
      return scaledValue.toFixed(precision).replace(/\.?0+$/, "") + "k";
    }

    return val.toFixed(precision).replace(/\.?0+$/, "");
  };

  let absValue = Math.abs(value);
  let suffixIndex = 0;

  while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
    absValue /= 1000;
    suffixIndex++;
  }

  const formattedNumber = formatValue(value, suffixIndex > 0 ? 2 : 0);

  return value < 0 ? `-${formattedNumber}` : formattedNumber;
}
