import { BigNumber } from "ethers";
import { BigNumber as BG } from "bignumber.js";

const truncateString = (value: string, numDecimalPlaces: number) => {
  const index = value.indexOf(".");
  if (index === -1) {
    return value;
  }
  return value.substring(0, index + numDecimalPlaces + 1);
};

export const formatBigNumber = (
  number: BigNumber | string | null,
  decimals = 0,
  decimalPlaces = 2
) => {
  if (number === "" || number === null) {
    return "-";
  }

  return truncateString(
    new BG(number.toString())
      .dividedBy(new BG(10 ** decimals))
      .toFixed(decimals),
    decimalPlaces
  );
};

export const formatNumber = (number: string | null, decimalPlaces = 2) => {
  if (number === "" || number === null) {
    return "-";
  }

  return truncateString(number, decimalPlaces);
};
