import { AnimatePresence, motion } from "framer-motion";
import {
  walletDisconnectedDarkIcon,
  walletDisconnectedLightIcon,
} from "../../../../assets";
import * as S from "../ToolBar/ToolBar.styled";

export type WalletIconProps = {
  click?: () => void;
  isOpen?: boolean;
  isConnected: boolean;
  isPending?: boolean;
  isToolbar?: boolean;
};

const WalletIcon = ({
  click,
  isOpen,
  isConnected,
  isPending,
  isToolbar,
}: WalletIconProps) => (
  <S.WalletIconBtn
    hasDiode={isConnected || isPending}
    isConnected={isConnected}
    isToolbar={isToolbar}
    className="ignoreOutsideClick"
    type="button"
    onClick={click}
  >
    <S.IconImg
      src={isToolbar ? walletDisconnectedLightIcon : walletDisconnectedDarkIcon}
      alt="wallet disconnected icon"
    />
    <AnimatePresence>
      {isOpen && isToolbar && (
        <S.IconBgWallet
          key="icon"
          as={motion.div}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.2 }}
        />
      )}
    </AnimatePresence>
  </S.WalletIconBtn>
);

export default WalletIcon;
