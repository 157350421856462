import { apiCallWithAuthToken } from "./config";

export const initializeTfa = () =>
  apiCallWithAuthToken.post("/auth/tfa/generate");

export const enableTfa = (code: string) =>
  apiCallWithAuthToken.put("auth/tfa/enable", { code });

export const loginWithTfa = (code: string) =>
  apiCallWithAuthToken.post("auth/tfa/authenticate-login", { code });

export const disableTfa = (code: string) =>
  apiCallWithAuthToken.patch("auth/tfa/disable", { code });

export const recoveryTfa = (code: string) =>
  apiCallWithAuthToken.delete("auth/tfa/recovery", { data: { code } });

export const authTfa = (code: string) =>
  apiCallWithAuthToken.post<{ accessToken: string; refreshToken: string }>(
    "auth/tfa",
    { code }
  );
