import { useRef, HTMLAttributes } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";
import { useDetectOutsideClick } from "../../hooks";
import * as S from "./Modal.styled";

export type ModalProps = {
  title: string;
  isOpen: boolean;
  withOutsideClick?: boolean;
  onClose: () => void;
  withoutCloseIcon?: boolean;
  withBackdropBlur?: boolean;
  icon?: string;
  message?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal");
document.body.appendChild(modalRoot);

export const Modal = ({
  children,
  isOpen,
  onClose,
  title,
  icon,
  message,
  withOutsideClick,
  withoutCloseIcon,
  withBackdropBlur,
  ...rest
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  let el = document.getElementById("modal");

  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "modal");
    document.body.appendChild(el);
  }
  const handleOutsideClick = () => {
    if (isOpen && onClose && withOutsideClick) {
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  useDetectOutsideClick(modalRef, handleOutsideClick);

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <S.Background
          withBackdropBlur={withBackdropBlur && withBackdropBlur}
          className="ignoreOutsideClick"
          initial={{ y: -400, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 400, opacity: 0 }}
          transition={{ duration: 0.35 }}
          data-testid="modal-back"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <S.Modal ref={modalRef} {...rest} message={message}>
            <S.ModalHeader>
              <S.Title>
                {icon ? <img src={icon} alt="boost" /> : null}
                {title}
              </S.Title>
              {!withoutCloseIcon && (
                <S.CloseBtn onClick={() => handleClose()} />
              )}
            </S.ModalHeader>
            {children}
          </S.Modal>
        </S.Background>
      )}
    </AnimatePresence>,
    el
  );
};
