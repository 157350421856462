import styled from "styled-components";
import { device } from "../../style";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    flex-wrap: wrap;
    width: 50%;
  }
`;

export const InstructionBox = styled.div<{ success?: boolean }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme, success }) =>
    success ? `${theme.colors.success}99` : theme.colors.boxBackground};
  gap: 15px;
  border-radius: 20px;
  width: 100%;
  position: relative;
`;

export const SuccessBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.success};
  opacity: 0.6;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
`;

export const SuccessMark = styled.div<{ success?: boolean }>`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: ${({ theme, success }) =>
    success ? `${theme.colors.success}80` : theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: brightness(0) invert(1);
    opacity: 0.8;
    height: 25px;
    width: 25px;
  }
`;
