import styled from "styled-components";
import { device } from "../../style";

export const Text = styled.p`
  color: #fff;

  font-family: Ingra;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 13.09px */

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const Button = styled.button`
  width: 28px;
  height: 28px;
  flex-shrink: 0;

  @media ${device.tablet} {
    width: 32px;
    height: 32px;
  }
`;
