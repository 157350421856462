import React from "react";
import { copyIcon } from "../../assets";
import { toast } from "../../toasts";

export type IconProps = {
  text: string;
  copiedSuccess?: string;
  copiedFailure?: string;
};

const copyText = async (text: string, success: string, failure: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied", success);
  } catch {
    toast.error("Not copied", failure);
  }
};

export const CopyButton = ({
  text,
  copiedSuccess = "Wallet address copied successfully",
  copiedFailure = "Unable to copy wallet address",
}: IconProps) => (
  <button
    type="button"
    onClick={() => copyText(text, copiedSuccess, copiedFailure)}
    style={{ minWidth: "16px" }}
    data-testid="copyButton"
  >
    <img src={copyIcon} alt="copy icon" />
  </button>
);
