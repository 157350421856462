import React from "react";
import { hamburgerIcon, hamburgerClose } from "../../../../assets";
import * as S from "../ToolBar/ToolBar.styled";

export type HamburgerIconProps = {
  click: () => void;
  isOpen: boolean;
};

const HamburgerIcon = ({ click, isOpen }: HamburgerIconProps) => (
  <S.HamburgerIconBtn type="button" onClick={click}>
    <img src={isOpen ? hamburgerClose : hamburgerIcon} alt="hamburger icon" />
  </S.HamburgerIconBtn>
);

export default HamburgerIcon;
