import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { api } from "../../../../api";
import { toast } from "../../../../toasts";
import { UpgradePoolInfo } from "./UpgradePoolInfo";
import * as S from "./UpgradeBody.styled";
import { Button, SmallSpinner, Typography } from "../../../../components";
import { LevelSelect } from "./LevelSelect";
import { usePoolUpgradeHandler } from "./usePoolUpgradeHandler";
import { ConnectWalletButton } from "../../../ConnectWalletButton";

type UpgradeBodyProps = {
  poolLevel: PoolLevel;
  onSuccess: () => void;
};

export const UpgradeBody = (props: UpgradeBodyProps) => {
  const { poolLevel, onSuccess } = props;
  const [otherLevels, setOtherLevels] = useState<PoolLevel[]>();
  const [selectedLevel, setSelectedLevel] = useState<PoolLevel>();
  const { isConnected } = useAccount();

  const fetchOtherPools = async () => {
    try {
      const {
        data: { data },
      } = await api.pools.getLevels({
        filter: {
          "pool.poolId": `$eq:${poolLevel.pool.poolId}`,
        },
      });

      setOtherLevels(
        data.filter(
          (level) => Number(level.usdcApr) > Number(poolLevel.usdcApr)
        )
      );
    } catch (e: unknown) {
      toast.errorHandler(e, "Failed to fetch other pool's levels");
    }
  };

  useEffect(() => {
    fetchOtherPools();
  }, []);

  useEffect(() => {
    if (otherLevels?.length === 1) {
      setSelectedLevel(otherLevels[0]);
    }
  }, [otherLevels]);

  const handleSelectLevel = (level: PoolLevel) => setSelectedLevel(level);

  const { submitUpgrade, isUpgradeInProgress } = usePoolUpgradeHandler(
    onSuccess,
    poolLevel,
    selectedLevel
  );

  return (
    <S.Wrapper>
      <UpgradePoolInfo poolLevel={poolLevel} />
      <Typography.ModalTitle as="p">Upgrade to:</Typography.ModalTitle>
      {otherLevels ? (
        otherLevels.length === 0 ? (
          <Typography.CurrencyValueText>
            There are no available pools with higher APR
          </Typography.CurrencyValueText>
        ) : (
          <>
            <LevelSelect
              levels={otherLevels}
              onLevelSelect={handleSelectLevel}
              selectedLevelId={selectedLevel?.id}
            />
            <Typography.Body>
              All your USDC will be transferred to the new pool and locked on
              the given period
            </Typography.Body>
            {isConnected ? (
              <Button.Primary
                bigSize
                onClick={submitUpgrade}
                disabled={!selectedLevel || isUpgradeInProgress}
              >
                Upgrade
              </Button.Primary>
            ) : (
              <ConnectWalletButton />
            )}
            <Typography.BodySmall>
              * During this action, your earned rewards will be transferred to
              your wallet
            </Typography.BodySmall>
          </>
        )
      ) : (
        <SmallSpinner />
      )}
    </S.Wrapper>
  );
};
