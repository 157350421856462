import { useEffect } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { getNetwork } from "@wagmi/core";
import { AnimatePresence, motion } from "framer-motion";
import { Modal, Typography } from "../../../../../../components";
import * as S from "../SwitchNetwork/SwitchNetwork.styled";
import {
  cursor,
  metamaskIcon,
  walletconnectIcon,
  warningIcon,
} from "../../../../../../assets";
import { useAuth, useModal } from "../../../../../../hooks";
import { config } from "../../../../../../config";
import { useAppSelector } from "../../../../../../redux/hooks";

const SwitchAccountAuth = () => {
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const userData = useAppSelector((state) => state.user.userData);
  const { isOpenSignMessageModal } = useAppSelector((state) => state.ui);
  const { tfaAuthenticated } = useAppSelector((state) => state.user);
  const { walletAddress } = userData;
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();
  const { logout } = useAuth();

  const { address, connector } = useAccount();
  const { isError } = useSignMessage();
  const { chain } = getNetwork();

  useEffect(() => {
    if (
      chain?.id === Number(config.NETWORK_ID) &&
      isLogged &&
      isOpenSignMessageModal &&
      address?.toLowerCase() !== walletAddress.toLowerCase()
    ) {
      handleModalOpen();
    }
    if (!isOpenSignMessageModal) {
      handleModalClose();
    }

    if (!isOpenSignMessageModal && isError) {
      handleModalClose();
      logout();
    }
  }, [address, isOpenSignMessageModal, isError, tfaAuthenticated]);

  return (
    <Modal
      title="Sign message"
      onClose={handleModalClose}
      isOpen={isModalOpen}
      withoutCloseIcon
    >
      <S.WarningWrapper>
        <img width={40} src={warningIcon} alt="warning icon" />
        <Typography.Body>
          You are currently logged in with the address: {walletAddress}
        </Typography.Body>
      </S.WarningWrapper>
      <Typography.Body style={{ paddingBottom: "30px" }}>
        &quot;Sign signature&quot; request has been sent to your wallet app. You
        may approve signing for login to other account or reject to logout.
      </Typography.Body>
      <Typography.Body style={{ paddingBottom: "30px" }}>
        Otherwise, if you want to stay logged in to your current account, change
        your connected wallet to the one that is logged in.
      </Typography.Body>
      <AnimatePresence>
        <Typography.Body style={{ paddingBottom: "20px" }}>
          {connector?.name === "MetaMask"
            ? "Check the metamask extension in Your browser..."
            : "Check your wallet app, that You use with the walletConnect..."}
        </Typography.Body>
        <S.ClickAnimationWrapper>
          <motion.img
            width={70}
            height={70}
            src={
              connector?.name === "MetaMask" ? metamaskIcon : walletconnectIcon
            }
            alt="metamask icon"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            transition={{ repeat: Infinity, duration: 0.2, repeatDelay: 3 }}
          />
          <motion.img
            src={cursor}
            alt="cursor"
            style={{
              filter:
                "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7500%) hue-rotate(229deg) brightness(103%) contrast(105%)",
            }}
            width={20}
            height={20}
            initial={{ x: "-80px" }}
            animate={{ x: 0 }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 1.6,
            }}
          />
        </S.ClickAnimationWrapper>
      </AnimatePresence>
    </Modal>
  );
};

export default SwitchAccountAuth;
