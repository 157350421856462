import React from "react";
import { AnimatePresence } from "framer-motion";
import * as S from "./Switch.styled";
import { theme } from "../../style";

type SwitchProps = {
  isOn: boolean;
  toggleSwitch?: () => void;
  isLevel?: boolean;
};

export const Switch = ({ isOn, toggleSwitch, isLevel }: SwitchProps) => {
  const dotMove = {
    type: "spring",
    stiffness: 700,
    damping: 65,
  };

  const switchVariants = {
    off: {
      backgroundColor: [
        `${theme.colors.switch.backgroundOn}`,
        `${theme.colors.switch.backgroundOff}`,
      ],
    },
    on: {
      backgroundColor: [
        `${theme.colors.switch.backgroundOff}`,
        `${theme.colors.switch.backgroundOn}`,
      ],
    },
  };
  return (
    <AnimatePresence>
      <S.SwitchWrapper
        id="switch"
        data-testid="switch"
        variants={switchVariants}
        animate={isOn ? "on" : "off"}
        transition={{ duration: 0.4 }}
        isSwitchedOn={isOn}
        onClick={toggleSwitch}
        isLevel={isLevel}
      >
        <S.SwitchDot layout transition={dotMove} isLevel={isLevel} />
      </S.SwitchWrapper>
    </AnimatePresence>
  );
};
