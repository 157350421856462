import React from "react";
import { Typography, Button } from "../components";
import * as S from "./ToastBody.styled";
import { config } from "../config";

type ToastBodyProps = {
  title: string;
  message: string;
  transactionHash?: string;
};

const ToastBody = ({ title, message, transactionHash }: ToastBodyProps) => (
  <S.ToastBodyWrapper>
    <Typography.ToastTitle fontWeight={400}>{title}</Typography.ToastTitle>
    <Typography.ToastMessage isWhite fontWeight={400} opacity={0.9}>
      {message}
    </Typography.ToastMessage>
    {transactionHash && (
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <a
          href={`https://${
            config.NODE_ENV === "dev" ? "mumbai." : ""
          }polygonscan.com/tx/${transactionHash}`}
          target="_blank"
          rel="noreferrer noopener nofollow"
        >
          <Button.Primary
            type="button"
            style={{ width: "150px", marginTop: "10px" }}
          >
            check details
          </Button.Primary>
        </a>
      </div>
    )}
  </S.ToastBodyWrapper>
);

export default ToastBody;
