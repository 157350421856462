import React, { useEffect, useState } from "react";
import AuthCode from "react-auth-code-input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormInput,
  Modal,
  Typography,
  Button,
} from "../../../../../../components";
import { useAuth, useModal } from "../../../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import * as S from "./LoginWithTfa.styled";
import { recoveryUserTfa } from "../../../../../../redux/features/userSlice";
import { toast } from "../../../../../../toasts";

type FormValues = {
  recoveryCode: string;
};

const schema = yup.object().shape({
  recoveryCode: yup
    .string()
    .max(16, "The recovery code must be exactly 16 characters long")
    .min(16, "The recovery code must be exactly 16 characters long")
    .required("The recovery code is required"),
});

const LoginWithTfa = () => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });
  const { tfaLogin } = useAuth();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const { tfaEnabled, tfaAuthenticated, loading } = useAppSelector(
    (state) => state.user
  );
  const [useRecoveryCode, setUseRecoveryCode] = useState(false);

  const handleModalCustomClose = () => {
    handleModalClose();
    setUseRecoveryCode(false);
  };

  const handleOnChange = async (code: string) => {
    if (code.length === 6) {
      await tfaLogin(code);
      handleModalCustomClose();
    }
  };

  const handleRecoveryAccount = async (code: string) => {
    try {
      await dispatch(recoveryUserTfa(code)).unwrap();
      toast.success(
        "Recovery account successful",
        "Two-Factor Authenticate has been disabled on your account"
      );
    } catch {
      toast.error(
        "Recovery account failed",
        "The entered recovery code is incorrect"
      );
    }
  };

  const onSubmit = (formData: FormValues) => {
    handleRecoveryAccount(formData.recoveryCode);
    reset();
    handleModalCustomClose();
  };

  const toggleUseRecoveryCode = () => {
    setUseRecoveryCode(!useRecoveryCode);
  };

  useEffect(() => {
    if (tfaEnabled && !tfaAuthenticated && loading) {
      handleModalOpen();
    } else {
      handleModalClose();
    }
  }, [tfaEnabled, tfaAuthenticated, loading]);

  return (
    <Modal
      title="Two-Factor Authentication"
      isOpen={isModalOpen}
      onClose={handleModalCustomClose}
      withoutCloseIcon
    >
      {useRecoveryCode ? (
        <>
          <Typography.Body style={{ marginBottom: "10px" }}>
            <span>Enter your recovery code below or </span>
            <S.BackToCodeBtn as="span" onClick={toggleUseRecoveryCode}>
              back to Two-Factor Authentication
            </S.BackToCodeBtn>
          </Typography.Body>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <FormInput
              {...register("recoveryCode")}
              label=""
              placeholder="Your code"
              error={errors?.recoveryCode?.message}
              anyCharacters
            />
            <Button.Secondary
              style={{ marginTop: "20px" }}
              type="submit"
              bigSize
            >
              Disable TFA
            </Button.Secondary>
          </form>
        </>
      ) : (
        <>
          <Typography.Body>Enter your code below</Typography.Body>
          <S.TwoFaContainer>
            <AuthCode allowedCharacters="numeric" onChange={handleOnChange} />
          </S.TwoFaContainer>
          <S.DontRememberText>
            You don&lsquo;t have access to your code?{" "}
            <S.BackToCodeBtn onClick={toggleUseRecoveryCode}>
              Use recovery code.
            </S.BackToCodeBtn>
          </S.DontRememberText>
        </>
      )}
    </Modal>
  );
};

export default LoginWithTfa;
