import { useEffect, useState } from "react";
import { CurrencyValue, InfoBox } from "../../components";
import { toast } from "../../toasts";
import { config } from "../../config";
import { getErrorMessage, formatBigNumber } from "../../utils";
import { api } from "../../api";

export const PoolsValue = () => {
  const [poolsValue, setPoolsValue] = useState<string | null>(null);

  const getPoolsValue = async () => {
    try {
      const {
        data: { totalDeposited },
      } = await api.pools.getGlobalPoolStats();
      setPoolsValue(
        formatBigNumber(totalDeposited, config.USDC_TOKEN_DECIMALS)
      );
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
    }
  };

  useEffect(() => {
    getPoolsValue();
  }, []);

  return (
    <InfoBox.Admin title="All pools value:">
      <CurrencyValue value={poolsValue} currency="USDC" decimals={2} isBig />
    </InfoBox.Admin>
  );
};
