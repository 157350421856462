import { ethers } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CurrencyValue,
  InfoBox,
  Modal,
  PenIcon,
  Typography,
} from "../../components";
import { config } from "../../config";
import { useModal } from "../../hooks";
import { poolsContractService } from "../../services";
import { device } from "../../style";
import { toast } from "../../toasts";
import { EditSoilLimitForm } from "./components/EditSoilLimitForm";
import { EditUsdcLimitForm } from "./components/EditUsdcLimitForm";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 24px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const PoolWithdrawLimits = () => {
  const [soilWithdrawLimit, setSoilWithdrawLimit] = useState<string>();
  const [usdcWithdrawLimit, setUsdcWithdrawLimit] = useState<string>();

  const handleGetSoilLimit = async () => {
    try {
      const soilLimit = await poolsContractService.limitOfSoilRewards();
      setSoilWithdrawLimit(
        ethers.utils.formatUnits(soilLimit, config.SOIL_TOKEN_DECIMALS)
      );
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch soil rewards limit");
    }
  };

  const handleGetUsdcLimit = async () => {
    try {
      const usdcLimit = await poolsContractService.limitOfUsdcRewards();
      setUsdcWithdrawLimit(
        ethers.utils.formatUnits(usdcLimit, config.USDC_TOKEN_DECIMALS)
      );
    } catch (e: unknown) {
      toast.errorHandler(e, "Unable to fetch usdc rewards limit");
    }
  };

  useEffect(() => {
    handleGetSoilLimit();
    handleGetUsdcLimit();
  }, []);

  const {
    isModalOpen: isUsdcLimitModalOpen,
    handleModalClose: usdcModalClose,
    handleModalOpen: usdcModalOpen,
  } = useModal();
  const {
    isModalOpen: isSoilLimitModalOpen,
    handleModalClose: soilModalClose,
    handleModalOpen: soilModalOpen,
  } = useModal();

  return (
    <>
      <Modal
        title="Edit SOIL rewards withdraw limit"
        isOpen={isSoilLimitModalOpen}
        onClose={soilModalClose}
        withOutsideClick
      >
        <EditSoilLimitForm
          closeModal={soilModalClose}
          value={soilWithdrawLimit || "0"}
          update={handleGetSoilLimit}
        />
      </Modal>
      <Modal
        title="Edit USDC rewards withdraw limit"
        isOpen={isUsdcLimitModalOpen}
        onClose={usdcModalClose}
        withOutsideClick
      >
        <EditUsdcLimitForm
          closeModal={usdcModalClose}
          value={usdcWithdrawLimit || "0"}
          update={handleGetUsdcLimit}
        />
      </Modal>
      <ItemsWrapper>
        <Typography.Header>Pool limits</Typography.Header>
        <BoxesWrapper>
          <InfoBox.Admin title="SOIL rewards withdraw limit:">
            <Row>
              <CurrencyValue
                value={soilWithdrawLimit || null}
                currency="$SOIL"
                decimals={2}
                isBig
              />
              <PenIcon noWrap click={soilModalOpen} />
            </Row>
          </InfoBox.Admin>
          <InfoBox.Admin title="USDC rewards withdraw limit:">
            <Row>
              <CurrencyValue
                value={usdcWithdrawLimit || null}
                currency="USDC"
                decimals={2}
                isBig
              />
              <PenIcon noWrap click={usdcModalOpen} />
            </Row>
          </InfoBox.Admin>
        </BoxesWrapper>
      </ItemsWrapper>
    </>
  );
};
