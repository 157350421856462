import styled from "styled-components";

const ContentBoxWrapper = styled.div`
  border-radius: 12px;
  background: #00000099;
  padding: 21.14px 25.01px 20.36px 25.01px;
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    background: linear-gradient(to right, #156a4ba6, #462373);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

type ContentBoxProps = {
  children: React.ReactNode;
};

export const ContentBox = ({ children }: ContentBoxProps) => (
  <ContentBoxWrapper>{children}</ContentBoxWrapper>
);
