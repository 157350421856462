import styled from "styled-components";
import { device } from "../../style";

export const WalletAddressWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8.67px;
  height: 18px;
`;

export const CopiedWrapper = styled.div`
  position: absolute;
  top: -1px;
  opacity: 0.9;
  width: calc(100% - 25px);
  height: 100%;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  color: black;
  font-size: 12px;
`;

export const ShortenWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  max-width: 230px;

  @media ${device.mobileM} {
    max-width: 245px;
  }
  @media ${device.mobileL} {
    max-width: 315px;
  }
  @media ${device.mobileXL} {
    max-width: 475px;
  }

  @media ${device.tablet} {
    max-width: 215px;
  }

  @media ${device.laptop} {
    max-width: 115px;
  }
  @media ${device.laptopL} {
    max-width: 355px;
  }
  @media ${device.desktop} {
    max-width: 1240px;
  }

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.notification.text.primary};
    /* border-top: 6px solid ${({ theme }) => theme.colors.background};
    border-bottom: 6px solid ${({ theme }) => theme.colors.background}; */
    border-radius: 10px;

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.notification.text.secondary};
    }
  }
`;

export const OtherAccountInfo = styled.p`
  font-size: ${({ theme }) => theme.fontStyles.walletStatus.fontSize};
  color: ${({ theme }) => theme.colors.error};
  padding-top: 5px;
`;
