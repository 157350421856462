import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 250px;
`;

export const TabsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -24px;
`;

export const ChartWrapper = styled.div`
  max-height: 100%;
  flex-grow: 1;
`;
