import { ethers } from "ethers";
import { soilTokenAbi } from "./contracts/abi/soilToken.abi";
import { usdcTokenAbi } from "./contracts/abi/usdcToken.abi";
import { config } from "../config";
import { AppRpcProvider } from "../utils";

const initUSDCContract = () =>
  new ethers.Contract(config.USDC_TOKEN_ADDRESS, usdcTokenAbi, AppRpcProvider);

export const getUSDCBalance = async (walletAddress: string) => {
  const usdcContract = initUSDCContract();
  const usdcBalance = await usdcContract.balanceOf(walletAddress);
  return usdcBalance;
};

const initSOILContract = () =>
  new ethers.Contract(config.SOIL_TOKEN_ADDRESS, soilTokenAbi, AppRpcProvider);

export const getSOILBalance = async (walletAddress: string) => {
  const soilContract = initSOILContract();
  const soilBalance = await soilContract.balanceOf(walletAddress);
  return soilBalance;
};

export const transferUsdcTokens = async (
  to: string,
  amount: ethers.BigNumber,
  signer: ethers.providers.JsonRpcSigner
) => {
  const usdcContract = initUSDCContract();
  const { wait } = await usdcContract.connect(signer).transfer(to, amount);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};
