import { useEffect } from "react";
import { useBoolean } from "./useBoolean";

export const usePreventTabClose = (
  messageBeforeTabClose = "Are you sure you want to exit? Action may not be saved"
) => {
  const [enabled, enable, disable] = useBoolean(false);

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      event.returnValue = messageBeforeTabClose;

      return event;
    };

    if (enabled) {
      window.addEventListener("beforeunload", handleTabClose);
    }

    return () => {
      if (enabled) {
        window.removeEventListener("beforeunload", handleTabClose);
      }
    };
  }, [enabled]);

  return {
    enableTabClosePrevention: enable,
    disableTabClosePrevention: disable,
  };
};
