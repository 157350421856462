import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { getUser, loginWithSignature, logout } from "../../api/auth";
import {
  authTfa,
  disableTfa,
  enableTfa,
  loginWithTfa,
  recoveryTfa,
} from "../../api/tfa";
import { ContractUserRole } from "../../constants";
import { protocolSettingsService } from "../../services/protocolSettings.service";
import { isAdminRole } from "../../utils";

type UserLoginData = {
  address: string;
  signedMessage: string;
};

export type KYCDataType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: "" | "started" | "approved" | "pending" | "rejected";
};

type TfaActions = {
  isStakeAuthenticated: boolean;
  isUnstakeAuthenticated: boolean;
  isHarvestAuthenticated: boolean;
  isCompoundAuthenticated: boolean;
  isPoolsDepositAuthenticated: boolean;
  isPoolsClaimAuthenticated: boolean;
  isCreatePoolAuthenticated: boolean;
  isUpgradePoolAuthenticated: boolean;
  isWithdrawPoolAuthenticated: boolean;
  isClaimAllRewardsAuthenticated: boolean;
};

type UserDataType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  lockReason: string;
  walletAddress: string;
  roles: Array<{ name: string }>;
  contractRole: string | null;
  email: string;
  kyc?: KYCDataType;
};

interface userState {
  userData: UserDataType;
  tfaEnabled: boolean;
  tfaAuthenticated: boolean;
  tfaAuthenticatedForAction: TfaActions;
  currentAction: string;
  preLogged: boolean;
  isLogged: boolean;
  loading: boolean;
}

export const getUserData = createAsyncThunk("user/getUser", async () => {
  const response = await getUser();
  const { tfaEnabled } = response.data;

  let contractRole: ContractUserRole | null = null;

  if (isAdminRole(response.data.roles)) {
    contractRole = await protocolSettingsService.checkRole(
      response.data.walletAddress
    );
  }

  return { ...response.data, contractRole, tfaEnabled };
});

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (data: UserLoginData) => {
    const { address, signedMessage } = data;
    const response = await loginWithSignature(address, signedMessage);
    const { accessToken, refreshToken } = response.data;
    const decodedToken = await jwtDecode<MyJwtToken>(accessToken);
    const { tfaEnabled, tfaAuthenticated, tokenType } = decodedToken;
    const preLogged = tokenType === "pre-login";
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    return { tfaEnabled, tfaAuthenticated, preLogged };
  }
);

export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  const response = await logout();
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  return response.data;
});

export const setCurrentTfaAction = createAsyncThunk(
  "user/setTfaAction",
  (action: string) => action
);

export const authenticateTfaStake = createAction("user/TfaAuthenticateStake");
export const authenticateTfaUnStake = createAction(
  "user/TfaAuthenticateUnstake"
);
export const authenticateTfaCompound = createAction(
  "user/TfaAuthenticateCompound"
);
export const authenticateTfaHarvest = createAction(
  "user/TfaAuthenticateHarvest"
);

export const unathenticateTfa = createAction("user/TfaUnauthenticate");

export const authenticateTfaDeposit = createAction(
  "user/TFAAuthenticateDeposit"
);

export const authenticateTfaClaim = createAction("user/TFAAuthenticateClaim");

export const authenticateTfaClaimAll = createAction(
  "user/TFAauthenticateClaimAll"
);

export const authenticateTfaUpgradePool = createAction(
  "user/TFAauthenticateUpgrade"
);

export const authenticateTfaWithdrawPool = createAction(
  "user/TFAauthenticateWithdraw"
);

export const authenticateCreatePool = createAction(
  "admin/createPoolAuthenticate"
);

export const refreshUserData = createAsyncThunk(
  "user/refreshUser",
  async (_, thunkAPI) => {
    const response = await getUser();
    thunkAPI.dispatch(unathenticateTfa());
    return response.data;
  }
);

export const loginUserTfa = createAsyncThunk(
  "user/loginUserTfa",
  async (code: string) => {
    const response = await loginWithTfa(code);
    const { accessToken, refreshToken } = response.data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    return response.data;
  }
);

export const enableUserTfa = createAsyncThunk(
  "user/enableTfa",
  async (code: string) => {
    const response = await enableTfa(code);
    return response;
  }
);

export const disableUserTfa = createAsyncThunk(
  "user/disableTfa",
  async (code: string) => {
    const response = await disableTfa(code);
    return response;
  }
);

export const recoveryUserTfa = createAsyncThunk(
  "user/recoveryTfa",
  async (code: string) => {
    const res = await recoveryTfa(code);
    return res;
  }
);

export const tfaUserAuthorization = createAsyncThunk(
  "user/tfaAuthorization",
  async (code: string) => {
    const { data } = await authTfa(code);
    const { accessToken, refreshToken } = data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    return {
      accessToken,
      refreshToken,
    };
  }
);

export const resetTfaAuthorizationForAction = createAction("user/tfaReset");

const initialState: userState = {
  userData: {
    id: "",
    createdAt: "",
    updatedAt: "",
    walletAddress: "",
    roles: [],
    isActive: true,
    lockReason: "",
    contractRole: null,
    email: "",
    kyc: {
      id: 0,
      createdAt: "",
      updatedAt: "",
      status: "",
    },
  },
  isLogged: false,
  loading: false,
  tfaEnabled: false,
  tfaAuthenticated: false,
  tfaAuthenticatedForAction: {
    isCompoundAuthenticated: false,
    isHarvestAuthenticated: false,
    isStakeAuthenticated: false,
    isUnstakeAuthenticated: false,
    isPoolsDepositAuthenticated: false,
    isPoolsClaimAuthenticated: false,
    isCreatePoolAuthenticated: false,
    isUpgradePoolAuthenticated: false,
    isWithdrawPoolAuthenticated: false,
    isClaimAllRewardsAuthenticated: false,
  },
  currentAction: "",
  preLogged: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.loading = false;
      state.isLogged = false;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const { tfaEnabled, tfaAuthenticated, preLogged } = payload;
      state.tfaEnabled = tfaEnabled;
      state.tfaAuthenticated = tfaAuthenticated;
      state.preLogged = preLogged;
    });
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      state.userData = { ...payload };
      state.loading = false;
      state.isLogged = true;
      state.tfaEnabled = payload.tfaEnabled;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.loading = false;
      state.isLogged = false;
      state.userData = initialState.userData;
    });
    builder.addCase(refreshUserData.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(refreshUserData.rejected, (state) => {
      state.isLogged = false;
      state.userData = initialState.userData;
    });
    builder.addCase(refreshUserData.fulfilled, (state, { payload }) => {
      state.isLogged = true;
      state.userData = {
        ...payload,
        contractRole: state.userData.contractRole,
      };
    });
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.loading = false;
      state.isLogged = false;
      state.userData = initialState.userData;
    });
    builder.addCase(logoutUser.rejected, (state) => {
      state.loading = false;
      state.isLogged = false;
      state.userData = initialState.userData;
    });
    builder.addCase(loginUserTfa.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUserTfa.rejected, (state) => {
      state.loading = false;
      state.isLogged = false;
    });
    builder.addCase(loginUserTfa.fulfilled, (state) => {
      state.loading = true;
      state.tfaAuthenticated = true;
      state.isLogged = true;
      state.tfaEnabled = true;
      state.preLogged = false;
    });
    builder.addCase(disableUserTfa.fulfilled, (state) => {
      state.tfaAuthenticated = false;
      state.tfaEnabled = false;
    });
    builder.addCase(enableUserTfa.fulfilled, (state) => {
      state.tfaEnabled = true;
      state.tfaAuthenticated = true;
    });
    builder.addCase(recoveryUserTfa.fulfilled, (state) => {
      state.tfaEnabled = false;
      state.tfaAuthenticated = false;
      state.loading = false;
    });
    builder.addCase(recoveryUserTfa.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(authenticateTfaStake, (state) => {
      state.tfaAuthenticatedForAction.isStakeAuthenticated = true;
    });
    builder.addCase(authenticateTfaDeposit, (state) => {
      state.tfaAuthenticatedForAction.isPoolsDepositAuthenticated = true;
    });
    builder.addCase(authenticateTfaUnStake, (state) => {
      state.tfaAuthenticatedForAction.isUnstakeAuthenticated = true;
    });
    builder.addCase(authenticateTfaCompound, (state) => {
      state.tfaAuthenticatedForAction.isCompoundAuthenticated = true;
    });
    builder.addCase(authenticateTfaHarvest, (state) => {
      state.tfaAuthenticatedForAction.isHarvestAuthenticated = true;
    });
    builder.addCase(authenticateTfaClaim, (state) => {
      state.tfaAuthenticatedForAction.isPoolsClaimAuthenticated = true;
    });
    builder.addCase(unathenticateTfa, (state) => {
      state.tfaAuthenticatedForAction = initialState.tfaAuthenticatedForAction;
      state.currentAction = initialState.currentAction;
    });
    builder.addCase(setCurrentTfaAction.fulfilled, (state, { payload }) => {
      state.currentAction = payload;
    });
    builder.addCase(authenticateCreatePool, (state) => {
      state.tfaAuthenticatedForAction.isCreatePoolAuthenticated = true;
    });
    builder.addCase(authenticateTfaClaimAll, (state) => {
      state.tfaAuthenticatedForAction.isClaimAllRewardsAuthenticated = true;
    });
    builder.addCase(authenticateTfaUpgradePool, (state) => {
      state.tfaAuthenticatedForAction.isUpgradePoolAuthenticated = true;
    });
    builder.addCase(authenticateTfaWithdrawPool, (state) => {
      state.tfaAuthenticatedForAction.isWithdrawPoolAuthenticated = true;
    });
    builder.addCase(resetTfaAuthorizationForAction, (state) => {
      state.tfaAuthenticatedForAction.isCompoundAuthenticated = false;
      state.tfaAuthenticatedForAction.isHarvestAuthenticated = false;
      state.tfaAuthenticatedForAction.isStakeAuthenticated = false;
      state.tfaAuthenticatedForAction.isUnstakeAuthenticated = false;
      state.tfaAuthenticatedForAction.isPoolsDepositAuthenticated = false;
      state.tfaAuthenticatedForAction.isPoolsClaimAuthenticated = false;
    });
  },
});

export default userSlice.reducer;
