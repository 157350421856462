import styled from "styled-components";
import { device } from "../../style";
import { Typography } from "../Typography/Typography";

export const Wrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  min-height: 82px;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  padding: 15px;
  background-color: ${({ theme }) =>
    theme.colors.criticalNotification.background};
  box-shadow: 3px 6px 25px 0px rgba(30, 30, 32, 1);
  flex-wrap: wrap;
  justify-content: flex-end;

  @media ${device.tablet} {
    flex-wrap: nowrap;
  }
`;

export const IconWrapper = styled.div`
  min-width: 20px;
  min-height: 20px;
  margin: 2px 17px 0 2px;

  img {
    width: 100%;
  }
`;

export const MassageWrapper = styled.div`
  width: calc(100% - 39px);
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.criticalNotification.primaryText};
`;

export const Description = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.criticalNotification.secondaryText};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 50px;
  margin-left: 35px;
  margin-right: 20px;
  margin-top: 14px;
  margin-bottom: 2px;

  @media ${device.tablet} {
    margin-top: 30px;
  }
`;

export const ActionButton = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.criticalNotification.primaryText};
  font-weight: 300;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-self: flex-start;
  position: relative;
  overflow-x: hidden;

  &::after {
    content: "";
    display: block;
    background-color: ${({ theme }) =>
      theme.colors.criticalNotification.primaryText};
    width: 100%;
    height: 1px;
    transform: translateX(-100%);
    transition: 0.2s ease;
  }

  &:hover {
    &::after {
      transform: translateX(0);
    }
  }
`;
