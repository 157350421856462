import { useState, useEffect } from "react";

export const usePageWide = () => {
  const [pageWide, setPageWide] = useState<number>(window.innerWidth);

  const handleResize = () => {
    if (pageWide === window.innerWidth) return;
    setPageWide(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { pageWide };
};
