import styled from "styled-components";
import { Typography } from "../../../../components";

export const Boost = styled(Typography.Description)`
  display: flex;
  width: 70px;
  gap: 4px;
  color: ${({ theme }) => theme.colors.farm.boostText};
  background-color: ${({ theme }) => theme.colors.farm.boostBgc};
  padding: 4.5px 4px;

  img {
    width: 12px;
    height: 12px;
  }
`;
