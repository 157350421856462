import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import { useEffect } from "react";
import { Button, Modal } from "../../../components";
import { useBoolean } from "../../../hooks";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { DepositModalBody } from "./DepositModalBody";

type PoolDepositProps = {
  poolLevel: PoolLevel;
  onDepositSuccess?: () => void;
};

export const PoolDeposit = ({
  poolLevel,
  onDepositSuccess,
}: PoolDepositProps) => {
  const [isModalVisible, openModal, closeModal] = useBoolean();
  const dispatch = useAppDispatch();
  const { walletAddress } = useAppSelector((state) => state.user.userData);

  const isPoolEnded = dayjs().isAfter(poolLevel.pool.end);
  const isDepositEnded = dayjs().isAfter(poolLevel.endOfDeposits);
  const isReachedHardCap = new BigNumber(
    poolLevel.pool.balance
  ).isGreaterThanOrEqualTo(new BigNumber(poolLevel.pool.hardCap));

  const userNotInWhitelist =
    poolLevel?.isWhitelisted && !poolLevel?.isUserOnTheWhitelist;

  const handleDepositSuccess = () => {
    closeModal();
    if (onDepositSuccess) {
      onDepositSuccess();
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal();
  };

  useEffect(() => {
    if (isModalVisible) {
      dispatch(actions.wallet.getBalances(walletAddress));
    }
  }, [isModalVisible]);

  return (
    <>
      <Modal title="Loan terms" isOpen={isModalVisible} onClose={closeModal}>
        <DepositModalBody
          poolLevel={poolLevel}
          onDepositSuccess={handleDepositSuccess}
          closeModal={closeModal}
        />
      </Modal>
      <Button.Primary
        style={{ padding: "0 24px" }}
        onClick={(e) => handleOpenModal(e)}
        disabled={
          isPoolEnded ||
          isDepositEnded ||
          isReachedHardCap ||
          userNotInWhitelist
        }
      >
        Earn
      </Button.Primary>
    </>
  );
};
