export type ThemeType = typeof theme;

const colors = {
  background: "rgba(0, 0, 0, 0.90)",
  white: "#ffffff",
  white033: "rgb(255, 255, 255, 0.33)",
  white05: "rgb(255, 255, 255, 0.5)",
  black: "#000000",
  grey: "#E4E4E4",
  greyMid: "#BCBCBB",
  greyDark: "#36373A",
  greyDark01: "rgba(24, 25, 28, 0.1)",
  link: "#B9C0FF",
  dotsActive: "#2C2E36",
  arrow:
    "linear-gradient(267.05deg, rgba(139, 87, 250, 0.32) -1.56%, rgba(137, 63, 255, 0.296) 100.39%)",
  primary: "#26A677",
  secondary: "#D243A1",
  error: "#E13E3E",
  errorDark: "#573333",
  warning: "#F39D52",
  warningLight: "#F8CFAA",
  success: "#33CC66",
  successBorder: "#1B935D",
  successLight: "#E8FFEF",
  greyLine: "#3E3E3E",

  boxBackground: "rgba(0, 0, 0, 0.50)",
  boxShadow: "1px 3px 5px rgba(30, 30, 32, 1)",

  box: {
    background: {
      primary: "rgba(255, 255, 255, 0.01)",
      secondary: "#282A30",
    },
    shadow: "1px 3px 5px rgba(30, 30, 32, 1)",
    border: "#FFFFFF1F",
    smallBox: "#1a1b1e",
  },

  messageLabels: {
    orange: "#F39D52",
    black: "rgba(16, 16, 16, 0.61)",
  },

  button: {
    primary: {
      main: "#26A677",
      hover: "rgba(38, 166, 119, 0.40)",
      border: "#26A677",
    },

    secondary: {
      main: "linear-gradient(0deg, #202125, #202125), linear-gradient(0deg, #52555F, #52555F)",
      hover:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, #202125, #202125), linear-gradient(0deg, #52555F, #52555F)",
      clicked:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(0deg, #202125, #202125), linear-gradient(0deg, #52555F, #52555F)",
      border: "rgba(82,85,95,1)",
    },
  },

  text: {
    primary: "rgba(255,255,255,0.92)",
    grey: "rgba(255, 255, 255, 0.6)",
  },

  toast: {
    background: "rgba(255, 255, 255, 0.98)",
    boxShadow: "3px 6px 25px #1E1E20",
    message: "#645F5F",
    title: "#202020",
    successIcon: "#33CC66",
    errorIcon: "#E13E3E",
  },

  chart: {
    primary: "#8E45EC",
    primary40: "#8E45EC40",
    secondary: "#35AE0A",
    third: "#8A108C",

    hover: {
      primary: "#741be7",
      secondary: "#2d9308",
      third: "#750d77",
    },
  },

  priceChart: {
    primary: "rgb(20, 199, 132, 1)",
    secondary: "rgb(20, 199, 132, 1)",
    bg: "rgba(255, 255, 255,1)",
    transparent: "rgba(0,0,0,0)",
  },
  modal: {
    background: "rgba(0, 0, 0, 1)",
    border: "#156A4B",
    info: "#FFF5BE",
  },

  card: {
    background: "rgba(47,50,60,0.5)",
    boxShadow: "#1E1E20",
  },

  dropdown: {
    btnBackground: "#202125",
    ulBackgroundOpacity: "rgba(255, 255, 255, 0.98)",
    ulBackground: "rgba(255, 255, 255)",
    liBackground: "rgba(32, 33, 36, 0.05)",
    boxShadow: "3px 6px 25px rgba(30, 30, 32, 0.5)",
  },

  switch: {
    backgroundOn: "#8E45EC54",
    backgroundOff: "#FFFFFF1A",
    boxShadow: "3px 6px 25px #1E1E20",
    backgroundDot: "#FFFFFF54",
  },

  notification: {
    header: "#FFFCFC",
    background: {
      checked: "#00000030",
      notChecked: "#00000030",
      counter: "#2A473A",
    },
    text: {
      primary: "#D2D2D2",
      secondary: "#939393",
    },
  },

  criticalNotification: {
    background: "#F8CFAA",
    primaryText: "#202020",
    secondaryText: "#645F5F",
  },

  toolbar: {
    background: "rgba(0, 0, 0, 1)",
    boxShadowDark: "0px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    boxShadowLight: "3px 6px 25px #1E1E20",
  },

  walletStatus: {
    backgroundOn: "#2A473A",
    backgroundOff: "#FFFFFFEB",
    backgroundPending: "#F39D52",
    backgroundReject: "#573333",
  },

  farm: {
    background:
      "linear-gradient(113.09deg, rgba(74, 106, 102, 0.05) 0.98%, rgba(22, 6, 69, 0.05) 102.6%), rgba(41, 42, 46, 0.2)",
    contrastiveBackground:
      "linear-gradient(113.09deg, rgba(22, 6, 69, 0.3) 0.01%, rgba(22, 6, 69, 0.3) 110%), rgba(41, 42, 46, 100)",
    shadow: "3px 6px 25px #1E1E20",
    shadowOtherBulb:
      "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    recentlyText: "#CACACA",
    recentlyBgc: "#1010109C",
    boostText: "#B688FF",
    boostBgc: "#300D409C",
    bar: {
      start: "#D243A1",
      filled: "#7A43D2",
    },
  },
  swapButton: {
    bgc: `linear-gradient(
      267.05deg,
      rgba(139, 87, 250, 0.32) -1.56%,
      rgba(137, 63, 255, 0.296) 100.39%
    ), linear-gradient(
      95.81deg,
      rgba(160, 106, 248, 0.4) 14.66%,
      rgba(158, 115, 246, 0.4) 78.65%
    )`,
    border: "rgba(158, 115, 246, 0.4)",
  },
};

const zIndex = {
  toolbar: {
    iconBg: 1,
    iconImg: 2,
    walletIconBtn: 3,
    dropdown: 2,
  },
  modal: 1000,
  spinner: 1,
  nav: 100,
  hamburger: 101,
  dropdown: {
    dots: 50,
    menu: 100,
  },
};

const fontFamily = {
  primaryFont: "Be Vietnam Pro",
};

const fontStyles = {
  big: {
    fontSize: "50px",
    lineHeight: "63.25px",
  },

  medium: {
    fontSize: "32px",
    lineHeight: "40.48px",
    letterSpacing: "0.05em",
  },

  headerBig: {
    fontSize: "22px",
    lineHeight: "24px",
  },

  header: {
    fontSize: "20px",
    lineHeight: "25.3px",
  },

  headerMedium: {
    fontSize: "18px",
    lineHeight: "22.77px",
    letterSpacing: "0.05em",
  },

  headerSmall: {
    fontSize: "18px",
    lineHeight: "20.24px",
    letterSpacing: "0.05em",
  },

  body: {
    fontSize: "14px",
    lineHeight: "17.71px",
    letterSpacing: "0.05em",
  },

  bodySmall: {
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.05em",
  },

  description: {
    fontSize: "10px",
    lineHeight: "12.65px",
    letterSpacing: "0.05em",
  },

  walletStatus: {
    fontSize: "10px",
    lineHeight: "11.5px",
    fontWeight: 700,
  },

  messageTime: {
    fontSize: "10px",
    lineHeight: "13px",
    letterSpacing: "0.05em",
  },

  toast: {
    title: {
      fontSize: "14px",
      lineHeight: "17.71px",
    },
    message: {
      fontSize: "12px",
      lineHeight: "15.18px",
    },
  },
};

export const theme = { colors, fontFamily, fontStyles, zIndex } as const;
