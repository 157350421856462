import { apiCallWithAuthToken } from "./config";

export const updateSettings = (setting: string, value: string) =>
  apiCallWithAuthToken.patch(`settings/${setting}`, {
    value,
  });

type GetSettingsResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  key: string;
  value: string;
};

export const getSettings = () =>
  apiCallWithAuthToken.get<GetSettingsResponse[]>(`settings`);

type GetUnclaimedRewardsResponse = {
  stakingRewards: string;
  poolsRewards: {
    soil: string;
    usdc: string;
  };
};

export const getUnclaimedRewards = () =>
  apiCallWithAuthToken.get<GetUnclaimedRewardsResponse>(
    "stats/total-unclaimed-rewards"
  );
