import { BigNumber } from "ethers";
import qs from "qs";
import { apiCallWithAuthToken } from "./config";
import { GetUsersData } from "./user";

type GetStakingAPRResponse = {
  apr: string;
  totalTokens: string;
};

export const getStakingAPR = () =>
  apiCallWithAuthToken.get<GetStakingAPRResponse>("staking/apr");

type GetStakedAmountResponse = {
  stakedTokens: string;
};

export const getStakedAmount = () =>
  apiCallWithAuthToken.get<GetStakedAmountResponse>("staking/staked-amount");

type GetStakingRewardsResponse = {
  rewardsAmount: string;
  lastRewardAmount: string;
};

export const getStakingRewards = () =>
  apiCallWithAuthToken.get<GetStakingRewardsResponse>("staking/rewards-amount");

type GetStakingFeesResponse = {
  fee: string;
  feeInUsdc: string;
};

export const getStakingFees = () =>
  apiCallWithAuthToken.get<GetStakingFeesResponse>("staking/fees");

type GetStakingSignatureStakeResponse = {
  signature: string;
  fee: string;
  deadline: string;
  feeInUsdt: string;
};

export const getSignatureStake = (value: BigNumber) =>
  apiCallWithAuthToken.get<GetStakingSignatureStakeResponse>(
    `staking/signature/stake?stakeAmount=${value}`
  );

type GetSignatureUnstakeResponse = {
  signature: string;
  fee: string;
  deadline: string;
};

export const getSignatureUnstake = (value: BigNumber) =>
  apiCallWithAuthToken.get<GetSignatureUnstakeResponse>(
    `staking/signature/unstake?withdrawAmount=${value}`
  );

type GetSignatureClaimResponse = {
  signature: string;
  fee: string;
  feeInUsdt: string;
  deadline: string;
};

export const getSignatureClaim = () =>
  apiCallWithAuthToken.get<GetSignatureClaimResponse>(
    "staking/signature/claim"
  );

type GetSignatureRestakeResponse = {
  signature: string;
  fee: string;
  feeInUsdt: string;
  deadline: string;
};

export const getSignatureRestake = (value: BigNumber) =>
  apiCallWithAuthToken.get<GetSignatureRestakeResponse>(
    `staking/signature/restake?restakeAmount=${value}`
  );

export type UserStakingAction = {
  createdAt: string;
  id: number;
  updatedAt: string;
  amount: string;
  balanceAfter: string;
  fee: string;
  action: string;
  txHash: string;
  txBlock: number;
  txIndex: number;
  start: string;
  end: string;
  user: GetUsersData;
};

export type UserStakingRewards = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  amountAvailable: string;
  start: string;
  end: string;
  user: GetUsersData;
};

export type UserStakingClaims = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  fee: string;
  txHash: string;
  txBlock: number;
  txTime: string;
  user: GetUsersData;
};

type GetUserStakingActionsResponse = {
  data: UserStakingAction[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

type UserStakingFilteredKeys = "id";

export const getUserStakingActions = (
  params: GetQueryParams<UserStakingFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserStakingActionsResponse>(
    `/staking/user/${id}/actions?${queryString}`
  );
};

type GetUserStakingRewardsResponse = {
  data: UserStakingRewards[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUserStakingRewards = (
  params: GetQueryParams<UserStakingFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserStakingRewardsResponse>(
    `/staking/user/${id}/rewards?${queryString}`
  );
};

type GetUserStakingClaimsResponse = {
  data: UserStakingClaims[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUserStakingClaims = (
  params: GetQueryParams<UserStakingFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserStakingClaimsResponse>(
    `/staking/user/${id}/claims?${queryString}`
  );
};

export const getStakingRewardsHolders = () =>
  apiCallWithAuthToken.get("/staking/rewards-holders");

export type UnlockedPositionItem = {
  amount: string;
  createdAt: string;
  id: number;
  isUnstaked: boolean;
  positionId: string;
  txBlock: number;
  txHash: string;
  txIndex: number;
  txTime: string;
  unlockTime: string;
  updatedAt: string;
};

type GetUnlockedTokensResponse = {
  unlockedTokens: UnlockedPositionItem[];
};

export const getUnlockedTokens = () =>
  apiCallWithAuthToken.get<GetUnlockedTokensResponse>(
    "staking/unlocked-tokens"
  );

type GetStakingLimits = {
  restakingLimit: string;
  soilLimit: string;
};

export type GetStakingLimitsResponse = {
  data: GetStakingLimits;
};

export const getStakingLimits = () =>
  apiCallWithAuthToken.get<GetStakingLimits>("staking/limits");
