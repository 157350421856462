import { ethers, BigNumber, BigNumberish } from "ethers";
import { config } from "../config";
import { AppRpcProvider } from "../utils";
import { VestingAbi } from "./contracts/abi";

export const fetchVestingInfo = async (walletAddress: string) => {
  const vestingContract = new ethers.Contract(
    config.VESTING_ADDRESS,
    VestingAbi,
    AppRpcProvider
  );
  const vaultsLength = await vestingContract.vaultsLength(walletAddress);

  const pages = await vestingContract.vaultsPages(walletAddress);

  const walletInfoPromises = [];

  for (let i = 0; i < pages?.toNumber(); i += 1) {
    walletInfoPromises.push(vestingContract.walletInfo(walletAddress, i));
  }

  const walletInfos = await Promise.all(walletInfoPromises);
  const VestingInfoArr = walletInfos.reduce(
    (accInfo, info) => {
      accInfo.lockedTokens = accInfo.lockedTokens.add(info.lockedTokens);
      accInfo.nextRelease =
        accInfo.nextRelease < BigNumber.from(0)
          ? Math.min(accInfo.nextRelease.toNumber(), info.nextRelease)
          : info.nextRelease.toNumber();
      accInfo.totalTokens = accInfo.totalTokens.add(info.totalTokens);
      accInfo.unlockedTokens = accInfo.unlockedTokens.add(info.unlockedTokens);
      accInfo.nextReleaseAmount = accInfo.nextReleaseAmount.add(
        info.nextReleaseAmount
      );

      return accInfo;
    },
    {
      lockedTokens: BigNumber.from(0),
      nextRelease: BigNumber.from(0),
      totalTokens: BigNumber.from(0),
      unlockedTokens: BigNumber.from(0),
      nextReleaseAmount: BigNumber.from(0),
      endDate: BigNumber.from(0),
    }
  );
  if (!vaultsLength.eq(0x00)) {
    const vaults = await vestingContract.vaults(
      walletAddress,
      vaultsLength.sub(1)
    );
    const endDate = vaults.releaseFrom.add(
      vaults.initAmount
        .div(vaults.tokensAmountPerInterval)
        .mul(vaults.releaseInterval)
    );
    VestingInfoArr.endDate = endDate;
  }
  return VestingInfoArr;
};

export const fetchVaultsInfo = async (walletAddress: string) => {
  const vestingContract = new ethers.Contract(
    config.VESTING_ADDRESS,
    VestingAbi,
    AppRpcProvider
  );
  const vaultsLength = await vestingContract.vaultsLength(walletAddress);
  if (!vaultsLength.eq(0x00)) {
    const vaultsInfoPromises = [];
    for (let i = 0; i < vaultsLength?.toNumber(); i += 1) {
      vaultsInfoPromises.push(vestingContract.vaults(walletAddress, i));
    }
    const vaultsInfos = await Promise.all(vaultsInfoPromises);
    return vaultsInfos;
  }
  return [];
};

export const ClaimVesting = async (
  address: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const vestingContract = new ethers.Contract(
    config.VESTING_ADDRESS,
    VestingAbi,
    signer
  );

  const pages = await vestingContract.vaultsPages(address);

  if (pages.toNumber() === 0) {
    throw new Error(`Nothing to withdraw from vesting contract`);
  }
  const txs = [];
  for (let i = 0; i < pages.toNumber(); i += 1) {
    const tx = await vestingContract.withdraw(i, {});
    txs.push(tx.wait());
  }

  const txHashes = (await Promise.all(txs)).map((tx) => tx.transactionHash);
  return txHashes;
};

export const unlockTokens = async (
  address: string,
  index: number,
  amount: BigNumberish,
  signer: ethers.providers.JsonRpcSigner
) => {
  const vestingContract = new ethers.Contract(
    config.VESTING_ADDRESS,
    VestingAbi,
    signer
  );
  await vestingContract
    .connect(signer)
    .unlockTokensOnDemand(address, amount, index);
};
