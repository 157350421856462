import styled from "styled-components";
import { device } from "../../style";
import { Button } from "..";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 24px;
  align-items: center;
  padding: 8px 16px 16px 16px;
  @media ${device.tablet} {
    padding: 12px 28px 28px 28px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const ConfirmButton = styled(Button.Primary)`
  width: 100%;
  @media ${device.tablet} {
    width: 50%;
  }
`;

export const CancelButton = styled(Button.Secondary)`
  width: 100%;
  @media ${device.tablet} {
    width: 50%;
  }
`;
