import { BigNumber, BigNumberish, ethers } from "ethers";
import { config } from "../config";
import { AppRpcProvider } from "../utils";
import { PoolsContract } from "./contracts";
import { erc20abi, poolsContractAbi, soilTokenAbi } from "./contracts/abi";
import { tokenService } from "./token.service";

const getFeeBalance = async () => PoolsContract.feeBalance();

const getPoolContractFees = async () => PoolsContract.poolsContractFee();

const changePoolsContractFee = async (
  fee: BigNumber,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).changePoolsContractFee(
    fee
  );
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdrawFees = async (
  address: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).withdrawFees(address);
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const deposit = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  amount: BigNumber,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  await tokenService.approveIfNeeded(
    config.USDC_TOKEN_ADDRESS,
    PoolsContract.address,
    amount,
    signer
  );

  const { wait } = await PoolsContract.connect(signer).deposit(
    poolId,
    level,
    amount,
    deadline,
    signature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const withdraw = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  amount: BigNumber | string,
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).withdraw(
    poolId,
    level,
    amount,
    rewards,
    soilRewards,
    deadline,
    claimSignature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const claimRewards = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).claimRewards(
    poolId,
    level,
    rewards,
    soilRewards,
    deadline,
    claimSignature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const upgradePool = async (
  poolId: Pool["id"],
  level: PoolLevel["level"],
  upgradeToLevel: PoolLevel["level"],
  rewards: BigNumber | string,
  soilRewards: BigNumber | string,
  deadline: string,
  claimSignature: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(
    signer
  ).upgradeToPoolWithHigherYield(
    poolId,
    level,
    upgradeToLevel,
    rewards,
    soilRewards,
    deadline,
    claimSignature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const createPool = async (
  signer: ethers.providers.JsonRpcSigner,
  name: string,
  startTime: BigNumberish,
  endTime: BigNumberish,
  softCap: BigNumberish,
  hardCap: BigNumberish,
  periods: BigNumberish[],
  fixedAPRs: BigNumberish[]
) => {
  const { wait } = await PoolsContract.connect(signer).createPool(
    name,
    startTime,
    endTime,
    softCap,
    hardCap,
    periods,
    fixedAPRs,
    { gasLimit: "1000000" }
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const updatePool = async (
  signer: ethers.providers.JsonRpcSigner,
  poolId: BigNumberish,
  startTime: BigNumberish,
  endTime: BigNumberish
) => {
  const { wait } = await PoolsContract.connect(signer).updatePool(
    poolId,
    startTime,
    endTime
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const getUserInfo = (user: string, poolId: BigNumberish, level: BigNumberish) =>
  PoolsContract.userInfo(user, poolId, level);

const withdrawFunds = async (
  target: string,
  amount: ethers.BigNumber,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await PoolsContract.connect(signer).withdrawFunds(
    target,
    amount
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const changeSoilWithdrawLimit = async (
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).changeSoilRewardsLimit(
    newLimit,
    { gasLimit: "1000000" }
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeUsdcWithdrawLimit = async (
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).changeTokenRewardsLimit(
    newLimit,
    { gasLimit: "1000000" }
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const initPoolsContract = () =>
  new ethers.Contract(
    config.POOLS_CONTRACT_ADDRESS,
    poolsContractAbi,
    AppRpcProvider
  );

const limitOfSoilRewards = async () => {
  const poolsContract = initPoolsContract();
  const soilLimit = await poolsContract.limitOfSoilRewards();
  return soilLimit;
};

const limitOfUsdcRewards = async () => {
  const poolsContract = initPoolsContract();
  const usdcLimit = await poolsContract.limitOfTokenRewards();
  return usdcLimit;
};

const getSoilRewards = async () => {
  const poolsContract = initPoolsContract();
  const soilRewards = await poolsContract.amountOfSoilRewards();
  return soilRewards;
};

const getUsdcRewards = async () => {
  const poolsContract = initPoolsContract();
  const usdcRewards = await poolsContract.amountOfTokenRewards();
  return usdcRewards;
};

const changeAgreementToBeRewardsHolder = async (
  signer: ethers.providers.JsonRpcSigner
) => {
  const SoilTokenContract = new ethers.Contract(
    config.SOIL_TOKEN_ADDRESS,
    soilTokenAbi,
    signer
  );

  const UsdcTokenContract = new ethers.Contract(
    config.USDC_TOKEN_ADDRESS,
    erc20abi,
    signer
  );

  await SoilTokenContract.approve(
    config.POOLS_CONTRACT_ADDRESS,
    ethers.constants.MaxUint256
  );
  await UsdcTokenContract.approve(
    config.POOLS_CONTRACT_ADDRESS,
    ethers.constants.MaxUint256
  );

  const { wait } = await PoolsContract.connect(
    signer
  ).changeAgreementToBeRewardsHolder();

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const sendUsdcRewardsToContract = async (
  signer: ethers.providers.JsonRpcSigner,
  from: string,
  amount: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).sendTokenRewards(
    from,
    amount
  );

  const tx = await wait(1);
  return tx;
};

const sendSoilRewardsToContract = async (
  signer: ethers.providers.JsonRpcSigner,
  from: string,
  amount: BigNumber
) => {
  const { wait } = await PoolsContract.connect(signer).sendSoilRewards(
    from,
    amount
  );

  const tx = await wait(1);
  return tx;
};

export const poolsContractService = {
  getFeeBalance,
  getPoolContractFees,
  changePoolsContractFee,
  withdrawFees,
  deposit,
  withdraw,
  claimRewards,
  upgradePool,
  createPool,
  getUserInfo,
  updatePool,
  withdrawFunds,
  changeSoilWithdrawLimit,
  changeUsdcWithdrawLimit,
  limitOfSoilRewards,
  limitOfUsdcRewards,
  getSoilRewards,
  getUsdcRewards,
  changeAgreementToBeRewardsHolder,
  sendSoilRewardsToContract,
  sendUsdcRewardsToContract,
};
