import { Button, ConnectWallet, Modal } from "../../components";
import { useBoolean } from "../../hooks";

type ConnectWalletButtonProps = {
  small?: boolean;
};

export const ConnectWalletButton = ({ small }: ConnectWalletButtonProps) => {
  const [isModalVisible, openModal, closeModal] = useBoolean();

  return (
    <>
      <Button.Primary type="button" bigSize={!small} onClick={openModal}>
        Connect Wallet
      </Button.Primary>
      <Modal
        title="Connect Wallet"
        isOpen={isModalVisible}
        onClose={closeModal}
        withOutsideClick
      >
        <ConnectWallet handleModalClose={closeModal} />
      </Modal>
    </>
  );
};
