import styled from "styled-components";
import { device } from "../../style";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  background-color: rgb(23, 11, 49);
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const RightSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  background: radial-gradient(
    221.33% 108.87% at 8.26% 85.83%,
    #1b032f 0%,
    #082436 48.44%,
    #000 100%
  );

  &::before {
    content: "";
    background-image: url("/bg-logo.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }
`;

export const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  margin-bottom: 0;
  position: relative;

  @media ${device.tablet} {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.background};
      width: 20px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) =>
        theme.colors.notification.text.primary};
      border-radius: 10px;

      &:hover {
        background-color: ${({ theme }) =>
          theme.colors.notification.text.secondary};
      }
    }
  }
`;

export const NavContainer = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;
