import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { QueryParams } from "../constants";

export const useSetSearchParam = (param: QueryParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const set = useCallback(
    (value?: string) => {
      setSearchParams((params) => {
        if (value) {
          params.set(param, value);
        } else {
          params.delete(param);
        }

        return params;
      });
    },
    [param, setSearchParams, searchParams]
  );

  return set;
};
