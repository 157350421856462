import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.notification.text.primary};
    border-radius: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 4px;
`;

const Head = styled.thead``;

const HeadRow = styled.tr`
  height: 48px;
  width: 100%;
  padding: 15px 16px;
`;

const HeadCell = styled.th`
  color: ${({ theme }) => theme.colors.text.grey};
  padding: 8px 20px;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.colors.box.background.primary};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.05em;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: right;
  &:nth-child(1) {
    text-align: left;
  }
`;

const Body = styled.tbody``;

const Row = styled.tr`
  background-color: ${({ theme }) => theme.colors.box.background.secondary};
  height: 48px;
  padding-top: 15px;
`;

const Cell = styled.td`
  padding: 0px 16px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.bodySmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.bodySmall.letterSpacing};
  text-align: right;
  &:nth-child(1) {
    text-align: left;
  }
`;

export const TableElements = {
  Wrapper,
  Table,
  Head,
  HeadRow,
  HeadCell,
  Body,
  Row,
  Cell,
};
