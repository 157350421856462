import { Controller } from "react-hook-form";
import { useAccount } from "wagmi";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { inputStableIcon } from "../../../../assets";
import { MBC, Button, CurrencyInput } from "../../../../components";
import { usePoolWithdrawHandler } from "./usePoolWithdrawHandler";
import { config } from "../../../../config";
import { ConnectWalletButton } from "../../../ConnectWalletButton";
import { formatNumber } from "../../../../utils";
import { PoolIcon } from "../../PoolIcon";
import { useEarlyWithdrawalCheck } from "./useEarlyWithdrawalCheck";

type WithdrawBodyProps = {
  poolLevel: PoolLevel;
  closeModal: () => void;
};

export const WithdrawBody = ({ poolLevel, closeModal }: WithdrawBodyProps) => {
  const { control, handleSubmit, inputValue, isWithdrawInProgress } =
    usePoolWithdrawHandler(poolLevel, closeModal);

  const { areFundsLocked, timeToUnlock } = useEarlyWithdrawalCheck(poolLevel);

  const { isConnected } = useAccount();

  const amountToGet = new BigNumber(inputValue || 0);

  const amountToGetToDisplay = amountToGet.isPositive()
    ? amountToGet
    : new BigNumber(0);

  const [isInputError, setIsInputError] = useState(false);
  const isButtonDisabled =
    isInputError ||
    amountToGet.isLessThanOrEqualTo(0) ||
    Boolean(areFundsLocked);

  return (
    <MBC.Form onSubmit={handleSubmit}>
      <MBC.LogoWrapper>
        <PoolIcon poolIcon={poolLevel.pool.icon} />
      </MBC.LogoWrapper>
      <MBC.HeaderWithLogoWrapper center>
        <MBC.InfoBox>
          <MBC.InfoRow as="li">{poolLevel.name}</MBC.InfoRow>
        </MBC.InfoBox>
      </MBC.HeaderWithLogoWrapper>
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <CurrencyInput
            {...field}
            label="USDC"
            logo={inputStableIcon}
            decimals={config.USDC_TOKEN_DECIMALS}
            available={poolLevel.deposit}
            onErrorChange={setIsInputError}
          />
        )}
      />
      {/* {poolLevel.unlockRewardsAfter &&
        dayjs(poolLevel.unlockRewardsAfter).isAfter(dayjs()) && (
          <div className="flex items-center gap-3">
            <Controller
              control={control}
              name="unlockRewardsAfter"
              rules={{ required: true }}
              render={({ field: { onChange, ref, name } }) => (
                <Checkbox
                  onClick={onChange}
                  ref={ref}
                  className="mr-2"
                  name={name}
                  id={name}
                />
              )}
            />
            <Typography.BodySmall className="opacity-90">
              I have been informed that the funds will be disbursed without
              interest before{" "}
              {dayjs(poolLevel.unlockRewardsAfter).format("DD.MM.YYYY HH:mm")}.
            </Typography.BodySmall>
          </div>
        )} */}
      <MBC.InfoBox>
        <MBC.InfoRow as="li">
          You will get
          <span>{formatNumber(amountToGetToDisplay.toString(), 6)} USDC</span>
        </MBC.InfoRow>
        {areFundsLocked && (
          <MBC.InfoRow as="li">
            Your funds are locked for {timeToUnlock}.{" "}
          </MBC.InfoRow>
        )}
      </MBC.InfoBox>
      {isConnected ? (
        <Button.Primary
          type="submit"
          bigSize
          disabled={isButtonDisabled || isWithdrawInProgress}
        >
          Withdraw
        </Button.Primary>
      ) : (
        <ConnectWalletButton />
      )}
    </MBC.Form>
  );
};
