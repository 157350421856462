import styled from "styled-components";
import { ThemeType } from "../../style/theme";

const handleColor = (theme: ThemeType, status: string) => {
  switch (status) {
    case "approved":
      return theme.colors.success;
    case "rejected":
      return theme.colors.error;
    case "pending":
      return theme.colors.greyDark;
    case "started":
      return theme.colors.greyDark;
    default:
      return theme.colors.text.primary;
  }
};
const handleBGC = (theme: ThemeType, status: string) => {
  switch (status) {
    case "approved":
      return theme.colors.walletStatus.backgroundOn;
    case "rejected":
      return theme.colors.walletStatus.backgroundReject;
    case "pending":
      return theme.colors.walletStatus.backgroundPending;
    case "started":
      return theme.colors.walletStatus.backgroundPending;
    default:
      return theme.colors.modal.background;
  }
};

export const StatusParagraph = styled.p<{ status: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  padding: 4px;
  font-family: Arial;
  font-size: ${({ theme }) => theme.fontStyles.walletStatus.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.walletStatus.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.walletStatus.lineHeight};
  border-radius: 4px;
  color: ${({ theme, status }) => handleColor(theme, status)};
  background-color: ${({ theme, status }) => handleBGC(theme, status)};
`;
