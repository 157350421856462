import qs from "qs";
import { apiCallWithAuthToken } from "./config";

type FilteredKeys = "revealTime" | "createdAt" | "types";

type GetNotificationFilter = Partial<Record<FilteredKeys, string>>;

type GetNotificationQueryParams = {
  page?: number;
  limit?: number;
  sortBy?: string;
  filter?: GetNotificationFilter;
};

export type NotificationType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
};

export type CreateNotification = {
  revealTime: Date;
  title: string;
  message: string;
  types: string[];
  recipientType: "ALL" | "ALL_STAKE_HOLDERS" | "ALL_POOL_HOLDERS" | "USERS";
  users?: number[];
};

export type NotificationData = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  revealTime: Date;
  title: string;
  message: string;
  types: NotificationType[];
  recipientType: string;
};

type GetNotificationsResponse = {
  data: NotificationData[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
};

export type NotificationUpdateData = {
  title: string;
  message: string;
};

export const getNotificationAdmin = (params: GetNotificationQueryParams) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetNotificationsResponse>(
    `notification/admin?${queryString}`
  );
};
export const addNotification = (obj: CreateNotification) =>
  apiCallWithAuthToken.post<NotificationData>("notification", obj);

export const updateNotification = (id: number, data: NotificationUpdateData) =>
  apiCallWithAuthToken.put(`notification/${id}`, data);

export const deleteNotification = (id: number) =>
  apiCallWithAuthToken.delete(`notification/${id}`);

export const getNotifications = (params: GetQueryParams<FilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetNotificationsResponse>(
    `/notification?${queryString}`
  );
};
