import { smallLogo } from "../../assets";
import { config } from "../../config";
import { formatNumberSuffix } from "../../utils";
import { Typography } from "../Typography/Typography";
import * as S from "./ValueBars.styled";

type SoilBarProps = {
  value: string | number;
  bordered?: boolean;
};

export const SoilBar = ({ value, bordered }: SoilBarProps) => (
  <S.SoilBarWrapper bordered={bordered}>
    <S.CoinSoilIconWrapper>
      <S.SoilCoinLogo src={smallLogo} alt="small logo" />
    </S.CoinSoilIconWrapper>
    <Typography.BarSoilLabel fontWeight={700}>
      {config.PROJECT_TOKEN}
    </Typography.BarSoilLabel>
    <Typography.BarValue fontWeight={700}>
      {value && Number(value) > 0 ? formatNumberSuffix(Number(value)) : "-"}
    </Typography.BarValue>
  </S.SoilBarWrapper>
);
