import { Navigate, Outlet } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useAppSelector } from "../../redux/hooks";
import { isAdminRole } from "../../utils";

export const AdminRoute = () => {
  const roles = useAppSelector((state) => state?.user?.userData?.roles);
  const isAdmin = isAdminRole(roles);

  return isAdmin ? (
    <Outlet />
  ) : (
    <Navigate
      to={routes.userPanel.dashboard}
      state={{ error: "You must be an admin to access this page" }}
    />
  );
};
