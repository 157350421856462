import styled from "styled-components";
import { device } from "../../style";

export type DotsBtnProps = {
  isDropdownOpen: boolean;
};

export type DropdownLiProps = {
  disabled?: boolean;
};

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: static;

  @media ${device.tablet} {
    position: relative;
  }
`;

export const DotsBtn = styled.div<DotsBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 8px 14px 8px 14px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid ${({ theme }) => theme.colors.button.secondary.border};
  z-index: ${({ theme }) => theme.zIndex.dropdown.dots};

  :hover {
    background: ${({ theme }) =>
      `${theme.colors.button.secondary.hover}, ${theme.colors.button.secondary.border}`};
  }

  :active {
    background: ${({ theme }) =>
      `${theme.colors.button.secondary.clicked}, ${theme.colors.button.secondary.border}`};
  }

  :focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.button.secondary.border};
  }
`;

export const DropdownUl = styled.ul`
  position: fixed;
  top: auto;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
  background: ${({ theme }) => theme.colors.dropdown.ulBackground};
  box-shadow: ${({ theme }) => theme.colors.dropdown.boxShadow};
  border-radius: 8px;
  padding: 8px;
  z-index: ${({ theme }) => theme.zIndex.dropdown.menu};

  @media ${device.tablet} {
    position: absolute;
    left: auto;
    top: 32px;
    bottom: auto;
    width: 171px;
    margin: 6px 0 0 0;
  }
`;

export const DropdownLi = styled.li<DropdownLiProps>`
  background: ${({ theme }) => theme.colors.dropdown.liBackground};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  padding: 12px 0;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  p {
    color: ${({ theme, disabled }) =>
      disabled ? `${theme.colors.background}4D` : theme.colors.background};
  }

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.dropdown.liBackground : "rgba(32, 33, 36, 0.09)"};
  }

  @media ${device.tablet} {
    padding: 7px 0;
  }
`;
