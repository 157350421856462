import styled, { css } from "styled-components";
import { Typography } from "../../../../../components";
import { device } from "../../../../../style";

export const NavItem = styled(Typography.HeaderSmall)<{ disabled?: boolean }>`
  text-align: right;
  width: 153px;
  position: relative;
  font-weight: 500;
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  padding-right: 37px;
  min-height: 47px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  p {
    font-size: 24px;

    @media ${device.laptop} {
      font-size: 16px;
    }

    @media ${device.laptopM} {
      font-size: 18px;
    }
  }

  span {
    font-size: 24px;

    @media ${device.laptop} {
      font-size: 16px;
    }

    @media ${device.laptopM} {
      font-size: 18px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;
    font-size: 24px;
    width: 100%;
    display: block;
    height: 100%;

    @media ${device.laptop} {
      font-size: 16px;
    }

    @media ${device.laptopM} {
      font-size: 18px;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};

      //TODO DELETE BELOW FOR NORMAL VERSION
      ${({ disabled }) =>
        disabled &&
        css`
          color: ${({ theme }) => theme.colors.white};
        `}
    }

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.3;
        pointer-events: none;

        p {
          cursor: wait; //TODO DELETE FOR NORMAL VERSION
          pointer-events: all;
        }
      `}
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};

      ${({ disabled }) =>
        disabled &&
        css`
          color: ${({ theme }) => theme.colors.white};
        `}
    }

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.3;
        cursor: wait;
      `}
  }

  a.active {
    color: #26a677;
    opacity: 1;

    ::before {
      content: "";
      display: none;
      display: block;
      width: 2px;
      height: 47px;
      background-color: #26a677;
      position: absolute;
      top: 0;
      right: 2px;

      @media ${device.laptop} {
        content: "";
        display: block;
        width: 2px;
        height: 47px;
        position: absolute;
        top: 0;
        right: -13px;
        transform: translateY(-13px);
      }

      @media ${device.laptopM} {
        content: "";
        display: block;
        width: 2px;
        height: 47px;
        position: absolute;
        top: 0;
        right: -42px;
        transform: translateY(-13px);
      }
    }

    ::after {
      content: "";
      display: none;
      display: block;
      width: 100%;
      height: 47px;
      background-color: #26a67733;
      position: absolute;
      top: 0;
      right: 2px;

      @media ${device.laptop} {
        display: none;
      }
    }
  }

  @media ${device.laptop} {
    text-align: left;
    min-height: unset;
    justify-content: flex-start;
  }
`;
