import React, { ComponentPropsWithoutRef, forwardRef } from "react";
import * as S from "./CustomInput.styled";

type CustomInputProps = {
  error?: string | undefined;
  clicked: () => void;
} & ComponentPropsWithoutRef<"input">;

export const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ ...rest }, ref) => {
    const { error, clicked } = rest;
    return (
      <S.InputWrapper>
        <S.Select ref={ref} {...rest} error={error} />
        <S.AddButton type="button" onClick={clicked} />
        {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
      </S.InputWrapper>
    );
  }
);
