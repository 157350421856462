import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux";
import { getUserData, logoutUser } from "../redux/features/userSlice";

export const useAuthCheck = () => {
  let accessToken: string | null = null;
  if (navigator.cookieEnabled) {
    accessToken = localStorage.getItem("accessToken");
  }
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const preLogged = useAppSelector((state) => state.user.preLogged);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((isLogged && !preLogged) || (accessToken && !preLogged)) {
      dispatch(getUserData());
    }
  }, [accessToken]);

  useEffect(() => {
    if (isLogged && !accessToken) {
      dispatch(logoutUser());
    }
  }, [accessToken]);
};
