import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { CookiesProvider } from "react-cookie";
import { UserOptions, cookie3Analytics } from "@cookie3/analytics";
import { GlobalStyle, theme } from "./style";
import { Layout } from "./template";
import { AnimatedRoutes } from "./components";
import { useAuthCheck, useReduxActionInterval } from "./hooks";
import { actions } from "./redux";
import { WalletProvider } from "./context/WagmiProvider";
import { config as appConfig } from "./config";
import { Cookie3Provider } from "./context/Cookie3Provider";

function App() {
  useAuthCheck();
  useReduxActionInterval(actions.token.getTokenPrice, 30_000);

  const config: UserOptions = {
    siteId: Number(appConfig.COOKIE3_CLIENT_ID),
    linkTracking: true,
  };

  const analytics = cookie3Analytics(config);

  return (
    <AnimatePresence>
      <Cookie3Provider value={analytics}>
        <CookiesProvider>
          <WalletProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <BrowserRouter>
                <Layout>
                  <HelmetProvider>
                    <AnimatedRoutes />
                  </HelmetProvider>
                </Layout>
              </BrowserRouter>
            </ThemeProvider>
          </WalletProvider>
        </CookiesProvider>
      </Cookie3Provider>
    </AnimatePresence>
  );
}

export default App;
