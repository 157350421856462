// ("page/:id", "2") => "page/2"
export const applyRouteParam = (route: string, param: string) => {
  const isParamOperator = route.indexOf(":");

  if (!isParamOperator) {
    throw new Error(`[${route}] - Route without param`);
  }

  return route.split(":")[0] + param;
};
