import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { api } from "../../../../api";
import { config } from "../../../../config";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { openTfaModal } from "../../../../redux/features/uiSlice";
import { setCurrentTfaAction } from "../../../../redux/features/userSlice";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";
import { handleBlockchainPromiseInToast } from "../../../../toasts/handleBlockchainPromiseInToast";
import { useEthersSigner } from "../../../../utils";

type WithdrawFormType = {
  amount: string;
  unlockRewardsAfter?: boolean;
};

export const usePoolWithdrawHandler = (
  poolLevel: PoolLevel,
  onSuccess: () => void
) => {
  const signer = useEthersSigner();

  const { control, handleSubmit, watch } = useForm<WithdrawFormType>();

  const [isWithdrawInProgress, setWithdrawInProgress] = useState(false);
  const [tfaAmount, setTfaAmount] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isWithdrawPoolAuthenticated } = tfaAuthenticatedForAction;

  const onWithdrawSubmit = async (amount: string) => {
    setWithdrawInProgress(true);

    try {
      if (!signer) {
        throw new Error("Wallet is not connected");
      }

      if (!poolLevel.usdcReward || !poolLevel.soilReward) throw new Error("");

      const rewards = BigNumber.from(poolLevel.pool.balance).gte(
        BigNumber.from(poolLevel.pool.softCap)
      )
        ? poolLevel.usdcReward
        : "0";
      const soilRewards = BigNumber.from(poolLevel.pool.balance).gte(
        BigNumber.from(poolLevel.pool.softCap)
      )
        ? poolLevel.soilReward
        : "0";

      const {
        data: { signature, deadline },
      } = await api.pools.getClaimRewardsSignature({
        poolId: poolLevel.pool.poolId,
        level: poolLevel.level,
        rewards,
        soilRewards,
        withWithdraw: true,
      });

      const parsedAmount = ethers.utils.parseUnits(
        amount,
        config.USDC_TOKEN_DECIMALS
      );

      const withdrawTx = poolsContractService.withdraw(
        poolLevel.pool.poolId,
        poolLevel.level,
        parsedAmount,
        rewards,
        soilRewards,
        deadline,
        signature,
        signer
      );

      const title = `Withdrawing ${amount} ${config.STABLE_TOKEN} from ${poolLevel.name}`;

      await handleBlockchainPromiseInToast(withdrawTx, {
        pending: {
          title,
          message: "Your request is in progress...",
        },
        error: {
          title,
          message:
            "Your request has been finished unsuccessful. Try again later",
        },
        success: {
          title,
          message:
            "The transaction has been finished successful. You can check details on the block explorer by click the button below.",
        },
      });

      onSuccess();
    } catch (e: unknown) {
      toast.errorHandler(e, "Withdrawal failed!");
      setWithdrawInProgress(false);
    }
  };

  const withdrawSubmit = async ({ amount }: WithdrawFormType) => {
    if (tfaEnabled) {
      setTfaAmount(amount);
      dispatch(setCurrentTfaAction("poolWithdraw"));
      dispatch(openTfaModal());
    } else {
      onWithdrawSubmit(amount);
    }
  };

  useEffect(() => {
    if (isWithdrawPoolAuthenticated && tfaAmount) {
      onWithdrawSubmit(tfaAmount);
    }
  }, [isWithdrawPoolAuthenticated, tfaAmount]);

  const inputValue = watch("amount");
  const checkBoxValue = watch("unlockRewardsAfter");
  return {
    control,
    handleSubmit: handleSubmit(withdrawSubmit),
    inputValue,
    isWithdrawInProgress,
    checkBoxValue,
  };
};
