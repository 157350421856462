import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./Disclaimer.styled";
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { Typography } from "../Typography/Typography";
import { useAppSelector } from "../../redux";
import { ConnectWallet } from "../ConnectWallet/ConnectWallet";
import { Modal } from "../Modal/Modal";
import { useBoolean } from "../../hooks";
import { config } from "../../config";

export const Disclaimer = () => {
  const [isModalVisible, openModal, closeModal] = useBoolean();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isLogged, userData } = useAppSelector((state) => state.user);
  const [isExpanded, setIsExpanded] = useState(
    isLogged
      ? Boolean(!localStorage.getItem(`closedByUser-${userData.id}`))
      : true
  );
  // const [hideText, setHideText] = useState(false);

  const animateVariants = {
    expand: { y: "100%" },
    hide: { y: "0" },
  };

  const toggleDisclaimerExpand = () => {
    if (isExpanded && isLogged) {
      localStorage.setItem(`closedByUser-${userData.id}`, "closedByUser");
      setIsExpanded(false);
    }
    if (!isExpanded && isLogged) {
      setIsExpanded(true);
      localStorage.removeItem(`closedByUser-${userData.id}`);
    }
    if (!isLogged) setIsExpanded(!isExpanded);
  };

  const dashboardClickHereHandler = () => {
    if (isLogged) {
      return navigate("/kyc");
    }
    return openModal();
  };

  const generateDisclaimerText = (path: string) => {
    switch (path) {
      case "/dashboard":
        return (
          <Typography.Body opacity={0.8}>
            Welcome to Soil! You can use the dashboard to monitor the most
            important indicators, such as the total sum of the loans granted to
            Soil Ltd and your investment value. We encourage you to explore our
            protocol. If you want to enter into a loan agreement with Soil Ltd,
            click on the &apos;Earn&apos; tab on the website. Remember that all
            interactions are restricted to a user who has passed the KYC
            verification and wallet vetting. To get verified and vetted, please
            proceed with the verification process.{" "}
            <button
              onClick={() => dashboardClickHereHandler()}
              type="button"
              style={{ color: "#3366CC", textDecoration: " underline" }}
            >
              click here
            </button>
          </Typography.Body>
        );
        break;
      case "/grow":
        return (
          <Typography.Body opacity={0.8}>
            You can grow your $SOIL to boost your yield and earn $SOIL tokens.
            Please remember that saving may be associated with a certain level
            of risk, e.g., in connection with loss of liquidity. You should
            familiarize yourself with how saving works before using this
            service. You can learn more about terms of our saving service by
            reading our{" "}
            <button
              onClick={() => navigate(`${config.TERMS_SERVICE}`)}
              type="button"
              style={{ color: "#3366CC", textDecoration: " underline" }}
            >
              Terms of Service.
            </button>
          </Typography.Body>
        );
        break;
      case "/earn":
        return (
          <Typography.Body opacity={0.8}>
            You can browse loan types sorted by their terms. The terms of loans
            are categorized by our (Soil) projects. You can select under what
            terms do you wish to enter into loan agreement with Soil by clicking
            EARN button
          </Typography.Body>
        );
        break;
      case "/swap":
        return (
          <Typography.Body opacity={0.8}>
            On this page you can swap your $SOIL with USDC and vice versa. Swap
            services are provided by Uniswap, an external service provider. You
            can learn more about swap services by reading our{" "}
            <button
              onClick={() => navigate(`${config.TERMS_SERVICE}`)}
              type="button"
              style={{ color: "#3366CC", textDecoration: " underline" }}
            >
              Terms of Service.
            </button>
          </Typography.Body>
        );
        break;
      default:
        return (
          <Typography.Body opacity={0.8}>
            On this page you can read about our (Soil) project. We will be using
            our own assets and funds to make a profit. The description of this
            project is not a description of investment portfolio management
            services. We are not executing any investment orders on behalf of
            third parties or holding any assets of third parties
          </Typography.Body>
        );
        break;
    }
  };

  useEffect(() => {
    if (isLogged) {
      setIsExpanded(
        Boolean(!localStorage.getItem(`closedByUser-${userData.id}`))
      );
    }
  }, [isLogged, userData, pathname]);

  return (
    <>
      <S.DisclaimerWrapper>
        <S.DisclaimerContainer
          animate={isExpanded ? "hide" : "expand"}
          // onAnimationComplete={() => {
          //   if (!isExpanded) {
          //     setHideText(true);
          //   }
          // }}
          variants={animateVariants}
          transition={{ type: "tween", duration: 0.15 }}
        >
          <S.ToggleExpandButton
            isExpanded={isExpanded}
            onClick={toggleDisclaimerExpand}
          >
            <Typography.BodySmall opacity={0.6} fontWeight={400}>
              {isExpanded ? "hide disclaimer" : "show disclaimer"}
            </Typography.BodySmall>
            <ArrowDown />
          </S.ToggleExpandButton>
          {isExpanded ? generateDisclaimerText(pathname) : null}
        </S.DisclaimerContainer>
      </S.DisclaimerWrapper>
      <Modal
        title="Connect Wallet"
        isOpen={isModalVisible}
        onClose={closeModal}
        withOutsideClick
      >
        <ConnectWallet handleModalClose={closeModal} />
      </Modal>
    </>
  );
};
