import BigNumber from "bignumber.js";

// Get the number of digits after the Decimal
export const afterDecimals = (number: number | string) => {
  if (Number.isInteger(number)) {
    return 0;
  }
  const stringAfterDecimal = new BigNumber(number).toString().split(".")[1];

  return stringAfterDecimal?.length;
};
