import styled from "styled-components";
import { Typography } from "../../../../components";
import { device } from "../../../../style";
import { Theme } from "../../../../@types/theme";

const handleBorderType = (
  theme: Theme,
  error: string | undefined,
  warning: string | undefined,
  defaultColor: string
) => {
  if (error) return theme.colors.error;
  if (warning) return theme.colors.warning;
  return defaultColor;
};

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div<{
  error?: string;
  title?: string;
  warning?: string;
  edit?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: ${(props) => (props.edit ? "250px" : "150px")};

  background-color: ${({ theme }) => theme.colors.greyDark01};
  border: 1px solid
    ${({ theme, error, warning }) =>
      handleBorderType(theme, error, warning, theme.colors.greyDark)};
  border-radius: 2px;
  gap: 10px;

  padding: 18px 20px 18px 20px;
  transition: 0.3s;

  &:hover {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.white033)};
  }
  &:active {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
  }

  &:focus-within {
    border: 1px solid
      ${({ theme, error, warning }) =>
        handleBorderType(theme, error, warning, theme.colors.primary)};
    outline: 3px solid
      ${({ theme, error, warning }) =>
        error || warning
          ? "none"
          : handleBorderType(theme, error, warning, theme.colors.primary)};
  }

  @media ${device.tablet} {
    height: 259px;
  }
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.description.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.description.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.description.letterSpacing};
  color: ${({ theme }) => theme.colors.grey};
  opacity: 0.6;
  text-transform: uppercase;
`;

export const Input = styled.textarea`
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  resize: none;

  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontStyles.headerSmall.fontSize};
  line-height: ${({ theme }) => theme.fontStyles.headerSmall.lineHeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.headerSmall.letterSpacing};
  color: ${({ theme }) => theme.colors.grey};
  text-align: left;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  gap: 4px;
  height: 16px;
`;

export const WarningMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.warning};
`;

export const ErrorMessage = styled(Typography.BodySmall)`
  color: ${({ theme }) => theme.colors.error};
`;
