import React, { useEffect } from "react";

export const useDebouncedEffect = (
  effect: React.EffectCallback,
  deps: React.DependencyList,
  delay = 2000
) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => effect(), delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [...(deps || []), delay]);
};
