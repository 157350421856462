import { useId, useRef } from "react";
import { usePopper } from "react-popper";
import { AnimatePresence } from "framer-motion";
import * as S from "./Dropdown.styled";
import { ReactComponent as Dots } from "../../assets/dots.svg";
import { useBoolean, useDetectOutsideClick } from "../../hooks";
import { DropdownList } from "./DropdownList";

type DropdownElement = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

export type DropdownProps = {
  elements: DropdownElement[];
};

export const Dropdown = ({ elements }: DropdownProps) => {
  const [isDropdownOpen, , closeDropdown, toggleDropdown] = useBoolean(false);

  const id = useId();

  const dropdownRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const excludeElements = document.getElementsByClassName(id);

  const { styles, attributes, update } = usePopper(
    buttonRef.current,
    dropdownRef.current,
    {
      placement: "bottom-end",
    }
  );

  useDetectOutsideClick(dropdownRef, closeDropdown, excludeElements);
  return (
    <S.DropdownMenu className={id}>
      <AnimatePresence>
        <S.DotsBtn
          key="dots"
          className={id}
          data-testid="dots-btn"
          onClick={async (e) => {
            e.stopPropagation();
            if (update) {
              await update();
            }
            toggleDropdown();
          }}
          isDropdownOpen={isDropdownOpen}
          ref={buttonRef}
        >
          <div>
            <Dots />
          </div>
        </S.DotsBtn>
        <DropdownList
          key="list"
          isVisible={isDropdownOpen}
          elements={elements}
          style={styles.popper}
          {...attributes.popper}
          ref={dropdownRef}
        />
      </AnimatePresence>
    </S.DropdownMenu>
  );
};
