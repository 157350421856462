import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "../../../style";
import { closeButton } from "../../../assets";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  @media ${device.tablet} {
    max-width: 250px;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  overflow-y: hidden;
`;

export const ListItem = styled(motion.li)`
  background-color: rgba(255, 255, 255, 0.02);
  padding: 8px 28px 8px 6px;
  border-radius: 8px;
  max-height: 31px;
  position: relative;
  p {
    display: inline-block;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 32px;
  text-decoration: none;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: transparent;
  background-image: url(${closeButton});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  transition: all 0.2s;
  :hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;
