import { BigNumber, ethers } from "ethers";
import { config } from "../config";
import { AppRpcProvider, compareAddresses } from "../utils";
import { SoilToken } from "./contracts";
import { erc20abi, usdcTokenAbi } from "./contracts/abi";

const approveIfNeeded = async (
  tokenAddress: string,
  spender: string,
  amount: BigNumber,
  signer: ethers.Signer
) => {
  const TokenContract = new ethers.Contract(tokenAddress, erc20abi, signer);

  const signerAddress = await signer.getAddress();

  const allowance = await TokenContract.allowance(signerAddress, spender);

  if (amount.gt(allowance)) {
    const approveAmount = compareAddresses(
      tokenAddress,
      config.SOIL_TOKEN_ADDRESS
    )
      ? ethers.constants.MaxUint256
      : amount;
    const approveTx = await TokenContract.approve(spender, approveAmount);
    await approveTx.wait(1);
  }
};

const tokenBalance = async (
  tokenAddress: string,
  holderAddress: string
): Promise<ethers.BigNumber> => {
  const TokenContract = new ethers.Contract(
    tokenAddress,
    erc20abi,
    AppRpcProvider
  );

  const balance = await TokenContract.balanceOf(holderAddress);

  return balance;
};

const totalSupply = async (tokenAddress: string): Promise<ethers.BigNumber> => {
  const TokenContract = new ethers.Contract(
    tokenAddress,
    erc20abi,
    AppRpcProvider
  );

  const supply = await TokenContract.totalSupply();

  return supply;
};

const getSoilAllowance = async (holder: string, spender: string) =>
  SoilToken.allowance(holder, spender);

const getUsdcAllowance = async (holder: string, spender: string) => {
  const TokenContract = new ethers.Contract(
    config.USDC_TOKEN_ADDRESS,
    usdcTokenAbi,
    AppRpcProvider
  );
  return TokenContract.allowance(holder, spender);
};

export const tokenService = {
  approveIfNeeded,
  tokenBalance,
  totalSupply,
  getSoilAllowance,
  getUsdcAllowance,
};
