import { ethers } from "ethers";
import { config } from "../config";

export const AppRpcProvider = new ethers.providers.FallbackProvider(
  [
    {
      provider: new ethers.providers.JsonRpcProvider(config.JSON_RPC_URL),
      priority: 3,
      weight: 5,
    },
    // {
    //   provider: new ethers.providers.JsonRpcProvider(
    //     `https://polygon-${
    //       config.NODE_ENV === "dev" ? "mumbai" : "mainnet"
    //     }.g.alchemy.com/v2/${config.ALCHEMY_ID}`
    //   ),
    //   priority: 1,
    //   weight: 15,
    // },
    // {
    //   provider: new ethers.providers.AlchemyProvider(
    //     config.NETWORK_ID,
    //     config.ALCHEMY_ID
    //   ),
    //   priority: 2,
    //   weight: 10,
    // },
  ],
  1
);

export const AppRpcBatchProvider = new ethers.providers.JsonRpcBatchProvider(
  config.JSON_RPC_URL
);
