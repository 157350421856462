export const VestingAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_tokenSpender",
        type: "address",
      },
      {
        internalType: "address",
        name: "_protocolSettings",
        type: "address",
      },
      {
        internalType: "address",
        name: "_techToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "_techExchangeOwner",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newTokenSpender",
        type: "address",
      },
    ],
    name: "ChangeTokenSpender",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "vaultIndex",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "UnlockedTokensOnDemand",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "releaseFrom",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "releaseInterval",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokensAmountPerInterval",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "vaultIndex",
        type: "uint256",
      },
    ],
    name: "VaultDeposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "vaultIndex",
        type: "uint256",
      },
    ],
    name: "VaultWithdraw",
    type: "event",
  },
  {
    inputs: [],
    name: "PAGE_LIMIT",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newTokenSpender",
        type: "address",
      },
    ],
    name: "changeTokenSpender",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "releaseFrom",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "releaseInterval",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensAmountPerInterval",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isWithdrawOnDemandAllowed",
        type: "bool",
      },
    ],
    name: "createVault",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "holders",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "holdersCount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "isHolder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolSettings",
    outputs: [
      {
        internalType: "contract ProtocolSettings",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "techTokenExchange",
    outputs: [
      {
        internalType: "contract TechTokenExchange",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenSpender",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vaultIndex",
        type: "uint256",
      },
    ],
    name: "unlockTokensOnDemand",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "vaults",
    outputs: [
      {
        internalType: "uint256",
        name: "initAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "unclaimedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensClaimedOnDemand",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "releaseFrom",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "releaseInterval",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensAmountPerInterval",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastRelease",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "initTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isWithdrawOnDemandAllowed",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
    ],
    name: "vaultsLength",
    outputs: [
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
    ],
    name: "vaultsPages",
    outputs: [
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "page",
        type: "uint256",
      },
    ],
    name: "walletInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "totalTokens",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lockedTokens",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "unlockedTokens",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "nextRelease",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "nextReleaseAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "page",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "vaultIndex",
        type: "uint256",
      },
    ],
    name: "withdrawByVaultIndex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
