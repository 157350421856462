export const getDecimalValue = (valueFun: number, decimal: number) => {
  if ((!decimal && decimal !== 0) || decimal < 0) return;

  if (decimal === 0) {
    const valueToFormat = valueFun.toFixed(decimal);
    const textBeforeDecimalPoint = valueToFormat.slice(0, valueToFormat.length);

    return `${Number(textBeforeDecimalPoint)
      .toLocaleString("en-US")
      .replaceAll(",", " ")}`;
  }

  const valueToFormat = valueFun.toFixed(decimal);
  const textAfterDecimalPoint = valueToFormat.slice(
    -decimal,
    valueToFormat.length
  );
  const textBeforeDecimalPoint = valueToFormat.slice(
    0,
    valueToFormat.length - 1 - decimal
  );

  return `${Number(textBeforeDecimalPoint)
    .toLocaleString("en-US")
    .replaceAll(",", " ")}.${textAfterDecimalPoint}`;
};
