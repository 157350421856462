import styled from "styled-components";
import { initKyc } from "../../../../../../api/kyc";
import { Button, Modal, Typography } from "../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux";
import { closeMissingKycModal } from "../../../../../../redux/features/uiSlice";
import { toast } from "../../../../../../toasts";

const ModalBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
`;

const List = styled.ol`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ListEl = styled.li`
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    color: ${({ theme }) => theme.colors.link};
  }
`;

export const MissingKyc = () => {
  const { isOpenMissingKycModal } = useAppSelector((state) => state.ui);

  const dispatch = useAppDispatch();

  const handleInitKyc = async () => {
    try {
      const { data } = await initKyc();
      const { message } = data;
      window.location.href = message;
    } catch (e: unknown) {
      toast.errorHandler(e, "error");
    }
  };

  return (
    <Modal
      isOpen={isOpenMissingKycModal}
      title="Missing KYC data"
      onClose={() => dispatch(closeMissingKycModal())}
    >
      <ModalBodyWrapper>
        <Typography.HeaderSmall>
          Unfortunately, your data is incomplete.
        </Typography.HeaderSmall>
        <Typography.Body>
          During the KYC procedure, the Fractal operator provided us with
          incomplete information about you. All you need to do now is:
        </Typography.Body>
        <List type="1">
          <ListEl>
            <Typography.HeaderSmall>1.</Typography.HeaderSmall>
            <Typography.Body>
              Go to the <span>fractal.id</span> website using the button below
            </Typography.Body>
          </ListEl>
          <ListEl>
            <Typography.HeaderSmall>2.</Typography.HeaderSmall>
            <Typography.Body>
              Return to us from the Fractal website using the button on the{" "}
              <span>fractal.id</span> website.
            </Typography.Body>
          </ListEl>
        </List>
        <Button.Primary onClick={handleInitKyc}>Go to fractal</Button.Primary>
      </ModalBodyWrapper>
    </Modal>
  );
};
