import { apiCall } from "./config";

type GetRateResponse = {
  rate: string;
};

export const getSoilToUsdcRate = () =>
  apiCall.get<GetRateResponse>("/rate/soil-to-usdc");

type GetSoilPriceHistoryParams = {
  granulation: string;
  count: number;
};

type GetSoilPriceHistoryResponse = {
  data: Array<{
    id: number;
    rate: number;
    timestamp: number;
    createdAt: string;
    updatedAt: string;
  }>;
};

export const getSoilPriceHistory = (params: GetSoilPriceHistoryParams) =>
  apiCall.get<GetSoilPriceHistoryResponse>("/rate/graph", { params });
