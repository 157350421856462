import styled from "styled-components";
import { Typography } from "../../../../../components";

export const LevelsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Level = styled.button<{ isSelected: boolean }>`
  padding: 16px;

  transition: 0.1s;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.button.primary.border : theme.colors.white033};

  &:hover {
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.colors.button.primary.border : theme.colors.white};
  }
`;

export const LevelName = styled(Typography.Body)`
  margin-bottom: 12px;
`;

export const LevelDetails = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;

  color: ${({ theme }) => theme.colors.grey};
  font-size: 14px;

  b {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }
`;
