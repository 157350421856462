import React, { useEffect, useRef } from "react";
import { ethers } from "ethers";
import { useAccount, useDisconnect } from "wagmi";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import WalletIcon from "../../../Icons/WalletIcon";
import {
  useAuth,
  useDetectIframeClick,
  useDetectOutsideClick,
  useMediaQuery,
} from "../../../../../../hooks";
import Dropdown from "../Dropdown/Dropdown";
import * as S from "./WalletTool.styled";
import {
  CurrencyValue,
  Typography,
  WalletAddress,
  WalletStatus,
  Button,
  SmallSpinner,
} from "../../../../../../components";
import {
  connectWalletIcon,
  disconnectWalletIcon,
} from "../../../../../../assets";
import { config } from "../../../../../../config";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";
import { device } from "../../../../../../style";
import { routes } from "../../../../../../utils/routes";

export type WalletDropdownProps = {
  isOpen: boolean;
  toggleVisible: () => void;
  isLogged: boolean;
};

const WalletTool = ({
  isOpen,
  toggleVisible,
  isLogged,
}: WalletDropdownProps) => {
  const isDesktopView = useMediaQuery(device.laptop);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { login } = useAuth();
  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );
  const { balances, loading } = useAppSelector((state) => state.wallet);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const iconBtn = document.getElementsByClassName("ignoreOutsideClick");
  useDetectOutsideClick(dropdownRef, toggleVisible, iconBtn);
  useDetectIframeClick(toggleVisible, isOpen);

  const isLogoutWithWalletConnected = isConnected && !isLogged;
  const isLoggedWithDifferentWalletAddresses =
    isLogged &&
    address &&
    address?.toLowerCase() !== walletAddress.toLowerCase() &&
    isConnected;

  const handleMobileClickWalletIcon = () => {
    navigate(routes.userPanel.wallet);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(actions.wallet.getBalances(walletAddress));
    }
  }, [isOpen]);

  return address ? (
    <>
      <WalletIcon
        click={isDesktopView ? toggleVisible : handleMobileClickWalletIcon}
        isToolbar
        isOpen={isOpen}
        isConnected
      />
      <AnimatePresence>
        {isOpen && (
          <Dropdown innerRef={dropdownRef} targetIcon="wallet">
            <S.WalletToolWrapper>
              <S.WalletStatusWrapper>
                <Typography.HeaderSmall
                  as="span"
                  fontWeight={300}
                  opacity={0.92}
                >
                  Wallet
                </Typography.HeaderSmall>
                <WalletStatus isConnected />
              </S.WalletStatusWrapper>
              <WalletAddress address={address} allWidth />
              <S.WalletBalanceWrapper>
                <Typography.Description
                  uppercase
                  fontWeight={400}
                  opacity={0.6}
                >
                  balance
                </Typography.Description>
                <S.WalletBalanceValues>
                  {!loading ? (
                    <>
                      <CurrencyValue
                        value={ethers.utils.formatUnits(
                          balances.usdc,
                          config.USDC_TOKEN_DECIMALS
                        )}
                        currency={config.STABLE_TOKEN}
                        decimals={2}
                        isBalance
                      />
                      <CurrencyValue
                        value={ethers.utils.formatUnits(
                          balances.soil,
                          config.SOIL_TOKEN_DECIMALS
                        )}
                        currency={config.PROJECT_TOKEN}
                        decimals={2}
                        isBalance
                      />
                    </>
                  ) : (
                    <SmallSpinner />
                  )}
                </S.WalletBalanceValues>
              </S.WalletBalanceWrapper>
              <div>
                {(isLogoutWithWalletConnected ||
                  isLoggedWithDifferentWalletAddresses) && (
                  <Button.Secondary
                    onClick={() => login(address)}
                    style={{ marginBottom: "5px", gap: "11px" }}
                  >
                    <img src={connectWalletIcon} alt="wallet connect icon" />
                    Authorize
                  </Button.Secondary>
                )}
                <Button.Secondary
                  onClick={() => disconnect()}
                  style={{ gap: "11px" }}
                >
                  <img
                    src={disconnectWalletIcon}
                    alt="disconnect wallet icon"
                  />
                  <span>Disconnect</span>
                </Button.Secondary>
              </div>
            </S.WalletToolWrapper>
          </Dropdown>
        )}
      </AnimatePresence>
    </>
  ) : null;
};

export default WalletTool;
