import { useEffect, useState } from "react";
import { CurrencyValue, InfoBox } from "../../components";
import { toast } from "../../toasts";
import { poolsContractService } from "../../services";
import { config } from "../../config";
import { getErrorMessage, formatBigNumber } from "../../utils";

export const PoolAvailableFees = () => {
  const [poolsAvailableFees, setPoolsAvailableFees] = useState<string>();

  const getFeesValue = async () => {
    try {
      const feeBalance = await poolsContractService.getFeeBalance();
      setPoolsAvailableFees(
        formatBigNumber(feeBalance, config.USDC_TOKEN_DECIMALS)
      );
    } catch (err) {
      toast.error("Error", getErrorMessage(err));
    }
  };

  useEffect(() => {
    getFeesValue();
  }, []);

  return (
    <InfoBox.Admin title="Fees Available For Withdrawal:">
      <CurrencyValue
        value={poolsAvailableFees || null}
        currency="USDC"
        decimals={2}
        isBig
      />
    </InfoBox.Admin>
  );
};
