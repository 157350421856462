import { createAction, createSlice } from "@reduxjs/toolkit";

type UserState = {
  isKycPreventModalOpen: boolean;
  isOpenTfaModal: boolean;
  tfaIsAuthenticated: boolean;
  isOpenSignMessageModal: boolean;
  isOpenSignMessageModalWhenAuth: boolean;
  isOpenExchangeModal: boolean;
  isOpenMissingKycModal: boolean;
};

const initialState: UserState = {
  isKycPreventModalOpen: false,
  isOpenTfaModal: false,
  tfaIsAuthenticated: false,
  isOpenSignMessageModal: false,
  isOpenSignMessageModalWhenAuth: false,
  isOpenExchangeModal: false,
  isOpenMissingKycModal: false,
};

export const openKycPreventModal = createAction("ui/OpenKycPreventModal");

export const closeKycPreventModal = createAction("ui/CloseKycPreventModal");

export const openTfaModal = createAction("ui/OpenTfaModal");

export const closeTfaModal = createAction("ui/CloseTfaModal");

export const confirmTfa = createAction("ui/ConfirmTfa");

export const openSignMessageModal = createAction("ui/OpenSignMessageModal");

export const closeSignMessageModal = createAction("ui/closeSignMessageModal");

export const openMissingKycModal = createAction("ui/openMissingKycModal");

export const closeMissingKycModal = createAction("ui/closeMissingKycModal");

export const openSignMessageModalWhenAuth = createAction(
  "ui/OpenSignMessageModalWhenAuth"
);

export const closeSignMessageModalWhenAuth = createAction(
  "ui/CloseSignMessageModalWhenAuth"
);

export const openExchangeModal = createAction("ui/openExchangeModal");

export const closeExchangeModal = createAction("ui/CloseExchangeModal");

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(openKycPreventModal, (state) => {
      state.isKycPreventModalOpen = true;
    });
    builder.addCase(closeKycPreventModal, (state) => {
      state.isKycPreventModalOpen = false;
    });
    builder.addCase(openTfaModal, (state) => {
      state.isOpenTfaModal = true;
      state.tfaIsAuthenticated = false;
    });
    builder.addCase(closeTfaModal, (state) => {
      state.isOpenTfaModal = false;
    });
    builder.addCase(confirmTfa, (state) => {
      state.tfaIsAuthenticated = true;
      state.isOpenTfaModal = false;
    });
    builder.addCase(openSignMessageModal, (state) => {
      state.isOpenSignMessageModal = true;
    });
    builder.addCase(closeSignMessageModal, (state) => {
      state.isOpenSignMessageModal = false;
    });
    builder.addCase(openSignMessageModalWhenAuth, (state) => {
      state.isOpenSignMessageModalWhenAuth = true;
    });
    builder.addCase(closeSignMessageModalWhenAuth, (state) => {
      state.isOpenSignMessageModalWhenAuth = false;
    });
    builder.addCase(openExchangeModal, (state) => {
      state.isOpenExchangeModal = true;
    });
    builder.addCase(closeExchangeModal, (state) => {
      state.isOpenExchangeModal = false;
    });
    builder.addCase(openMissingKycModal, (state) => {
      state.isOpenMissingKycModal = true;
    });
    builder.addCase(closeMissingKycModal, (state) => {
      state.isOpenMissingKycModal = false;
    });
  },
});

export default uiSlice.reducer;
