import styled, { css } from "styled-components";
import { device } from "../../style";

export const Wrapper = styled.li<{ withBackground?: boolean }>(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    gap: 13px;

    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);

    padding: 24.19px 20.89px 18.4px 19.65px;
    position: relative;
    transition: all 0.2s ease-in-out;
    @media ${device.tablet} {
      padding: 30.76px 30.76px 58.56px 30.76px;
      gap: 0;
    }

    @media ${device.laptop} {
      padding-bottom: 30.76px;
    }

    @media ${device.laptopM} {
      padding: 30px 22px;
    }

    @media ${device.laptopL} {
      padding: 30px;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 12px;
      padding: 1px;
      background: linear-gradient(to left, #156a4ba6, #462373);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      box-shadow: 0px 0px 9.9px 0px #26a677;
      cursor: pointer;
    }
  `
);

export const PoolDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 13px;
`;

export const PoolProgressWrapper = styled.div<{ isDetailsView?: boolean }>`
  display: block;
  @media ${device.laptop} {
    display: ${({ isDetailsView }) => (isDetailsView ? "block" : "none")};
  }

  @media ${device.laptopM} {
    display: block;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileXL} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.tablet} {
    align-self: flex-start;
    /* align-self: center; */
    margin-top: 0px;
  }
`;
