import { forwardRef } from "react";
import * as S from "../CurrencyInput/CurrencyInput.styled";

export type FormInputProps = {
  name: string;
  label?: string;
  logo?: string;
  error?: string;
  title?: string;
  isReversed?: boolean;
  anyCharacters?: boolean;
  file?: boolean;
  placeholder?: string;
  valueDisabled?: number;
  defaultValue?: string;
  descriptionNote?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      logo,
      label,
      name,
      error,
      title,
      isReversed,
      anyCharacters,
      file,
      placeholder,
      valueDisabled,
      defaultValue,
      descriptionNote,
      ...rest
    }: FormInputProps,
    ref
  ) => (
    <S.Container {...rest}>
      <S.Wrapper
        error={error}
        title={title}
        data-testid="wrapper"
        className="form-input-wrapper"
      >
        {title && (
          <S.Title isReversed={isReversed} data-testid="title">
            {title}
          </S.Title>
        )}
        <S.InputWrapper isReversed={isReversed}>
          <S.LabelWrapper as="label" htmlFor={name}>
            {logo && <S.Logo src={logo} alt="logo" />}
            {label && <S.Label>{label}</S.Label>}
          </S.LabelWrapper>
          <S.Input
            style={{ width: "100%", fontSize: "12px" }}
            value={valueDisabled && valueDisabled}
            defaultValue={defaultValue && defaultValue}
            disabled={!!valueDisabled}
            type={anyCharacters ? "text" : file ? "file" : "number"}
            step="any"
            id={name}
            name={name}
            ref={ref}
            placeholder={placeholder || "0"}
            data-testid="input"
            isReversed={isReversed}
            {...rest}
          />
        </S.InputWrapper>
      </S.Wrapper>
      {descriptionNote && (
        <S.Note style={{ paddingTop: "2px" }} uppercase>
          <S.Note
            as="span"
            style={{ paddingRight: "5px" }}
            uppercase
            opacity={0.8}
            fontWeight={600}
          >
            auxiliary note:
          </S.Note>
          <S.Note as="span" uppercase opacity={0.5} fontWeight={400}>
            {descriptionNote}
          </S.Note>
        </S.Note>
      )}
      <S.InfoWrapper>
        {error ? <S.ErrorMessage uppercase>{error}</S.ErrorMessage> : null}
      </S.InfoWrapper>
    </S.Container>
  )
);
