import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as S from "../../ToolBar.styled";
import NotificationsIcon from "../../../Icons/NotificationsIcon";
import { useAuth } from "../../../../../../hooks";
import { routes } from "../../../../../../utils/routes";
import WalletTool from "../WalletTool/WalletTool";
import { useAppSelector } from "../../../../../../redux";

const AdminToolBar = ({
  isNewNotification,
  handleNotificationClick,
}: S.ToolBarProps) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(routes.userPanel.dashboard);
  };

  const isLogged = useAppSelector((state) => state.user.isLogged);

  const [isWalletDropdownVisible, setIsWalletDropdownVisible] = useState(false);

  const toggleWalletDropdown = () => {
    setIsWalletDropdownVisible(!isWalletDropdownVisible);
  };

  return (
    <>
      <WalletTool
        toggleVisible={toggleWalletDropdown}
        isOpen={isWalletDropdownVisible}
        isLogged={isLogged}
      />
      <NotificationsIcon
        isNewNotification={isNewNotification}
        isToolbar
        click={handleNotificationClick}
      />
      <S.LogOutButton onClick={handleLogout}>
        <span>Log Out</span>
      </S.LogOutButton>
    </>
  );
};

export default AdminToolBar;
