import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = (elementID?: string) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (elementID) {
      const element = document.getElementById(elementID);
      if (element) element.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [pathname]);
};
