import qs from "qs";
import { apiCallWithAuthToken } from "./config";
import { GetUsersData } from "./user";

type GetRequiredTokensPerInterval = {
  soil: string;
  soilBoost: string;
  usdc: string;
};

export const getTokensPerInterval = () =>
  apiCallWithAuthToken.get<GetRequiredTokensPerInterval>(
    "pools/levels/required-tokens-per-interval"
  );

type PoolsFilteredKeys = "endDate" | "balance" | "poolId" | "id";

type GetPoolsResponse = {
  data: Pool[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getPools = (params: GetQueryParams<PoolsFilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetPoolsResponse>(`/pools?${queryString}`);
};

type LevelsFilteredKeys = "pool.poolId" | "id" | "pool.start" | "poolId";

type GetLevelsResponse = {
  data: PoolLevel[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getLevels = (params: GetQueryParams<LevelsFilteredKeys>) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetLevelsResponse>(
    `/pools/levels?${queryString}`
  );
};

export const getLevelsAsLogged = (
  params: GetQueryParams<LevelsFilteredKeys>
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetLevelsResponse>(
    `/pools/levels/as-logged?${queryString}`
  );
};

type GetDepositSignatureResponse = {
  signature: string;
  fee: string;
  feeInUsdt: string;
  deadline: string;
};

export const getDepositSignature = (
  poolId: Pool["id"],
  level: PoolLevel["id"],
  amount: string
) =>
  apiCallWithAuthToken.get<GetDepositSignatureResponse>(
    "/pools/signature/deposit",
    {
      params: {
        poolId,
        level,
        amount,
      },
    }
  );

type GetClaimSignatureParams = {
  poolId: Pool["id"];
  level: PoolLevel["level"];
  rewards: string;
  soilRewards: string;
  withWithdraw: boolean;
};

type GetClaimSignatureResponse = {
  signature: string;
  deadline: string;
};

export const getClaimRewardsSignature = (params: GetClaimSignatureParams) =>
  apiCallWithAuthToken.get<GetClaimSignatureResponse>(
    "/pools/signature/claim-rewards",
    {
      params,
    }
  );

type GetGlobalPoolStats = {
  totalHardCaps: string;
  totalDeposited: string;
  openPoolsHardCapsSum: string;
};

export const getGlobalPoolStats = () =>
  apiCallWithAuthToken.get<GetGlobalPoolStats>("/pools/stats");

type GetUserTotalInvested = {
  totalInvested: string;
};

export const getUserTotalInvested = () =>
  apiCallWithAuthToken.get<GetUserTotalInvested>("/pools/total-invested");

type PoolDataToUpdate = {
  description?: string;
  balance?: string;
  fee?: string;
};

export const updatePoolData = (poolId: number, data: PoolDataToUpdate) =>
  apiCallWithAuthToken.patch(`/pools/${poolId}`, data);

type LevelDataToUpdate = {
  name?: string;
  subtitle?: string;
  description?: string;
  aprAutoAdjust?: boolean;
  tokensPerInterval?: string;
  lowAprLimit?: string;
  highAprLimit?: string;
  aprAutoChangeValue?: string;
  boostPercentage?: string;
  boostTokensPerInterval?: string;
  apr?: string;
};

export const updateLevelData = (levelId: number, data: LevelDataToUpdate) =>
  apiCallWithAuthToken.patch(`/pools/levels/${levelId}`, data);

type GetRewardsAmountResponse = {
  rewards: {
    soil: string;
    usdc: string;
  };
  lastRewardAmount: {
    soil: string;
    usdc: string;
  };
};

export const getRewardsAmount = () =>
  apiCallWithAuthToken.get<GetRewardsAmountResponse>("/pools/rewards-amounts");

export const uploadIcon = (id: Pool["id"], formData: FormData) =>
  apiCallWithAuthToken.post(`/pools/${id}/icon`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export type UserPoolsAction = {
  createdAt: string;
  id: number;
  updatedAt: string;
  amount: string;
  balanceAfter: string;
  fee: string;
  action: string;
  txHash: string;
  txBlock: number;
  txIndex: number;
  start: string;
  end: string;
  user: GetUsersData;
};

export type UserPoolsRewards = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  soilAmount: string;
  boostAmount: string;
  start: string;
  end: string;
  user: GetUsersData;
};

export type UserPoolsClaims = {
  id: number;
  createdAt: string;
  updatedAt: string;
  amount: string;
  fee: string;
  txHash: string;
  txBlock: number;
  txIndex: string;
  user: GetUsersData;
};

type GetUserPoolsActionsResponse = {
  data: UserPoolsAction[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

type UserPoolsFilteredKeys = "id";

export const getUserPoolsActions = (
  params: GetQueryParams<UserPoolsFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserPoolsActionsResponse>(
    `/pools/user/${id}/actions?${queryString}`
  );
};

type GetUserPoolsRewardsResponse = {
  data: UserPoolsRewards[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUserPoolsRewards = (
  params: GetQueryParams<UserPoolsFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserPoolsRewardsResponse>(
    `/pools/user/${id}/rewards?${queryString}`
  );
};

type GetUserPoolsClaimsResponse = {
  data: UserPoolsClaims[];
  links: {
    current: string;
  };
  meta: ResponseMetadata;
};

export const getUserPoolsClaims = (
  params: GetQueryParams<UserPoolsFilteredKeys>,
  id: number
) => {
  const queryString = qs.stringify(params);

  return apiCallWithAuthToken.get<GetUserPoolsClaimsResponse>(
    `/pools/user/${id}/claims?${queryString}`
  );
};

type GetLevelsWithRewardsResponse = {
  data: Array<{
    createdAt: Date;
    id: number;
    level: 0;
    name: string;
    subtitle: string;
    description: string;
    pool: {
      createdAt: Date;
      name: string;
      poolId: number;
    };
    rewards: Array<{
      amount: string;
      boostAmount: string;
      createdAt: string;
      end: string;
      id: number;
      soilAmount: string;
      start: string;
      updatedAt: string;
    }>;
  }>;
  limit: number;
  page: number;
  total: number;
};

export const getLevelsWithRewards = (page: number, limit: number) =>
  apiCallWithAuthToken.get<GetLevelsWithRewardsResponse>(
    "/pools/levels/with-rewards",
    {
      params: {
        page,
        limit,
      },
    }
  );

export type RewardsHolder = {
  createdAt: string;
  id: number;
  isActive: boolean;
  txBlock: number;
  txHash: string;
  txIndex: number;
  txTime: string;
  walletAddress: string;
  updatedAt: string;
};

export type RewardsHoldersType = Array<RewardsHolder>;

type GetPoolsRewardsHoldersResponse = {
  data: RewardsHoldersType;
};

export const getPoolsRewardsHolders = () =>
  apiCallWithAuthToken.get<GetPoolsRewardsHoldersResponse>(
    "/pools/rewards-holders"
  );
