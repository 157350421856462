import styled from "styled-components";
import { Button } from "../../../../components";
import { device } from "../../../../style";

export type WalletBtnProps = {
  isConnected: boolean;
  hasDiode?: boolean;
  isToolbar?: boolean;
};

export type ToolBarProps = {
  isNewNotification: boolean;
  handleNotificationClick?: () => void;
};

export type NotificationBtnProps = {
  isNewNotification: boolean;
  isToolbar?: boolean;
};

export type UserIconBtnProps = {
  isToolbar?: boolean;
};

export const ToolBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 23px;

  @media ${device.tablet} {
    gap: 30px;
  }

  @media ${device.laptop} {
    gap: 32px;
  }
`;

export const DropdownMarkIconWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const IconBgWallet = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.toolbar.iconBg};
  background: ${({ theme }) => theme.colors.primary};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: -3px;
  left: -1px;
`;

export const IconBgUser = styled(IconBgWallet)`
  top: -1px;
`;

export const IconImg = styled.img`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.toolbar.iconImg};
`;

export const UserIconBtn = styled.button<UserIconBtnProps>`
  position: relative;
  padding: ${({ isToolbar }) => (isToolbar ? "4px" : "8px 10px 8px 10px")};
`;

export const WalletIconBtn = styled.button<WalletBtnProps>`
  position: relative;
  padding: ${({ isToolbar }) => (isToolbar ? "4px" : "8px 10px 8px 10px")};

  &:after {
    content: "";
    display: ${({ hasDiode }) => (hasDiode ? "block" : "none")};
    position: absolute;
    padding: 5px;
    border: 3px solid
      ${({ theme, isConnected }) =>
        isConnected ? theme.colors.successBorder : theme.colors.warningLight};
    background: ${({ theme, isConnected }) =>
      isConnected ? theme.colors.success : theme.colors.warning};
    border-radius: 50%;
    top: ${({ isToolbar }) => (isToolbar ? "-6px" : "0")};
    right: ${({ isToolbar }) => (isToolbar ? "-4px" : "2px")};
    z-index: ${({ theme }) => theme.zIndex.toolbar.walletIconBtn};
  }
`;

export const NotificationIconBtn = styled.button<NotificationBtnProps>`
  position: relative;
  padding: 4px;

  &:after {
    content: "";
    display: ${({ isNewNotification }) =>
      isNewNotification ? "block" : "none"};
    position: absolute;
    padding: 5px;
    border: 3px solid
      ${({ theme, isNewNotification, isToolbar }) =>
        isNewNotification && isToolbar
          ? theme.colors.background
          : theme.colors.white};
    background: ${({ theme, isNewNotification }) =>
      isNewNotification && theme.colors.error};
    border-radius: 50%;
    top: -3px;
    right: 2px;
  }
`;

export const HamburgerIconBtn = styled.button`
  position: relative;
  padding: 4px;
`;

export const LogoutIconBtn = styled.button`
  position: relative;
  padding: 4px;
`;

export const LogOutButton = styled(Button.Secondary)`
  @media ${device.mobileXL} {
    width: 140px;
  }
`;
