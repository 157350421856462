import {
  notificationNoneDarkIcon,
  notificationNoneLightIcon,
} from "../../../../assets";
import * as S from "../ToolBar/ToolBar.styled";

export type NotificationIconProps = {
  isNewNotification: boolean;
  isToolbar?: boolean;
  click?: () => void;
};

const NotificationsIcon = ({
  isNewNotification,
  isToolbar,
  click,
}: NotificationIconProps) => (
  <S.NotificationIconBtn
    isNewNotification={isNewNotification}
    isToolbar={isToolbar}
    onClick={click}
    type="button"
  >
    <img
      src={isToolbar ? notificationNoneLightIcon : notificationNoneDarkIcon}
      alt="none notification icon"
    />
  </S.NotificationIconBtn>
);

export default NotificationsIcon;
