import { ChangeEvent, ComponentPropsWithoutRef } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { usePaginationQueryParams } from "../../hooks";
import * as S from "./Pagination.styled";

type PaginationProps = {
  pageCount: number;
  customPage?: number;
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
} & ComponentPropsWithoutRef<"div">;

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 30, 40, 50];

export const Pagination = (props: PaginationProps) => {
  const { pageCount, customPage, setPageNumber, ...rest } = props;

  const { page, itemsPerPage, changeItemsPerPage, setPage } =
    usePaginationQueryParams();

  const handlePageChange: ReactPaginateProps["onPageChange"] = (event) => {
    if (setPageNumber) {
      setPageNumber(event.selected + 1);
    } else {
      setPage(event.selected + 1);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    changeItemsPerPage(Number(event.target.value));
  };

  return (
    <S.PaginationWrapper {...rest}>
      <S.DisplayWrapper>
        <S.Display>Display</S.Display>
        <S.Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          {ITEMS_PER_PAGE_OPTIONS.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </S.Select>
      </S.DisplayWrapper>
      <S.PaginationContainer>
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          previousLabel="<"
          nextLabel=">"
          pageRangeDisplayed={4}
          pageCount={pageCount}
          activeClassName="activePage"
          forcePage={customPage ? customPage - 1 : page - 1}
          onPageChange={handlePageChange}
        />
      </S.PaginationContainer>
    </S.PaginationWrapper>
  );
};
