import { Navigate, Outlet } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useAppSelector } from "../../redux/hooks";
import { isAdminRole } from "../../utils";

export const PrivateRoute = () => {
  const isLogged = useAppSelector((state) => state?.user?.isLogged);
  const roles = useAppSelector((state) => state?.user?.userData?.roles);

  const isAdmin = isAdminRole(roles);
  return isLogged && !isAdmin ? (
    <Outlet />
  ) : isAdmin ? (
    <Navigate
      to={routes.adminPanel.dashboard}
      state={{ error: "You must login to proceed" }}
    />
  ) : (
    <Navigate
      to={routes.userPanel.dashboard}
      state={{ error: "You must login to proceed" }}
    />
  );
};
