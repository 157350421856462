import styled from "styled-components";
import { useMediaQuery } from "../../hooks";
import { device } from "../../style";
import { LoadingSkeleton } from "../LoadingSkeleton";
import { Typography } from "../Typography/Typography";

const GreenValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
`;

type GreenValueProps = {
  firstPart: string | number | null;
  secondPart?: string | number | null;
  isCurrencyValue?: boolean;
};

export const GreenValue = ({
  firstPart,
  secondPart,
  isCurrencyValue,
}: GreenValueProps) => {
  const isTablet = useMediaQuery(device.tablet);
  const formattedValue = Number(firstPart)
    .toLocaleString("en-US")
    .replaceAll(",", " ");
  return (
    <GreenValueWrapper>
      {!firstPart || !formattedValue || Number.isNaN(formattedValue) ? (
        <LoadingSkeleton
          height={isTablet ? "18.38px" : "14.38px"}
          width="50px"
        />
      ) : (
        <>
          <Typography.GreenValue fontWeight={700}>
            {isCurrencyValue ? formattedValue : firstPart}
          </Typography.GreenValue>
          {secondPart ? (
            <Typography.GreenValue fontWeight={400}>
              {" "}
              {secondPart}
            </Typography.GreenValue>
          ) : null}
        </>
      )}
    </GreenValueWrapper>
  );
};
