import { forwardRef } from "react";
import * as S from "./MessageInput.styled";

export type FormInputProps = {
  name: string;
  error?: string;
  title?: string;
  value?: string;
  edit?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const MessageInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ name, error, title, edit, value, ...rest }: FormInputProps, ref) => (
    <S.Container {...rest} ref={ref}>
      <S.Wrapper error={error} title={title} edit={edit}>
        {title && <S.Title data-testid="title">{title}</S.Title>}
        <S.Input
          defaultValue={value && value}
          disabled={false}
          id={name}
          name={name}
          placeholder=""
        />
      </S.Wrapper>
      <S.InfoWrapper>
        {error ? <S.ErrorMessage uppercase>{error}</S.ErrorMessage> : null}
      </S.InfoWrapper>
    </S.Container>
  )
);
