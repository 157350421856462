import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Checkmark, Input, Label } from "./Checkbox.styled";

type BaseInputProps = ComponentPropsWithoutRef<"input">;

export const Checkbox = forwardRef<HTMLInputElement, BaseInputProps>(
  (props) => {
    const { className, ...restProps } = props;
    return (
      <Label className={className}>
        <Input type="checkbox" {...restProps} />
        <Checkmark />
      </Label>
    );
  }
);
