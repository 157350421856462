import styled from "styled-components";

export type StatusProps = {
  isConnected: boolean;
};

export const Status = styled.span<StatusProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  padding: 4px;
  font-family: Arial;
  font-size: ${({ theme }) => theme.fontStyles.walletStatus.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.walletStatus.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.walletStatus.lineHeight};
  border-radius: 4px;
  color: ${({ theme, isConnected }) =>
    isConnected ? theme.colors.success : theme.colors.text.primary};
  background: ${({ theme, isConnected }) =>
    isConnected
      ? theme.colors.walletStatus.backgroundOn
      : theme.colors.modal.background};
`;

export const ConnectedRadio = styled.div`
  padding: 2px;
  background: ${({ theme }) => theme.colors.success};
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.successBorder};
`;
