import { InjectedConnector } from "@wagmi/core";
import React from "react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { polygonMumbai, polygon } from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { SafeConnector } from "wagmi/connectors/safe";
// import { LedgerConnector } from "@wagmi/connectors/ledger";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { config } from "../config";

const { chains, publicClient } = configureChains(
  [config.NODE_ENV === "dev" ? polygonMumbai : polygon],
  [alchemyProvider({ apiKey: `${config.ALCHEMY_ID}` }), publicProvider()]
);

export const configWagmi = createConfig({
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        showQrModal: true,
        projectId: `${config.WALLET_CONNECT_PROJECT_ID}`,
      },
    }),
    new InjectedConnector({ chains }),
    // new LedgerConnector({
    //   chains,
    //   options: {
    //     projectId: `${config.WALLET_CONNECT_PROJECT_ID}`,
    //   },
    // }),
    new SafeConnector({
      chains,
      options: {
        allowedDomains: [/app.safe.global$/],
        debug: false,
      },
    }),
  ],
  publicClient,
  autoConnect: true,
});

interface IWalletProvider {
  children: React.ReactNode;
}

export const WalletProvider = ({ children }: IWalletProvider) => (
  <WagmiConfig config={configWagmi}>{children}</WagmiConfig>
);
