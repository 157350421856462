import styled from "styled-components";

export const Wrapper = styled.button`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 32px;
  height: 32px;

  background: #202125;
  border: 1px solid #52555f;
  border-radius: 8px;
`;
