import { FormEvent, useEffect, useState } from "react";
import { api } from "../../../../api";
import { config } from "../../../../config";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { openTfaModal } from "../../../../redux/features/uiSlice";
import {
  resetTfaAuthorizationForAction,
  setCurrentTfaAction,
} from "../../../../redux/features/userSlice";
import { poolsContractService } from "../../../../services";
import { toast } from "../../../../toasts";
import { handleBlockchainPromiseInToast } from "../../../../toasts/handleBlockchainPromiseInToast";
import { formatBigNumber, useEthersSigner } from "../../../../utils";

export const usePoolClaimHandler = (
  poolLevel: PoolLevel,
  onSuccess: () => void
) => {
  const [isClaimInProgress, setIsClaimInProgress] = useState(false);
  const signer = useEthersSigner();
  const dispatch = useAppDispatch();

  const { tfaEnabled, tfaAuthenticatedForAction } = useAppSelector(
    (state) => state.user
  );
  const { isPoolsClaimAuthenticated } = tfaAuthenticatedForAction;

  const claimRewards = async () => {
    try {
      setIsClaimInProgress(true);

      if (!signer) {
        throw new Error("Wallet not connected");
      }

      const rewards = poolLevel.usdcReward || "0";
      const soilRewards = poolLevel.soilReward || "0";

      // console.log(poolLevel.usdcReward);
      // console.log(poolLevel.soilReward);

      const {
        data: { signature, deadline },
      } = await api.pools.getClaimRewardsSignature({
        poolId: poolLevel.pool.poolId,
        level: poolLevel.level,
        rewards,
        soilRewards,
        withWithdraw: false,
      });

      const claimTx = poolsContractService.claimRewards(
        poolLevel.pool.poolId,
        poolLevel.level,
        rewards,
        soilRewards,
        deadline,
        signature,
        signer
      );

      const formattedUSDCRewards = formatBigNumber(
        rewards,
        config.USDC_TOKEN_DECIMALS
      );
      const formattedSoilRewards = formatBigNumber(
        soilRewards,
        config.SOIL_TOKEN_DECIMALS
      );

      const title = `Claiming of ${formattedUSDCRewards} ${config.STABLE_TOKEN} and ${formattedSoilRewards} ${config.PROJECT_TOKEN}...`;

      await handleBlockchainPromiseInToast(claimTx, {
        pending: {
          title,
          message: "Your request is in progress...",
        },
        error: {
          title,
          message:
            "Your request has been finished unsuccessful. Try again later",
        },
        success: {
          title,
          message:
            "The transaction has been finished successful. You can check details on the block explorer by click the button below.",
        },
      });

      onSuccess();
    } catch (error: unknown) {
      toast.errorHandler(error, "Failed to claim rewards!");
      setIsClaimInProgress(false);
      dispatch(resetTfaAuthorizationForAction());
    }
  };

  const submitClaimRewards = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (tfaEnabled) {
      dispatch(setCurrentTfaAction("claim"));
      dispatch(openTfaModal());
    } else {
      await claimRewards();
    }
  };

  useEffect(() => {
    if (isPoolsClaimAuthenticated) {
      claimRewards();
    }
  }, [isPoolsClaimAuthenticated]);

  return { isClaimInProgress, submitClaimRewards };
};
