import { BigNumber, BigNumberish, ethers } from "ethers";
import { config } from "../config";
import { AppRpcProvider } from "../utils";
import { SoilToken, Staking } from "./contracts";
import { soilTokenAbi, stakingAbi } from "./contracts/abi";

const stakeSoil = async (
  amountToStake: BigNumber,
  fee: string,
  signature: string,
  deadline: string,
  signer: ethers.providers.JsonRpcSigner,
  address: string
): Promise<string> => {
  const allowance = await SoilToken.connect(signer).allowance(
    address,
    Staking.address
  );

  if (amountToStake.gt(allowance)) {
    const approveTx = await SoilToken.connect(signer).approve(
      Staking.address,
      amountToStake
    );
    await approveTx.wait(config.CONFIRMATION_BLOCKS);
  }

  const { wait } = await Staking.connect(signer).stakeSOIL(
    amountToStake,
    fee,
    deadline,
    signature
  );

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const unstakeSoil = async (
  positionId: string,
  rewards: string,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner
): Promise<string> => {
  const { wait } = await Staking.connect(signer).unstakeSOIL(
    positionId,
    rewards,
    deadline,
    signature
  );
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const restakeSoil = async (
  amountToRestake: BigNumber,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner
): Promise<string> => {
  /* eslint-disable */
  console.log({
    amountToRestake,
    deadline,
    signature,
  });
  const { wait } = await Staking.connect(signer).restakeRewards(
    amountToRestake,
    deadline,
    signature
  );
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const claimRewards = async (
  amount: BigNumberish,
  deadline: string,
  signature: string,
  signer: ethers.providers.JsonRpcSigner
): Promise<string> => {
  const { wait } = await Staking.connect(signer).claimRewards(
    amount,
    deadline,
    signature
  );
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const getLockoutTime = async () =>
  Staking.connect(AppRpcProvider).LOCKOUT_TIME();

const adminWithdraw = async (
  address: string,
  amount: BigNumber,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await Staking.connect(signer).adminWithdraw(address, amount);

  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);

  return transactionHash;
};

const withdrawFees = async (
  address: string,
  signer: ethers.providers.JsonRpcSigner
) => {
  const { wait } = await Staking.connect(signer).withdrawFee(address);
  const { transactionHash } = await wait(config.CONFIRMATION_BLOCKS);
  return transactionHash;
};

const getFeeBalance = async () => Staking.feeBalance();

const initStakingContract = () =>
  new ethers.Contract(config.STAKING_ADDRESS, stakingAbi, AppRpcProvider);

const limitOfSoilRewards = async () => {
  const stakingContract = initStakingContract();

  const soilLimit = stakingContract.limitOfSoilRewards();
  return soilLimit;
};

const limitOfRestakingRewards = async () => {
  const stakingContract = initStakingContract();

  const restakeLimit = stakingContract.limitOfRestakingRewards();
  return restakeLimit;
};

const changeSoilRewardsLimit = async (
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await Staking.connect(signer).changeSoilRewardsLimit(
    newLimit,
    { gasLimit: "1000000" }
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeRestakingRewardsLimit = async (
  signer: ethers.providers.JsonRpcSigner,
  newLimit: BigNumber
) => {
  const { wait } = await Staking.connect(signer).changeRestakingRewardsLimit(
    newLimit,
    { gasLimit: "1000000" }
  );

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const changeAgreementToBeRewardsHolder = async (
  signer: ethers.providers.JsonRpcSigner
) => {
  const SoilTokenContract = new ethers.Contract(
    config.SOIL_TOKEN_ADDRESS,
    soilTokenAbi,
    signer
  );

  await SoilTokenContract.approve(
    config.STAKING_ADDRESS,
    ethers.constants.MaxUint256
  );

  const { wait } = await Staking.connect(
    signer
  ).changeAgreementToBeRewardsHolder();

  const tx = await wait(config.CONFIRMATION_BLOCKS);
  return tx;
};

const getSoilRewards = async () => {
  const stakingContract = initStakingContract();
  const soilRewards = await stakingContract.amountOfRewards();
  return soilRewards;
};

const sendSoilRewardsToContract = async (
  signer: ethers.providers.JsonRpcSigner,
  from: string,
  amount: BigNumber
) => {
  const { wait } = await Staking.connect(signer).sendRewards(from, amount);

  const tx = await wait(1);
  return tx;
};

const unlockTokens = async (
  signer: ethers.providers.JsonRpcSigner,
  amount: BigNumber
) => {
  const { wait } = await Staking.connect(signer).unlockTokens(amount);
  const tx = await wait(1);
  return tx;
};

export const stakingService = {
  stakeSoil,
  unstakeSoil,
  restakeSoil,
  claimRewards,
  getLockoutTime,
  adminWithdraw,
  getFeeBalance,
  withdrawFees,
  limitOfRestakingRewards,
  limitOfSoilRewards,
  changeRestakingRewardsLimit,
  changeSoilRewardsLimit,
  changeAgreementToBeRewardsHolder,
  getSoilRewards,
  sendSoilRewardsToContract,
  unlockTokens,
};
