import React from "react";
import * as S from "./WalletStatus.styled";

export type WalletStatusProps = {
  isConnected: boolean;
};

export const WalletStatus = ({ isConnected }: WalletStatusProps) => (
  <S.Status isConnected={isConnected}>
    {isConnected && <S.ConnectedRadio />}
    {isConnected ? "is connected" : "is disconnected"}
  </S.Status>
);
