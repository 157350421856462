import { useEffect, useRef } from "react";

export const useInterval = (
  callback: () => void,
  delay: number | null,
  callOnInit = true
) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    if (callOnInit) {
      callback();
    }
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};
