import { config } from "../../../../../config";
import {
  bpsToPercent,
  formatBigNumber,
  humanizePoolDuration,
} from "../../../../../utils";
import { CustomTooltip, MBC } from "../../../../../components";
import { PoolIcon } from "../../../PoolIcon";

type UpgradePoolInfoProps = {
  poolLevel: PoolLevel;
};

export const UpgradePoolInfo = (props: UpgradePoolInfoProps) => {
  const { poolLevel } = props;

  return (
    <>
      <MBC.LogoWrapper>
        <PoolIcon poolIcon={poolLevel.pool.icon} />
      </MBC.LogoWrapper>
      <MBC.HeaderWithLogoWrapper>
        <MBC.InfoBox>
          <MBC.InfoRow as="li">
            Current pool
            <span>{poolLevel.name}</span>
          </MBC.InfoRow>
          <MBC.InfoRow as="li">
            Fixed APR in USDC
            <span>
              {bpsToPercent(poolLevel.usdcApr)}%
              <CustomTooltip message="Refers to the ordinary interest applied to the principal amount of an investment" />
            </span>
          </MBC.InfoRow>
          <MBC.InfoRow as="li">
            Duration (lockup period)
            <span>
              {humanizePoolDuration(poolLevel.cooldown)}
              <CustomTooltip message="The period on which invested funds are locked on the contract" />
            </span>
          </MBC.InfoRow>
          <MBC.InfoRow as="li">
            Your balance
            <span>
              {formatBigNumber(
                poolLevel.deposit || "0",
                config.USDC_TOKEN_DECIMALS
              )}{" "}
              USDC
            </span>
          </MBC.InfoRow>
        </MBC.InfoBox>
      </MBC.HeaderWithLogoWrapper>
    </>
  );
};
