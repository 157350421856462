import { HTMLMotionProps } from "framer-motion";
import { forwardRef } from "react";
import { createPortal } from "react-dom";
import { preparePortalElement } from "../../../utils";
import { Typography } from "../../Typography/Typography";
import * as S from "./DropdownList.styled";
import { CustomTooltip } from "../../Tooltip/CustomTooltip";

type DropdownElement = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
  tooltip?: string;
};

type DropdownListProps = {
  isVisible: boolean;
  elements: DropdownElement[];
} & HTMLMotionProps<"ul">;

export const DropdownList = forwardRef<HTMLUListElement, DropdownListProps>(
  (props, ref) => {
    const el = preparePortalElement("dropdown");

    const { elements, isVisible, ...rest } = props;

    return createPortal(
      <S.DropdownUl ref={ref} isVisible={isVisible} {...rest}>
        {elements.map((element) => (
          <S.DropdownLi
            key={String(element.label)}
            onClick={
              !element.isDisabled
                ? (e) => {
                    e.stopPropagation();
                    element.onClick();
                  }
                : undefined
            }
            disabled={element.isDisabled}
            role="button"
          >
            {element.isDisabled && element.tooltip ? (
              <CustomTooltip
                message={element.tooltip}
                placement="top"
                big
                className="!flex !w-full !justify-center"
              >
                <Typography.Body fontWeight={400}>
                  {element.label}
                </Typography.Body>
              </CustomTooltip>
            ) : (
              <Typography.Body fontWeight={400}>
                {element.label}
              </Typography.Body>
            )}
          </S.DropdownLi>
        ))}
      </S.DropdownUl>,
      el
    );
  }
);
