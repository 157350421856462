import { useState } from "react";
import { infoIcon } from "../../assets";
import * as S from "./CriticalNotification.styled";

export type CriticalNotificationProps = {
  title: string;
  description: string;
};

export const CriticalNotification = ({
  title,
  description,
}: CriticalNotificationProps) => {
  const [isOpen] = useState<boolean>(true);

  return (
    <S.Wrapper isOpen={isOpen}>
      <S.IconWrapper>
        <img src={infoIcon} alt="infoIcon" />
      </S.IconWrapper>
      <S.MassageWrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.MassageWrapper>
      <S.ActionsWrapper>
        {/* <S.ActionButton as="button" onClick={() => setIsOpen(false)}>
          Action
        </S.ActionButton>
        <S.ActionButton as="button" onClick={() => setIsOpen(false)}>
          Dismiss
        </S.ActionButton> */}
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};
