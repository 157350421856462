/* eslint-disable */
import { forwardRef, useEffect, useState } from "react";
import * as S from "./FileInput.styled";

export type FormInputProps = {
  name: string;
  logo?: string;
  error?: string;
  title?: string;
  isReversed?: boolean;
  value?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const FileInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { logo, name, error, title, isReversed, value, ...rest }: FormInputProps,
    ref
  ) => {
    const [imageUrl, setImageUrl] = useState<string | null>(logo || null);
    const handleClick = () => {
      const fileInput = document.getElementById(name);
      if (fileInput !== null) {
        fileInput.click();
      }
    };

    useEffect(() => {
      if (!value) {
        return;
      }

      let fileReader: any;
      let isCancel = false;
      if (value[0]) {
        fileReader = new FileReader();
        fileReader.onload = (e: any) => {
          const { result } = e.target;
          if (result && !isCancel) {
            setImageUrl(result);
          }
        };
        fileReader.readAsDataURL(value[0]);
      }

      return () => {
        isCancel = true;
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort();
        }
      };
    }, [value]);

    return (
      <S.Container {...rest}>
        <S.Wrapper error={error} title={title}>
          <S.InputWrapper isReversed={isReversed}>
            {imageUrl && <S.Logo src={imageUrl} alt="logo" />}
            <S.LeftWrapper>
              {title && <S.Title isReversed={isReversed}>{title}</S.Title>}
              <S.Input
                type="file"
                step="any"
                id={name}
                name={name}
                ref={ref}
                isReversed={isReversed}
              />
              <S.inputButton type="button" onClick={handleClick}>
                select picture
              </S.inputButton>
            </S.LeftWrapper>
          </S.InputWrapper>
        </S.Wrapper>
        <S.InfoWrapper>
          {error ? <S.ErrorMessage uppercase>{error}</S.ErrorMessage> : null}
        </S.InfoWrapper>
      </S.Container>
    );
  }
);
