import { StakeForm } from "../../../StakeForm";
import { BoostInfo } from "./BoostInfo";

type BoostBodyProps = {
  poolLevel: PoolLevel;
  closeModal: () => void;
};

export const BoostBody = ({ poolLevel, closeModal }: BoostBodyProps) => (
  <div className="flex flex-col gap-10">
    <BoostInfo poolLevel={poolLevel} />
    <StakeForm onStakeSuccess={closeModal} />
  </div>
);
