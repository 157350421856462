import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 10px 8px 32px;
  gap: 6px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  top: 8px;
  left: 12px;
  pointer-events: none;
`;
