import React, { useEffect, useState } from "react";
import { useAccount, useConnect } from "wagmi";
import * as S from "./WalletAddress.styled";
import { eyeCrossedIcon, eyeIcon } from "../../assets";
import { Typography } from "../Typography/Typography";
import { sliceWalletAddress, copyToClipboard } from "../../utils";
import { useAppSelector } from "../../redux/hooks";

export type WalletAddressProps = {
  address: string;
  allWidth?: boolean;
};

export const WalletAddress = ({ address, allWidth }: WalletAddressProps) => {
  const { isOpenSignMessageModal } = useAppSelector((state) => state.ui);
  const { address: connectedWalletAddress } = useAccount();
  const { isLoading } = useConnect();

  const walletAddress = useAppSelector(
    (state) => state.user.userData.walletAddress
  );
  const isLogged = useAppSelector((state) => state.user.isLogged);
  const [isAddressVisible, setIsAddressVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const toggleAddressVisible = () => setIsAddressVisible(!isAddressVisible);

  const handleCopyToClipboard = (textToCopy: string) => {
    if (isAddressVisible) {
      setIsCopied(true);
      copyToClipboard(textToCopy);
    }
  };

  const addressButton = (
    <button
      style={isAddressVisible ? { cursor: "copy" } : { cursor: "default" }}
      type="button"
      onClick={() => handleCopyToClipboard(address)}
    >
      <Typography.Body fontWeight={400} isWhite>
        {isAddressVisible ? address : sliceWalletAddress(address)}
        {isCopied && <S.CopiedWrapper as="span">Copied!</S.CopiedWrapper>}
      </Typography.Body>
    </button>
  );

  useEffect(() => {
    const copyTimeout = setTimeout(() => {
      setIsCopied(false);
    }, 700);

    return () => {
      clearTimeout(copyTimeout);
    };
  }, [isCopied]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <S.WalletAddressWrapper>
        <button
          data-testid="eyeButton"
          type="button"
          onClick={toggleAddressVisible}
          style={{ minHeight: "14px", minWidth: "16px", marginBottom: "5px" }}
        >
          {isAddressVisible ? (
            <img src={eyeCrossedIcon} alt="eye crossed icon" />
          ) : (
            <img src={eyeIcon} alt="eye icon" />
          )}
        </button>
        {allWidth ? (
          addressButton
        ) : (
          <S.ShortenWrapper>{addressButton}</S.ShortenWrapper>
        )}
      </S.WalletAddressWrapper>
      {walletAddress.toLowerCase() !== connectedWalletAddress?.toLowerCase() &&
        isLogged &&
        !isLoading &&
        !isOpenSignMessageModal && (
          <S.OtherAccountInfo>
            The connected wallet and wallet associated with your account are
            different
          </S.OtherAccountInfo>
        )}
    </div>
  );
};
