import { ethers } from "ethers";
import {
  protocolSettingsAbi,
  soilTokenAbi,
  stakingAbi,
  uniswapPoolAbi,
  uniswapQuoterAbi,
  uniswapSwapRouterAbi,
  poolsContractAbi,
} from "./abi";
import { config } from "../../config";
import {
  Staking as StakingType,
  SoilToken as SoilTokenType,
  UniswapPool as UniswapPoolType,
  UniswapQuoter as UniswapQuoterType,
  UniswapSwapRouter as UniswapSwapRouterType,
  ProtocolSettings as ProtocolSettingsType,
  PoolsContract as PoolsContractType,
} from "./types";
import { AppRpcProvider } from "../../utils";

export const Staking = new ethers.Contract(
  config.STAKING_ADDRESS,
  stakingAbi,
  AppRpcProvider
) as StakingType;

export const SoilToken = new ethers.Contract(
  config.SOIL_TOKEN_ADDRESS,
  soilTokenAbi,
  AppRpcProvider
) as SoilTokenType;

export const UniswapSoilUsdcPool = new ethers.Contract(
  config.USDC_SOIL_POOL_ADDRESS,
  uniswapPoolAbi,
  AppRpcProvider
) as UniswapPoolType;

export const UniswapQuoter = new ethers.Contract(
  config.UNISWAP_QUOTER_ADDRESS,
  uniswapQuoterAbi,
  AppRpcProvider
) as UniswapQuoterType;

export const UniswapSwapRouter = new ethers.Contract(
  config.UNISWAP_SWAP_ROUTER_ADDRESS,
  uniswapSwapRouterAbi,
  AppRpcProvider
) as unknown as UniswapSwapRouterType;

export const ProtocolSettings = new ethers.Contract(
  config.PROTOCOL_SETTINGS_CONTRACT,
  protocolSettingsAbi,
  AppRpcProvider
) as unknown as ProtocolSettingsType;

export const PoolsContract = new ethers.Contract(
  config.POOLS_CONTRACT_ADDRESS,
  poolsContractAbi,
  AppRpcProvider
) as PoolsContractType;
