import React from "react";
import * as S from "./CurrencyValue.styled";
import { Typography } from "../Typography/Typography";
import { getDecimalValue } from "../../utils";
import { LoadingSkeleton } from "../LoadingSkeleton";

export type CurrencyValueProps = {
  value: number | string | null;
  currency: string;
  decimals: number;
  isBig?: boolean;
  isTable?: boolean;
  isLevel?: boolean;
  isBalance?: boolean;
  specialSign?: string;
} & React.HTMLAttributes<HTMLParagraphElement>;

export const CurrencyValue = ({
  value,
  currency,
  isBig,
  decimals,
  isTable,
  isLevel,
  specialSign,
  isBalance,
  ...rest
}: CurrencyValueProps) => {
  const formattedValue =
    value !== null
      ? currency === "%" || isBalance
        ? getDecimalValue(Number(value), 2)
        : Number(value) > 100 || Number(value) === 0
        ? getDecimalValue(Number(value), 0)
        : getDecimalValue(Number(value), decimals)
      : null;

  // if (value !== null) {
  //   if (currency === "%") {
  //     getDecimalValue(Number(value), 2);
  //   } else if (Number(value) < 100 || Number(value) === 0) {
  //     getDecimalValue(Number(value), 0);
  //   }
  //   getDecimalValue(Number(value), decimals);
  // }
  // null;

  return (
    <S.CurrencyValueWrapper {...rest}>
      <S.CurrencyValueInlineWrapper>
        {!formattedValue ? (
          <LoadingSkeleton width="64px" className="mr-1" />
        ) : isBig ? (
          <Typography.CurrencyValueBiggerText
            noLineHeight
            isWhite
            fontWeight={700}
            opacity={0.92}
            data-testid="CurrencyValueBiggerText"
          >
            {formattedValue}
          </Typography.CurrencyValueBiggerText>
        ) : isTable ? (
          <Typography.CurrencyValueTableText
            isWhite
            fontWeight={400}
            opacity={0.92}
            isLevel={isLevel}
          >
            {formattedValue}
          </Typography.CurrencyValueTableText>
        ) : specialSign ? (
          <Typography.CurrencyValueText
            noLineHeight
            isWhite
            fontWeight={700}
            opacity={0.92}
            data-testid="CurrencyValueText"
          >
            {specialSign} {formattedValue}
          </Typography.CurrencyValueText>
        ) : (
          <Typography.CurrencyValueText
            noLineHeight
            isWhite
            fontWeight={700}
            opacity={0.92}
            data-testid="CurrencyValueText"
          >
            {formattedValue}
          </Typography.CurrencyValueText>
        )}
        <Typography.CurrencyValueCurrency
          noLineHeight
          isWhite
          fontWeight={400}
          opacity={0.92}
          isLevel={isLevel}
          data-testid="CurrencyValueCurrency"
        >
          {"\u00a0"}
          {currency.toUpperCase()}
        </Typography.CurrencyValueCurrency>
      </S.CurrencyValueInlineWrapper>
    </S.CurrencyValueWrapper>
  );
};
