import { apiCall, apiCallWithAuthToken } from "./config";

type InitKycResponse = {
  message: string;
};

export const getKYCAccessToken = () =>
  apiCallWithAuthToken.get<KYCAccessToken>("kyc/sdk-access-token");

export const initKyc = () =>
  apiCallWithAuthToken.get<InitKycResponse>("kyc/auth-url");

export const redirectAfterKyc = (code: string, state: string) =>
  apiCall.get(`kyc/redirect?code=${code}&state=${state}`);
